import { ReferenceSubType, ReferenceType } from 'enums/common';
import { selectAuthTenantData } from 'stores/auth';
import { useAppSelector } from 'stores/hooks';

export const useCheckUserIsAgent = () => {
  const tenantData = useAppSelector(selectAuthTenantData);
  const referenceType = tenantData?.tenantAssociation?.referenceType;
  const referenceSubType = tenantData?.tenantAssociation?.referenceSubType;

  const isAgent =
    referenceType === ReferenceType.AGENT &&
    referenceSubType === ReferenceSubType.AGENT;

  return isAgent;
};
