import { useMutation } from 'react-query';

import * as filesAPI from 'api/files';
import { IError } from 'interfaces/http';
import { useSnackbar } from 'notistack';
import { toArrayBuffer } from 'utils/file';

export const filesKeys = {
  all: ['files'] as const,
  download: (filters: { filePath: string }) =>
    [...filesKeys.all, { filters }] as const,
};

export const useFileDownloadMutation = () => {
  const { enqueueSnackbar } = useSnackbar();

  return useMutation(
    ({
      filepath,
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      name,
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      download,
    }: {
      filepath: string;
      name: string;
      download: { view: string };
    }) => filesAPI.downloadFile({ filepath }),
    {
      onSuccess: (res, context) => {
        const mimeType = res.data?.contentType;
        const arraybuffer = toArrayBuffer(res.data?.file?.data);
        const blob = new Blob([arraybuffer], { type: mimeType });
        const url = window.URL.createObjectURL(blob); // work with blob directly
        if (context.download.view === 'view') {
          window.open(url, '_blank');
        } else {
          // create hidden dom element so it works in all browsers
          const a = document.createElement('a');
          a.setAttribute('style', 'display:none;');
          document.body.appendChild(a);
          // create file, attach to hidden element and open hidden element
          a.href = url;
          a.download = context.name;
          a.click();
        }
        if (context.download.view !== 'view') {
          enqueueSnackbar(res.message, {
            variant: 'success',
          });
        }
      },
      onError: (err: IError) => {
        enqueueSnackbar(err.message, {
          variant: 'error',
        });
      },
    }
  );
};
