import { Box, SxProps } from '@mui/material';

interface IProps {
  children?: React.ReactNode;
  index: number;
  value: number;
  containerSx?: SxProps;
}

const TabPanel = ({
  children,
  value,
  index,
  containerSx,
  ...other
}: IProps) => (
  <Box
    aria-labelledby={`tab-${index}`}
    hidden={value !== index}
    id={`tabpanel-${index}`}
    role="tabpanel"
    sx={{ ...containerSx }}
    // eslint-disable-next-line react/jsx-props-no-spreading
    {...other}
  >
    {value === index && <Box>{children}</Box>}
  </Box>
);

TabPanel.defaultProps = {
  children: null,
  containerSx: null,
};

export default TabPanel;
