import apiRoutes from 'constants/apiRoutes';
import { ESignatureStatus } from 'enums/eSign';
import {
  IClientLookupSaveAgreementForm,
  ISaveClientAgreementForm,
  ISendClientAgreementForm,
} from 'interfaces/client-agreement';
import { IResponse } from 'interfaces/http';
import { baseRequest } from 'utils/axios';

const { clientAgreement } = apiRoutes;

export const decodeAgreement = async (code: string) => {
  const { response, error } = await baseRequest({
    method: 'POST',
    url: clientAgreement.decode,
    data: { code },
  });

  if (error) {
    throw new Error(error.message);
  }

  return response?.data;
};

// Naming for this function can be improved.
// Function name suggests that we are updating but the method is `POST`.
export const updateAgreement = async (
  lookupId: string,
  data: IClientLookupSaveAgreementForm
) => {
  const { response, error } = await baseRequest({
    method: 'POST',
    url: clientAgreement.updateAgreement.replace(':id', lookupId),
    data,
  });

  if (error) {
    throw new Error(error.message);
  }

  return response?.data;
};

export const saveAgreement = async (data: ISaveClientAgreementForm) => {
  const { response, error } = await baseRequest({
    method: 'POST',
    url: clientAgreement.saveAgreement,
    data,
  });

  if (error) {
    throw new Error(error.message);
  }

  return response?.data;
};

export const sendAgreement = async (data: ISendClientAgreementForm) => {
  const { response, error } = await baseRequest({
    method: 'POST',
    url: clientAgreement.sendAgreement,
    data,
  });

  if (error) {
    throw new Error(error.message);
  }

  return response?.data;
};

export const verifyESignAgreement = async (data: {
  token: string;
}): Promise<IResponse<{ status: ESignatureStatus; link: string }>> => {
  const { response, error } = await baseRequest({
    method: 'POST',
    url: clientAgreement.verifyESignAgreement,
    data,
  });

  if (error) {
    throw new Error(error.message);
  }

  return response?.data;
};
