import { ReferenceSubType } from 'enums/common';
import { selectAuthTenantData } from 'stores/auth';
import { useAppSelector } from 'stores/hooks';

import { useCheckUserIsClient } from './useCheckUserIsClient';

export const useCheckUserIsClientIndividualOrDependentOrGroupMember = () => {
  const tenantData = useAppSelector(selectAuthTenantData);
  const referenceSubType = tenantData?.tenantAssociation?.referenceSubType;

  const isUserClient = useCheckUserIsClient();

  return !!(
    isUserClient &&
    (referenceSubType === ReferenceSubType.INDIVIDUAL ||
      referenceSubType === ReferenceSubType.DEPENDENT ||
      referenceSubType === ReferenceSubType.GROUP_MEMBER)
  );
};
