import { useTenantContactQuery } from 'services/tenant-management/tenant';
import { selectAuthTenantAssociation } from 'stores/auth';
import { useAppSelector } from 'stores/hooks';

export const useGetSupportContact = () => {
  const authTenantAssociationData = useAppSelector(selectAuthTenantAssociation);
  const authTenantId = authTenantAssociationData?.tenantId;

  const tenantContactsQuery = useTenantContactQuery(authTenantId!, {
    enabled: !!authTenantId,
  });

  const primaryEmail = tenantContactsQuery?.data?.primaryEmail;
  const supportEmail = tenantContactsQuery?.data?.supportEmail;

  return supportEmail || primaryEmail || '';
};
