import { useEffect, useState } from 'react';
import { useLocation, useNavigate, useSearchParams } from 'react-router-dom';

import { faCalendarCheck, faXmark } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Alert, Box, TextField, Typography, useTheme } from '@mui/material';
import MediumSpanText from 'common/display/MediumSpanText';
import ModalFooter from 'common/ui/modal/ModalFooter';
import {
  ActionFrom,
  ChangeMembershipActionType,
} from 'enums/client-management';
import { TextTense } from 'enums/common';
import ModalKey from 'enums/ModalKey';
import { BillingType } from 'enums/settings/tenant-offerings';
import { EnrollmentCode } from 'enums/tenant-management/tenant';
import UserType from 'enums/User';
import {
  useCancelClientEnrollmentMutation,
  useDeclineClientEnrollmentMutation,
} from 'services/client-management/enrollment';
import {
  selectChangeMembershipClientData,
  selectChangeMembershipModal,
  selectCurrentMembershipSelectedData,
  setChangeMembershipStep,
} from 'stores/client-management';
import { useAppDispatch, useAppSelector } from 'stores/hooks';
import { checkIsClientPrimary } from 'utils/client-management';
import { checkIsEnrollmentSettingEnabled } from 'utils/tenantSetting';

const ChangeMembershipCancellation = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const [endDate, setEndDate] = useState<string>('');
  const [declineNote, setDeclineNote] = useState<string>('');

  const [searchParams] = useSearchParams();
  const clientStatus = searchParams.get('status');
  const clientEnrollmentIdFromParams = searchParams.get('clientEnrollmentId');

  const isActionFromClientList =
    searchParams.get('actionFrom') === ActionFrom.CLIENT_LIST;

  const theme = useTheme();
  const changeMembershipActionType = useAppSelector(
    selectChangeMembershipModal
  )?.actionType;
  const changeMembershipClientData = useAppSelector(
    selectChangeMembershipClientData
  );
  const clientData = useAppSelector(selectChangeMembershipClientData) || null;
  const currentMembershipSelectedData =
    useAppSelector(selectCurrentMembershipSelectedData) || null;
  const dispatch = useAppDispatch();
  const cancelClientMutation = useCancelClientEnrollmentMutation();
  const declineClientMutation = useDeclineClientEnrollmentMutation();
  const clientType = currentMembershipSelectedData?.client.type;
  const clientId = clientData?.clientId || '';

  const isClientPrimary = checkIsClientPrimary(clientData);
  const isMembershipBillingTypeFamily =
    currentMembershipSelectedData?.offerings.billingType === BillingType.FAMILY;

  const isActionTypeCancel =
    changeMembershipActionType === ChangeMembershipActionType.CANCEL;

  const isCompleteMembershipEnabled = checkIsEnrollmentSettingEnabled(
    EnrollmentCode.COMPLETEMEMBERSHIP
  );

  useEffect(() => {
    setEndDate(currentMembershipSelectedData?.startDate as string);
  }, [currentMembershipSelectedData?.startDate]);

  const handlePrevious = () => {
    if (isActionFromClientList) {
      navigate(
        `${location.pathname}?type=${
          ModalKey.CHANGE_MEMBERSHIP
        }&clientId=${clientId!}&status=${clientStatus}&clientEnrollmentId=${clientEnrollmentIdFromParams}`
      );
    }
    dispatch(
      setChangeMembershipStep({
        step: 0,
        actionType: changeMembershipActionType,
      })
    );
  };

  const onConfirmCancellation = () => {
    const actionTypeMutation = isActionTypeCancel
      ? cancelClientMutation
      : declineClientMutation;
    const payload = {
      endDate,
      note: declineNote,
      email: changeMembershipClientData?.email,
    };
    actionTypeMutation.mutate(
      {
        clientId: clientData?.clientId ? clientData.clientId : '',
        enrollmentId: currentMembershipSelectedData?.clientEnrollmentId
          ? currentMembershipSelectedData.clientEnrollmentId
          : '',
        data: payload,
        clientType: clientType as UserType,
      },
      {
        onSuccess: () => {
          dispatch(
            setChangeMembershipStep({
              step: 3,
              actionType: changeMembershipActionType,
            })
          );
        },
      }
    );
  };

  const getActionText = (tense: TextTense) => {
    if (changeMembershipActionType === ChangeMembershipActionType.DECLINE) {
      if (tense === TextTense.PAST) return 'declined';
      if (tense === TextTense.PRESENT_PARTICIPLE) return 'declining';
      return 'decline';
    }
    if (
      changeMembershipActionType === ChangeMembershipActionType.CANCEL &&
      isCompleteMembershipEnabled
    ) {
      if (tense === TextTense.PAST) return 'completed';
      if (tense === TextTense.PRESENT_PARTICIPLE) return 'completing';
      return 'complete';
    }
    if (tense === TextTense.PAST) return 'cancelled';
    if (tense === TextTense.PRESENT_PARTICIPLE) return 'canceling';
    return 'cancel';
  };

  return (
    <Box>
      <Box paddingY={6} sx={{ textAlign: 'center' }}>
        <FontAwesomeIcon
          color={theme.palette.primary.main}
          icon={faXmark}
          size="3x"
        />
        <Typography display="block" marginY={2} variant="body1">
          <Typography display="inline" variant="body2">
            You are {getActionText(TextTense.PRESENT_PARTICIPLE)}{' '}
          </Typography>
          <Typography display="inline" fontWeight="medium" variant="body2">
            {currentMembershipSelectedData?.offerings.name}
          </Typography>
          <Typography display="inline" variant="body2">
            {' '}
            for{' '}
          </Typography>
          <Typography textTransform="capitalize" variant="body2">
            {clientData?.fullName
              ? clientData?.fullName
              : clientData?.businessName}
            .
          </Typography>
          {isClientPrimary && isMembershipBillingTypeFamily && (
            <Typography variant="body2">
              <MediumSpanText>
                This will {getActionText(TextTense.PRESENT)} the membership for
                all dependents if any.
              </MediumSpanText>
            </Typography>
          )}
        </Typography>
        <Box
          sx={{
            width: '30rem',
            margin: '0 auto',
            background: theme.palette.gray.lighter,
            padding: '1rem 0 1.5rem',
            borderRadius: '5px',
            border: `2px solid ${theme.palette.gray.lighter}`,
          }}
        >
          <Typography
            color={theme.palette.common.black}
            style={{ margin: '0 auto' }}
            variant="body2"
            width="20rem"
          >
            Are you sure you want to {getActionText(TextTense.PRESENT)}{' '}
            membership on
          </Typography>
          <Box>
            <Typography
              fontWeight="medium"
              style={{ marginBottom: '-8px' }}
              variant="body2"
            >
              <FontAwesomeIcon
                color={theme.palette.primary.main}
                icon={faCalendarCheck}
                style={{
                  margin: '0.5rem 0.5rem 0 0',
                }}
              />{' '}
              {endDate}
            </Typography>
          </Box>
        </Box>
        <Box
          sx={{
            width: '30rem',
            margin: '1rem auto',
          }}
        >
          <TextField
            fullWidth
            label="Add Note"
            multiline
            onChange={(e) => setDeclineNote(e.target.value)}
            rows={3}
            size="small"
            value={declineNote}
            variant="standard"
          />
        </Box>
        <Box
          sx={{
            width: '34rem',
            margin: '1rem auto',
          }}
        >
          <Alert
            severity="info"
            sx={{
              marginX: '2rem !important',
              textAlign: 'left',
            }}
          >
            Membership can be {getActionText(TextTense.PAST)}. Please confirm to{' '}
            {getActionText(TextTense.PRESENT)} membership.
          </Alert>
        </Box>
      </Box>
      <ModalFooter
        actionButtonLabel="Confirm"
        actionButtonType="button"
        cancelButtonType="previous"
        isLoading={
          cancelClientMutation.isLoading || declineClientMutation.isLoading
        }
        onActionButtonClick={onConfirmCancellation}
        onCancelButtonClick={handlePrevious}
      />
    </Box>
  );
};

export default ChangeMembershipCancellation;
