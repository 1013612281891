/* eslint-disable no-param-reassign */
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import commonConstants from 'constants/common';
import { ReportFilterBy } from 'enums/reports';
import { IFilter } from 'interfaces/common';
import { ICommissionReportAdvancedSearchForm } from 'interfaces/reports/commission-report';
import { RootState } from 'stores';

const { DEFAULT_TABLE_FILTER } = commonConstants;

const advancedSearchDefaultValues = {
  interval: ReportFilterBy.QUATERLY,
  startDate: '',
  endDate: '',
};

interface CommissionReportState {
  filters: IFilter;
  advancedSearch: ICommissionReportAdvancedSearchForm;
}

const initialState: CommissionReportState = {
  filters: { ...DEFAULT_TABLE_FILTER },
  advancedSearch: { ...advancedSearchDefaultValues },
};

export const slice = createSlice({
  name: 'commissionReport',
  initialState,
  reducers: {
    // Changing the state directly (with mutation) in reducer
    // Redux Toolkit uses Immer under the hood (takes care of immutablility)
    changeFilters: (state, action: PayloadAction<Partial<IFilter>>) => {
      state.filters = { ...state.filters, ...action.payload };
    },

    resetFilters: (state) => {
      state.filters = { ...DEFAULT_TABLE_FILTER };
    },

    changeAdvancedSearch: (
      state,
      action: PayloadAction<Partial<ICommissionReportAdvancedSearchForm>>
    ) => {
      state.advancedSearch = { ...state.advancedSearch, ...action.payload };
    },

    resetAdvancedSearch: (state) => {
      state.advancedSearch = { ...advancedSearchDefaultValues };
    },
  },
});

// Actions
export const {
  changeFilters,
  changeAdvancedSearch,
  resetFilters,
  resetAdvancedSearch,
} = slice.actions;

// Selectors
export const selectCommissionReportFilter = (state: RootState) =>
  state.commissionReport.filters;
// export const selectCommissionReportSort = (state: RootState) => state.commissionReport.sort;

export const selectCommissionReportAdvancedSearch = (state: RootState) =>
  state.commissionReport.advancedSearch;
export const selectCommissionReport = (state: RootState) =>
  state.commissionReport;

// Reducer
export default slice.reducer;
