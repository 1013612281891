import React, { lazy } from 'react';
import { QueryErrorResetBoundary } from 'react-query';

import { clearBrowserCaches } from 'utils/misc';
import { getLocal, setLocal } from 'utils/storage';

import ErrorBoundary from './error-boundary';

const loadComponentWithWrapper =
  (Component: React.ComponentType) => (props: any) =>
    (
      <QueryErrorResetBoundary>
        {({ reset }) => (
          <React.Suspense>
            <ErrorBoundary onReset={reset}>
              <Component {...props} />
            </ErrorBoundary>
          </React.Suspense>
        )}
      </QueryErrorResetBoundary>
    );

const lazyImportWithRetry = (componentImport: any) =>
  loadComponentWithWrapper(
    lazy(async () => {
      const isForceRefreshed = getLocal('force-refreshed', true) || false;
      try {
        const component = await componentImport();
        setLocal('force-refreshed', 'false');
        return component;
      } catch (error) {
        if (!isForceRefreshed) {
          clearBrowserCaches();
          setLocal('force-refreshed', 'true');
          return window.location.reload();
        }
        throw error;
      }
    })
  );

export { lazyImportWithRetry };
