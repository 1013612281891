/* eslint-disable no-param-reassign */
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import commonConstants from 'constants/common';
import { SortOrderType } from 'interfaces/common';
import {
  ILeadRows,
  ILeadsAdvancedSearchForm,
  ILeadsFilters,
  LeadsSortByType,
} from 'interfaces/leads';
import { RootState } from 'stores';

const { DEFAULT_TABLE_FILTER } = commonConstants;

const advancedSearchDefaultValues = {
  status: '',
  type: '',
  name: '',
};

interface LeadsSort {
  sortBy: LeadsSortByType | null;
  sortOrder: SortOrderType | null;
}

interface LeadsState {
  filters: ILeadsFilters;
  advancedSearch: ILeadsAdvancedSearchForm;
  sort: LeadsSort;
  editLeadsData: ILeadRows | null;
  advanceFilterOfferings: any;
}

const initialState: LeadsState = {
  filters: {
    ...DEFAULT_TABLE_FILTER,
  },

  advancedSearch: { ...advancedSearchDefaultValues },
  sort: {
    sortBy: null,
    sortOrder: null,
  },
  editLeadsData: null,
  advanceFilterOfferings: null,
};

export const slice = createSlice({
  name: 'leads',
  initialState,
  reducers: {
    // Changing the state directly (with mutation) in reducer
    // Redux Toolkit uses Immer under the hood (takes care of immutablility)
    changeFilters: (state, action: PayloadAction<Partial<ILeadsFilters>>) => {
      state.filters = { ...state.filters, ...action.payload };
    },
    setEditLeadsData: (state, action: PayloadAction<ILeadRows>) => {
      state.editLeadsData = action.payload;
    },
    resetFilters: (state) => {
      state.filters = {
        ...state.filters,
        ...DEFAULT_TABLE_FILTER,
      };
    },

    changeAdvancedSearch: (
      state,
      action: PayloadAction<Partial<ILeadsAdvancedSearchForm>>
    ) => {
      const advanceFilters = { ...state.advancedSearch, ...action.payload };

      state.advancedSearch = {
        ...advanceFilters,
      };
      state.advancedSearch = { ...state.advancedSearch, ...action.payload };
    },

    resetAdvancedSearch: (state) => {
      state.advancedSearch = { ...advancedSearchDefaultValues };
      state.advanceFilterOfferings = null;
    },

    changeSortByAndOrder: (state, action: PayloadAction<LeadsSort>) => {
      state.sort = {
        sortBy: action.payload.sortBy,
        sortOrder: action.payload.sortOrder,
      };
    },

    resetAllFilters: (state) => {
      state.advancedSearch = { ...advancedSearchDefaultValues };
      state.filters = {
        ...DEFAULT_TABLE_FILTER,
      };
      state.sort = initialState.sort;
      state.advanceFilterOfferings = [];
    },
    changeSelectedOfferings: (
      state,
      action: PayloadAction<Partial<any> | null>
    ) => {
      state.advanceFilterOfferings = action.payload;
    },
  },
});

// Actions
export const {
  changeFilters,
  changeAdvancedSearch,
  resetFilters,
  resetAdvancedSearch,
  changeSortByAndOrder,
  resetAllFilters,
  setEditLeadsData,
  changeSelectedOfferings,
} = slice.actions;

// Selectors
export const selectLeadsFilter = (state: RootState) => state.leads.filters;
export const selectEditLeadData = (state: RootState) =>
  state.leads.editLeadsData;
export const selectLeadsSort = (state: RootState) => state.leads.sort;
export const selectLeadsAdvancedSearch = (state: RootState) =>
  state.leads.advancedSearch;
export const selectLeads = (state: RootState) => state.leads;
export const selectAdvanceFilteredOfferings = (state: RootState) =>
  state.clientIndividual.advanceFilterOfferings;
// Reducer
export default slice.reducer;
