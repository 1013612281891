export enum TestReportStatus {
  ACTIVE = 'Active',
  DELETED = 'Deleted',
}

export enum TestReportSortBy {
  REPORT_NAME = 'name',
  PATIENT_NAME = 'patient_name',
  DATE = 'date',
  EMAIL = 'email',
}
