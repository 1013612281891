/* eslint-disable no-param-reassign */
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import commonConstants from 'constants/common';
import {
  ClientIndividualSortByType,
  IClientFilters,
  IIndividualAdvancedSearchForm,
} from 'interfaces/client-management';
import { SortOrderType } from 'interfaces/common';
import { RootState } from 'stores';
import { checkIfValuesInObjectIsEmpty } from 'utils/common';

const { DEFAULT_TABLE_FILTER } = commonConstants;

const advancedSearchDefaultValues = {
  name: '',
  email: '',
  dob: '',
  phone: '',
  status: '',
  relationship: '',
  isAdvancedSearch: false,
  offeringType: '',
  offerings: '',
  benefitInterval: '',
  referredBy: '',
  referredByName: '',
  bloodGroup: '',
};

interface IIndividualSort {
  sortBy: ClientIndividualSortByType | null;
  sortOrder: SortOrderType | null;
}

interface IndividualState {
  filters: IClientFilters;
  advancedSearch: IIndividualAdvancedSearchForm;
  sort: IIndividualSort;
  // TODO Refactor this code
  advanceFilterOfferings: any;
}

const initialState: IndividualState = {
  filters: {
    ...DEFAULT_TABLE_FILTER,
    type: commonConstants.TYPE.INDIVIDUAL,
    tenantId: '',
    clientId: '',
    agentId: '',
  },
  advancedSearch: { ...advancedSearchDefaultValues },
  sort: {
    sortBy: null,
    sortOrder: null,
  },
  advanceFilterOfferings: null,
};

export const slice = createSlice({
  name: 'individual',
  initialState,
  reducers: {
    // Changing the state directly (with mutation) in reducer
    // Redux Toolkit uses Immer under the hood (takes care of immutablility)
    changeFilters: (state, action: PayloadAction<Partial<IClientFilters>>) => {
      state.filters = { ...state.filters, ...action.payload };
    },

    resetFilters: (state) => {
      state.filters = {
        ...state.filters,
        ...DEFAULT_TABLE_FILTER,
        type: commonConstants.TYPE.INDIVIDUAL,
      };
    },

    changeAdvancedSearch: (
      state,
      action: PayloadAction<Partial<IIndividualAdvancedSearchForm>>
    ) => {
      const advanceFilters = { ...state.advancedSearch, ...action.payload };
      const { isAdvancedSearch, ...rest } = advanceFilters;
      state.advancedSearch = {
        ...advanceFilters,
        isAdvancedSearch: !checkIfValuesInObjectIsEmpty(rest),
      };
      state.advancedSearch = { ...state.advancedSearch, ...action.payload };
    },

    resetAdvancedSearch: (state) => {
      state.advancedSearch = { ...advancedSearchDefaultValues };
      state.advanceFilterOfferings = null;
    },

    changeSortByAndOrder: (state, action: PayloadAction<IIndividualSort>) => {
      state.sort = {
        sortBy: action.payload.sortBy,
        sortOrder: action.payload.sortOrder,
      };
    },

    resetAllFilters: (state) => {
      state.advancedSearch = { ...advancedSearchDefaultValues };
      state.filters = {
        ...state.filters,
        ...DEFAULT_TABLE_FILTER,
        type: commonConstants.TYPE.INDIVIDUAL,
      };
      state.sort = initialState.sort;
      state.advanceFilterOfferings = [];
    },

    changeSelectedOfferings: (
      state,
      action: PayloadAction<Partial<any> | null>
    ) => {
      state.advanceFilterOfferings = action.payload;
    },
  },
});

// Actions
export const {
  changeFilters,
  changeAdvancedSearch,
  resetFilters,
  resetAdvancedSearch,
  changeSortByAndOrder,
  resetAllFilters,
  changeSelectedOfferings,
} = slice.actions;

// Selectors
export const selectIndividualFilter = (state: RootState) =>
  state.clientIndividual.filters;
export const selectIndividualSort = (state: RootState) =>
  state.clientIndividual.sort;
export const selectIndividualAdvancedSearch = (state: RootState) =>
  state.clientIndividual.advancedSearch;
export const selectIndividual = (state: RootState) => state.clientIndividual;
export const selectAdvanceFilteredOfferings = (state: RootState) =>
  state.clientIndividual.advanceFilterOfferings;
export const selectAdvancedFilterBloodGroup = (state: RootState) =>
  state.clientIndividual.advancedSearch.bloodGroup;

// Reducer
export default slice.reducer;
