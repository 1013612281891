import React from 'react';

import { Grid } from '@mui/material';
import { FormTextfield } from 'common';

const DocumentURIForm = () => (
  <Grid container mb={2} spacing={2}>
    <Grid item xs={12}>
      <FormTextfield
        inputProps={{ 'data-cy': 'add-url' }}
        label="URL"
        name="path"
        required
        showHelperText
      />
    </Grid>
    <Grid item xs={12}>
      <FormTextfield
        inputProps={{ 'data-cy': 'add-file-name' }}
        label="File Name"
        name="name"
        required
      />
    </Grid>
  </Grid>
);

export default DocumentURIForm;
