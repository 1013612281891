import {
  faArrowsRotate,
  faCalendar,
  faCheckCircle,
  faFileZipper,
  faXmark,
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  Box,
  Button,
  Stack,
  TableCell,
  TableRow,
  Typography,
} from '@mui/material';
import { ChangeMembershipActionType } from 'enums/client-management';
import { EnrollmentCode } from 'enums/tenant-management/tenant';
import { useCheckPermissionToModifyMembership } from 'hooks/useCheckPermissionToModifyMembership';
import { IAdaptedClientEnrollmentMembership } from 'interfaces/client-management/enrollment';
import {
  setChangeMembershipSelectedData,
  setChangeMembershipStep,
  setCurrentMembershipSelectedData,
} from 'stores/client-management';
import { useAppDispatch } from 'stores/hooks';
import { checkIfDateisInFuture } from 'utils/moment';
import { checkIsEnrollmentSettingEnabled } from 'utils/tenantSetting';

const ChangeMembershipTableRow = ({
  data,
}: {
  data: IAdaptedClientEnrollmentMembership[];
}) => {
  const dispatch = useAppDispatch();

  const isModifyMembershpAllowed = useCheckPermissionToModifyMembership();

  if (data.length > 0) {
    dispatch(setCurrentMembershipSelectedData(data[0]));
  }

  const onApprovePlanClick = (item: IAdaptedClientEnrollmentMembership) => {
    dispatch(setCurrentMembershipSelectedData(item));
    dispatch(
      setChangeMembershipStep({
        step: 2,
        actionType: ChangeMembershipActionType.APPROVE,
      })
    );
  };

  const onChangePlanClick = (item: IAdaptedClientEnrollmentMembership) => {
    if (item.endDate) {
      dispatch(setChangeMembershipSelectedData({ endDate: item.endDate }));
    }
    dispatch(setCurrentMembershipSelectedData(item));
    dispatch(
      setChangeMembershipStep({
        step: 1,
        actionType: ChangeMembershipActionType.CHANGE,
      })
    );
  };
  const onCompletePlanClick = (item: IAdaptedClientEnrollmentMembership) => {
    dispatch(setCurrentMembershipSelectedData(item));
    dispatch(
      setChangeMembershipStep({
        step: 4,
        actionType: ChangeMembershipActionType.CANCEL,
      })
    );
  };

  const onCancelPlanClick = (item: IAdaptedClientEnrollmentMembership) => {
    dispatch(setCurrentMembershipSelectedData(item));
    dispatch(
      setChangeMembershipStep({
        step: 5,
        actionType: ChangeMembershipActionType.CANCEL,
      })
    );
  };

  const onDeclinePlanClick = (item: IAdaptedClientEnrollmentMembership) => {
    dispatch(setCurrentMembershipSelectedData(item));
    dispatch(
      setChangeMembershipStep({
        step: 5,
        actionType: ChangeMembershipActionType.DECLINE,
      })
    );
  };

  const isCompleteMembershipEnabled = checkIsEnrollmentSettingEnabled(
    EnrollmentCode.COMPLETEMEMBERSHIP
  );

  return (
    <>
      {data.map((x) => {
        const action = x.actions;
        const isBenefitStartDateInFuture = checkIfDateisInFuture(
          x.startDate || ''
        );
        return (
          <TableRow key={x.clientEnrollmentId}>
            <TableCell>
              <Typography fontWeight="medium" variant="body2">
                {x.offerings.name}
              </Typography>
              <Typography
                color="gray.dark"
                gutterBottom={false}
                variant="body2"
              >
                <FontAwesomeIcon icon={faFileZipper} /> {x.formattedTotalCost}{' '}
                {x.formattedBillingType} {x.formattedBillingInterval}
                {+x.offerings.registrationFee > 0 && (
                  <>
                    <br />
                    Registration Fee: {x.formattedRegistrationFee}
                  </>
                )}
              </Typography>
            </TableCell>
            <TableCell>
              <Typography fontWeight="medium" variant="body2">
                {x.status} {x.isActiveStatus}
              </Typography>
              <Typography
                color="gray.dark"
                data-cy="client-modifyMembership-date"
                gutterBottom={false}
                variant="body2"
              >
                <FontAwesomeIcon icon={faCalendar} />{' '}
                {`${x.startDate} ${x?.endDate ? `- ${x.endDate}` : ''}`}
              </Typography>
            </TableCell>
            <TableCell width="30%">
              <Stack
                direction="row"
                display="flex"
                justifyContent="end"
                spacing={1}
              >
                {(!isModifyMembershpAllowed || !action.length) && (
                  <Box data-cy="client-modifyMembership-empty" />
                )}
                {isModifyMembershpAllowed &&
                  action.includes(ChangeMembershipActionType.APPROVE) && (
                    <Button
                      data-cy="client-modifyMembership-approve"
                      onClick={() => onApprovePlanClick(x)}
                      size="small"
                      startIcon={<FontAwesomeIcon icon={faCheckCircle} />}
                    >
                      Approve
                    </Button>
                  )}
                {isModifyMembershpAllowed &&
                  action.includes(ChangeMembershipActionType.CHANGE) && (
                    <Button
                      data-cy="client-modifyMembership-change"
                      onClick={() => onChangePlanClick(x)}
                      size="small"
                      startIcon={<FontAwesomeIcon icon={faArrowsRotate} />}
                    >
                      Change
                    </Button>
                  )}
                {isModifyMembershpAllowed &&
                  action.includes(ChangeMembershipActionType.DECLINE) && (
                    <Button
                      data-cy="client-modifyMembership-decline"
                      onClick={() => onDeclinePlanClick(x)}
                      size="small"
                      startIcon={<FontAwesomeIcon icon={faXmark} />}
                    >
                      Decline
                    </Button>
                  )}
                {isModifyMembershpAllowed &&
                  (action.includes(ChangeMembershipActionType.CANCEL) ||
                    action.includes(ChangeMembershipActionType.COMPLETE)) && (
                    <Button
                      data-cy={`client-modifyMembership-${
                        isCompleteMembershipEnabled
                          ? ChangeMembershipActionType.COMPLETE
                          : ChangeMembershipActionType.CANCEL
                      }`}
                      onClick={() =>
                        isBenefitStartDateInFuture
                          ? onCancelPlanClick(x)
                          : onCompletePlanClick(x)
                      }
                      size="small"
                      startIcon={<FontAwesomeIcon icon={faXmark} />}
                    >
                      {isCompleteMembershipEnabled
                        ? ChangeMembershipActionType.COMPLETE
                        : ChangeMembershipActionType.CANCEL}
                    </Button>
                  )}
              </Stack>
            </TableCell>
          </TableRow>
        );
      })}
    </>
  );
};

export default ChangeMembershipTableRow;
