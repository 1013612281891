import { Box, SxProps } from '@mui/material';
import VitafyLogo from 'assets/images/svg/vitafy-logo.svg';

interface Props {
  alt?: string;
  sx?: SxProps;
  src?: any;
  fallbackImg?: any;
}

const Logo = ({ alt = 'logo', sx, src, fallbackImg }: Props) => (
  <Box
    alt={alt}
    component="img"
    onError={(e: any) => {
      e.target.onerror = null;
      e.target.src = `${fallbackImg}`;
    }}
    src={src}
    sx={{ height: 30, width: 'auto', ...sx }}
  />
);

Logo.defaultProps = {
  alt: 'logo',
  sx: {
    height: 30,
  },
  src: VitafyLogo,
  fallbackImg: VitafyLogo,
};

export default Logo;
