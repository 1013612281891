/* eslint-disable no-param-reassign */
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import commonConstants from 'constants/common';
import { SortOrder } from 'enums/common';
import { ProfileAdminSortBy } from 'enums/settings/profile-admin';
import { IProfileAdminFilter, SortOrderType } from 'interfaces/common';
import { RootState } from 'stores';

const { DEFAULT_PROFILE_ADMINS_FILTER } = commonConstants;

const sortDefaultValues = {
  sortBy: ProfileAdminSortBy.NAME,
  sortOrder: SortOrder.ASC,
};

interface IProfileAdminSort {
  sortBy: ProfileAdminSortBy;
  sortOrder: SortOrderType;
}

interface IProfileAdminState {
  filters: IProfileAdminFilter;
  sort: IProfileAdminSort;
}

const initialState: IProfileAdminState = {
  filters: { ...DEFAULT_PROFILE_ADMINS_FILTER },
  sort: { ...sortDefaultValues },
};

export const slice = createSlice({
  name: 'profileAdmins',
  initialState,
  reducers: {
    changeFilter: (
      state,
      action: PayloadAction<Partial<IProfileAdminFilter>>
    ) => {
      state.filters = { ...state.filters, ...action.payload };
    },
    resetFilters: (state) => {
      state.filters = { ...DEFAULT_PROFILE_ADMINS_FILTER };
    },
    changeSortByAndOrder: (state, action: PayloadAction<IProfileAdminSort>) => {
      state.sort = {
        sortBy: action.payload.sortBy,
        sortOrder: action.payload.sortOrder,
      };
    },
  },
});

// Actions
export const { changeFilter, changeSortByAndOrder, resetFilters } =
  slice.actions;

export const selectProfileAdminFilter = (state: RootState) =>
  state.profileAdmin.filters;

export const selectProfileAdminSort = (state: RootState) =>
  state.profileAdmin.sort;

// Reducer
export default slice.reducer;
