import apiRoutes from 'constants/apiRoutes';
import { IResponse } from 'interfaces/http';
import { baseRequest } from 'utils/axios';

const { files } = apiRoutes;

export const downloadFile = async (data: {
  filepath: string;
}): Promise<IResponse<any>> => {
  const { response, error } = await baseRequest({
    method: 'POST',
    url: files.download,
    data,
  });

  if (error) {
    throw new Error(error.message);
  }

  return response?.data;
};
