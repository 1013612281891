import apiRoutes from 'constants/apiRoutes';
import { IResponse } from 'interfaces/http';
import { ILangDict, ILangDictFilter } from 'interfaces/language-dictionary';
import { baseRequest } from 'utils/axios';

const { langDict } = apiRoutes;

export const getLanguageDictionary = async (
  params: ILangDictFilter
): Promise<IResponse<ILangDict>> => {
  const { response, error } = await baseRequest({
    method: 'GET',
    url: langDict.getAll,
    params,
  });

  if (error) {
    throw new Error(error.message);
  }

  return response?.data;
};
