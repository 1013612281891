import { Components, Theme } from '@mui/material/styles';
import themeVars from 'assets/scss/_theme-vars.module.scss';
import { pxToRem } from 'utils/mui';

const Select = (theme: Theme): Components<Theme> => ({
  MuiAutocomplete: {
    defaultProps: {
      size: 'small',
    },
    styleOverrides: {
      root: {
        '& .MuiInputBase-root.MuiInput-root': {
          paddingTop: theme.spacing(0.5),
          paddingBottom: theme.spacing(1),
        },
      },
      input: {
        '&.MuiInputBase-input.MuiInput-input': {
          padding: 0,
        },
      },
      endAdornment: {
        '& > svg': {
          fontSize: pxToRem(+themeVars.body1FontSize),
        },
      },
    },
  },
  MuiSelect: {
    defaultProps: {
      size: 'small',
    },
    styleOverrides: {
      icon: {
        fontSize: pxToRem(+themeVars.body1FontSize),
      },
      select: {
        '&.MuiSelect-standard': {
          height: 0,
        },

        '&:focus': {
          backgroundColor: 'initial',
        },
      },
    },
  },
});

export default Select;
