export enum UpcomingSortBy {
  BILLING_DATE = 'nextBillingDate',
  CLIENT = 'name',
  CONTACT = 'email',
}

export enum UpcomingStatus {
  ACTIVE = 'Active',
  HOLD = 'Hold',
  CANCELLED = 'Cancelled',
}
