import { Outlet } from 'react-router-dom';

import { Box, Container } from '@mui/material';

const UiGuidelinesLayout = () => (
  <Container maxWidth="md">
    <Box py={4}>
      <Outlet />
    </Box>
  </Container>
);

export default UiGuidelinesLayout;
