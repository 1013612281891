import React from 'react';

import { DialogContent, TextField } from '@mui/material';
import { Modal, ModalFooter } from 'common';

interface IProps {
  onCloseModal: VoidFunction;
  handleSubmitKey: VoidFunction;
}
const SecretKeyModal = React.forwardRef((props: IProps, ref) => {
  const { handleSubmitKey, onCloseModal } = props;
  return (
    <Modal
      handleClose={onCloseModal}
      icon="faKey"
      open
      title="Enter Secret Key"
    >
      <DialogContent className="modal-content modal-content--fixed-height">
        <TextField
          fullWidth
          inputRef={ref}
          label="secret key"
          sx={{ mt: 2 }}
          variant="standard"
        />
      </DialogContent>
      <ModalFooter
        actionButtonType="button"
        isActionRequired
        isDisabled={false}
        isLoading={false}
        onActionButtonClick={handleSubmitKey}
        onCancelButtonClick={onCloseModal}
      />
    </Modal>
  );
});

export default SecretKeyModal;
