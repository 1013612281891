import { AgreementType } from 'enums/client-management';
import { ReferenceType } from 'enums/common';
import {
  IAdaptedClientAgreementQuery,
  IClientAgreementQuery,
  IClientSendAgreementPayload,
} from 'interfaces/client-agreement';
import { IClientFile } from 'interfaces/client-management';
import { IResponse } from 'interfaces/http';
import { getFullName } from 'utils/common';

const formatFullName = (data: IClientAgreementQuery) => {
  if (data.client.businessName) return data.client.businessName;
  if (data.client.firstName || data.client.lastName)
    return getFullName(data.client.firstName, data.client.lastName);

  return '-';
};

export const adaptAgreement = (
  res: IResponse<IClientAgreementQuery>
): IResponse<IAdaptedClientAgreementQuery> => ({
  ...res,
  data: {
    ...res.data,
    fullName: formatFullName(res.data),
    clientName: formatFullName(res.data),
    clientEmail: res.data.client.email,
  },
});

export const formatSaveAgreementPayload = ({
  body,
  clientInfo,
  browserName,
  browserVersion,
}: {
  body: IClientSendAgreementPayload;
  clientInfo?: IAdaptedClientAgreementQuery;
  browserName: string;
  browserVersion: string;
}) => {
  const requestPayload = {
    createdDate: new Date(),
    tenantId: clientInfo?.tenantId ?? '',
    referenceType: ReferenceType.SLA,
    referenceId: clientInfo?.clientId ?? '',
    metaData: {
      browserName,
      browserVersion,
    },
  };

  return {
    file: body,
    agreementInfo: requestPayload,
  };
};

export const checkIsAgreementSignedAlready = (data: IClientFile[]) =>
  data.some((e) => e.type === AgreementType.SLA);
