import { useEffect, useRef, useState } from 'react';

import { Box, Stack, Typography } from '@mui/material';
import toastMessageConstant from 'constants/toastMessage';
import { Email } from 'enums/common';
import { useSnackbar } from 'notistack';
import { setSession } from 'utils/storage';

import VitafyLogo from '../../../assets/images/svg/vitafy-logo.svg';
import SecretKeyModal from './SecretKeyModal';

const MaintenancePage = () => {
  const [openForm, setOpenForm] = useState(false);
  const inputRef = useRef<HTMLInputElement>(null);
  const { enqueueSnackbar } = useSnackbar();

  useEffect(() => {
    const handleKeyPress = (event: KeyboardEvent) => {
      if (event.key === process.env.REACT_APP_SECRET_KEY_FORM_OPEN_CODE) {
        setOpenForm(true);
      }
    };

    window.addEventListener('keypress', handleKeyPress);

    return () => {
      window.removeEventListener('keypress', handleKeyPress);
    };
  }, []);

  const onCloseModal = () => {
    setOpenForm(false);
  };

  const handleSubmitKey = () => {
    const inputValue = inputRef.current?.value;
    if (inputValue === process.env.REACT_APP_MAINTENANCE_PAGE_BYPASS_CODE) {
      setSession('maintenance_bypass_code', inputValue);
      window.location.reload();
      return;
    }
    enqueueSnackbar(toastMessageConstant.INVALID_MAINTENANCE_CODE, {
      variant: 'error',
    });
  };

  return (
    <Box
      alignItems="center"
      display="flex"
      flexDirection="column"
      gap={3}
      height="100vh"
      justifyContent="center"
    >
      <Box alt="vitafy-logo" component="img" height={40} src={VitafyLogo} />

      <Stack spacing={1}>
        <Typography textAlign="center" variant="h5">
          We&apos;ll be back soon
        </Typography>
        <Box>
          <Typography textAlign="center" variant="body2">
            Vitafy is down for scheduled maintenance. Please check back soon.
          </Typography>
          <Typography textAlign="center" variant="body2">
            If you have any question, please contact us at{' '}
            <Typography
              color="secondary.main"
              component="a"
              href={`mailto:${Email.vitafySupport}`}
              sx={{ textDecoration: 'none' }}
              variant="body2"
            >
              {Email.vitafySupport}
            </Typography>
          </Typography>
        </Box>
      </Stack>
      {openForm && (
        <SecretKeyModal
          handleSubmitKey={handleSubmitKey}
          onCloseModal={onCloseModal}
          ref={inputRef}
        />
      )}
    </Box>
  );
};

export default MaintenancePage;
