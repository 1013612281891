import { Components, Theme } from '@mui/material';

const Link = (theme: Theme): Components<Theme> => ({
  MuiLink: {
    defaultProps: {
      underline: 'always',
      variant: 'body2',
    },
    styleOverrides: {
      root: {
        color: theme.palette.secondary.main,
        textDecorationColor: theme.palette.secondary.main,
        ':hover': {
          color: theme.palette.secondary.dark,
        },
      },
    },
  },
});

export default Link;
