/* eslint-disable no-param-reassign */
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import commonConstants from 'constants/common';
import { IInvoiceAdvancedSearchForm } from 'interfaces/billings/invoices';
import { IFilter } from 'interfaces/common';
import { RootState } from 'stores';
import { checkIfValuesInObjectIsEmpty } from 'utils/common';

const { DEFAULT_TABLE_FILTER } = commonConstants;

const advancedSearchDefaultValues = {
  interval: '',
  offeringType: '',
  startDate: '',
  endDate: '',
  isAdvancedSearch: false,
};

interface InvoiceState {
  filters: IFilter;
  advancedSearch: IInvoiceAdvancedSearchForm;
}

const initialState: InvoiceState = {
  filters: { ...DEFAULT_TABLE_FILTER },
  advancedSearch: { ...advancedSearchDefaultValues },
};

export const slice = createSlice({
  name: 'invoice',
  initialState,
  reducers: {
    // Changing the state directly (with mutation) in reducer
    // Redux Toolkit uses Immer under the hood (takes care of immutablility)
    changeFilters: (state, action: PayloadAction<Partial<IFilter>>) => {
      state.filters = { ...state.filters, ...action.payload };
    },

    changeAdvancedSearch: (
      state,
      action: PayloadAction<Partial<IInvoiceAdvancedSearchForm>>
    ) => {
      const advanceFilters = { ...state.advancedSearch, ...action.payload };
      const { isAdvancedSearch, ...rest } = advanceFilters;
      state.advancedSearch = {
        ...advanceFilters,
        isAdvancedSearch: !checkIfValuesInObjectIsEmpty(rest),
      };
      state.advancedSearch = { ...state.advancedSearch, ...action.payload };
    },

    resetFilters: (state) => {
      state.filters = { ...DEFAULT_TABLE_FILTER };
    },

    resetAllFilters: (state) => {
      state.filters = { ...DEFAULT_TABLE_FILTER };
    },
  },
});

// Actions
export const {
  changeFilters,
  resetFilters,
  resetAllFilters,
} = slice.actions;

// Selectors
export const selectInvoiceFilter = (state: RootState) =>
  state.invoice.filters;
export const selectInvoiceAdvancedSearch = (state: RootState) =>
  state.invoice.advancedSearch;

// Reducer
export default slice.reducer;
