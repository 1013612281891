/* eslint-disable no-param-reassign */
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import commonConstants from 'constants/common';
import { IFilter } from 'interfaces/common';
import { RootState } from 'stores';

const { DEFAULT_TABLE_FILTER } = commonConstants;

interface AdvocateCasesState {
  filters: IFilter;
}

const initialState: AdvocateCasesState = {
  filters: { ...DEFAULT_TABLE_FILTER },
};

export const slice = createSlice({
  name: 'advocateCases',
  initialState,
  reducers: {
    // Changing the state directly (with mutation) in reducer
    // Redux Toolkit uses Immer under the hood (takes care of immutablility)
    changeFilters: (state, action: PayloadAction<Partial<IFilter>>) => {
      state.filters = { ...state.filters, ...action.payload };
    },

    resetFilters: (state) => {
      state.filters = { ...DEFAULT_TABLE_FILTER };
    },
  },
});

// Actions
export const { changeFilters } = slice.actions;

// Selectors
export const selectAdvocateCaseFilter = (state: RootState) =>
  state.advocateCasesReport.filters;
export const selectAdvocateCase = (state: RootState) =>
  state.advocateCasesReport;

// Reducer
export default slice.reducer;
