import { TextFieldProps } from '@mui/material';
import commonConstants from 'constants/common';
import { LookUpCategory } from 'enums/lookups';
import { useLookUpQuery } from 'services/lookups';

import FormSelectTextField from './FormSelectTextField';

type Props = {
  name: string;
  label: string;
  showHelperText?: boolean;
  isRequired?: boolean;
} & TextFieldProps;

const FormBillingIntervalSelect = ({
  isRequired,
  label = 'Billing Interval',
  name = 'billingInterval',
  ...props
}: Props) => {
  const billingIntervalQuery = useLookUpQuery(
    {
      category: LookUpCategory.INTERVAL,
      limit: commonConstants.PAGINATION.MAXIMUM_LIMIT,
    },
    { enabled: true }
  );

  if (!billingIntervalQuery.data) {
    return <>...</>;
  }

  const billingIntervalOptions = billingIntervalQuery.data?.map(
    (item, index) => ({
      id: index + 1,
      label: item.name,
      value: item.name,
    })
  );

  return (
    <FormSelectTextField
      data={billingIntervalOptions}
      isRequired
      label={label}
      name={name}
      {...props}
    />
  );
};

FormBillingIntervalSelect.defaultProps = {
  showHelperText: false,
  isRequired: false,
};

export default FormBillingIntervalSelect;
