import React from 'react';

import { FormControl, MenuItem, Select, SelectProps } from '@mui/material';
import paymentConstants from 'constants/payment';
import { AuthorizeDotNetBankAccountType } from 'enums/Payment';

const { AuthorizeDotNetBankAccountTypeOptions: accountTypes } =
  paymentConstants;

const { CHECKING, SAVINGS, BUSINESS_CHECKING } = AuthorizeDotNetBankAccountType;

const PLACEHOLDER = 'Select Account Type';

const VALUE_MAP = {
  [CHECKING]: 'Checking',
  [SAVINGS]: 'Savings',
  [BUSINESS_CHECKING]: 'Business Checking',
};

const AccountTypeSelect = ({ ...selectProps }: SelectProps) => (
  <FormControl className="select-filled-variant" fullWidth size="small">
    <Select
      {...selectProps}
      displayEmpty
      id="account-type-select"
      inputProps={{ 'aria-label': 'Account Type Select Without label' }}
      renderValue={(selected) => {
        if (!selected) {
          return <em>{PLACEHOLDER}</em>;
        }

        return VALUE_MAP[
          selected as AuthorizeDotNetBankAccountType
        ] as React.ReactNode;
      }}
    >
      <MenuItem disabled value="">
        <em>{PLACEHOLDER}</em>
      </MenuItem>
      {accountTypes.map((item) => (
        <MenuItem key={item.value} value={item.value}>
          {item.text}
        </MenuItem>
      ))}
    </Select>
  </FormControl>
);

export default AccountTypeSelect;
