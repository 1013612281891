export enum NetworkOfferingsSortBy {
  BUNDLE_NAME = 'service.name',
  PROVIDER_NAME = 'provider.displayName',
  START_DATE = 'startDate',
  STATUS = 'status',
}

export enum NetworkOfferingsStatus {
  ALL = 'All',
  ACTIVE = 'Active',
  INACTIVE = 'Inactive',
}

export enum NetworkOfferingsCostVisibility {
  PUBLIC = 'Public',
  COMMUNITY = 'Community',
  PRIVATE = 'Private',
}

export enum NetworkOfferingsCostStatus {
  APPROVED = 'Approved',
  SUBMITTED = 'Submitted',
}

export enum NetworkOfferingsCardType {
  PHYSICIAN = 'physician',
  PROVIDER = 'provider',
  SERVICE = 'service',
  OFFERING_COST = 'offeringCost',
}

export enum NetworkOfferingProviderType {
  SERVICE = 'Service',
  FACILITY = 'Facility',
  PHYSICIAN = 'Physician',
}

export enum ProviderEntityType {
  FACILITY = '2',
  Physician = '1',
}

export enum NetworkOfferingAmountType {
  CASH = 'Cash',
  DIRECT = 'Contract',
  AVERAGE = 'Likely',
}
