/* eslint-disable no-param-reassign */
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import commonConstants from 'constants/common';
import { SortOrder } from 'enums/common';
import {
  MembershipOfferingSortBy,
  OfferingSortBy,
} from 'enums/settings/tenant-offerings';
import { IFilter, SortOrderType } from 'interfaces/common';
import { ITenantOfferingAdvancedSearchForm } from 'interfaces/tenant-management/tenant/tenantMembershipOffering';
import { RootState } from 'stores';

const { DEFAULT_TABLE_FILTER } = commonConstants;

const advancedSearchDefaultValues = {
  name: '',
  qtyRange: '',
  qtyAge: '',
  status: '',
};

const sortDefaultValues = {
  sortBy: OfferingSortBy.NAME,
  sortOrder: SortOrder.ASC,
};

interface IOfferingSort {
  sortBy: OfferingSortBy | MembershipOfferingSortBy;
  sortOrder: SortOrderType;
}

interface OfferingState {
  filters: IFilter;
  advancedSearch: ITenantOfferingAdvancedSearchForm;
  sort: IOfferingSort;
}

const initialState: OfferingState = {
  filters: { ...DEFAULT_TABLE_FILTER },
  advancedSearch: { ...advancedSearchDefaultValues },
  sort: { ...sortDefaultValues },
};

export const slice = createSlice({
  name: 'tenantMembershipOffering',
  initialState,
  reducers: {
    // Changing the state directly (with mutation) in reducer
    // Redux Toolkit uses Immer under the hood (takes care of immutablility)
    changeFilters: (state, action: PayloadAction<Partial<IFilter>>) => {
      state.filters = { ...state.filters, ...action.payload };
    },

    resetFilters: (state) => {
      state.filters = { ...DEFAULT_TABLE_FILTER };
    },

    changeAdvancedSearch: (
      state,
      action: PayloadAction<Partial<ITenantOfferingAdvancedSearchForm>>
    ) => {
      state.advancedSearch = { ...state.advancedSearch, ...action.payload };
    },

    resetAdvancedSearch: (state) => {
      state.advancedSearch = { ...advancedSearchDefaultValues };
    },

    changeSortByAndOrder: (state, action: PayloadAction<IOfferingSort>) => {
      state.sort = {
        sortBy: action.payload.sortBy,
        sortOrder: action.payload.sortOrder,
      };
    },

    resetAllFilters: (state) => {
      state.advancedSearch = { ...advancedSearchDefaultValues };
      state.sort = { ...sortDefaultValues };
      state.filters = { ...DEFAULT_TABLE_FILTER };
    },
  },
});

// Actions
export const {
  changeFilters,
  changeAdvancedSearch,
  resetFilters,
  resetAdvancedSearch,
  changeSortByAndOrder,
  resetAllFilters,
} = slice.actions;

// Selectors
export const selectOfferingFilter = (state: RootState) =>
  state.tenantMembershipOffering.filters;
export const selectOfferingSort = (state: RootState) =>
  state.tenantMembershipOffering.sort;

// Reducer
export default slice.reducer;
