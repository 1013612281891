import { useMutation, useQuery } from 'react-query';

import * as clientAgreementAPI from 'api/client-agreement';
import {
  IClientLookupSaveAgreementForm,
  ISaveClientAgreementForm,
  ISendClientAgreementForm,
} from 'interfaces/client-agreement';
import { IError } from 'interfaces/http';
import { useSnackbar } from 'notistack';
import { adaptAgreement } from 'utils/client-agreement';

export const agreementKeys = {
  all: ['subscriptions'] as const,
  detail: () => [...agreementKeys.all, 'detail'] as const,
};

export const useClientAgreementQuery = (code: string, enabled: boolean) => {
  const queryInfo = useQuery(
    agreementKeys.detail(),
    () => clientAgreementAPI.decodeAgreement(code),
    { select: adaptAgreement, enabled }
  );

  return {
    ...queryInfo,
    data: queryInfo.data?.data,
  };
};

export const useClientAgreementLookupMutation = () => {
  const { enqueueSnackbar } = useSnackbar();

  return useMutation(
    ({
      lookupId,
      data,
    }: {
      lookupId: string;
      data: IClientLookupSaveAgreementForm;
    }) => clientAgreementAPI.updateAgreement(lookupId, data),
    {
      onSuccess: (res) => {
        enqueueSnackbar(res.message, {
          variant: 'success',
        });
      },
      onError: (err: IError) => {
        enqueueSnackbar(err.message, {
          variant: 'error',
        });
      },
    }
  );
};

export const useClientAgreementMutation = () => {
  const { enqueueSnackbar } = useSnackbar();

  return useMutation(
    ({ data }: { data: ISaveClientAgreementForm }) =>
      clientAgreementAPI.saveAgreement(data),
    {
      onSuccess: (res) => {
        enqueueSnackbar(res.message, {
          variant: 'success',
        });
      },
      onError: (err: IError) => {
        enqueueSnackbar(err.message, {
          variant: 'error',
        });
      },
    }
  );
};

export const useSendClientAgreementMutation = () => {
  const { enqueueSnackbar } = useSnackbar();

  return useMutation(
    ({ data }: { data: ISendClientAgreementForm }) =>
      clientAgreementAPI.sendAgreement(data),
    {
      onSuccess: (res) => {
        enqueueSnackbar(res.message, {
          variant: 'success',
        });
      },
      onError: (err: IError) => {
        enqueueSnackbar(err.message, {
          variant: 'error',
        });
      },
    }
  );
};

export const useVerifyESignAgreementMutation = () =>
  useMutation(({ data }: { data: { token: string } }) =>
    clientAgreementAPI.verifyESignAgreement(data)
  );
