import {
  IExtraSupportInquiryFields,
  ISupportInquiryForm,
  ISupportInquirySchema,
} from 'interfaces/support-inquiry';

export const formatSupportInquirySavePayload = (
  data: ISupportInquiryForm,
  extraParams: IExtraSupportInquiryFields
): ISupportInquirySchema => {
  const payload: ISupportInquirySchema = {
    name: data.name,
    description: data.description,
    email: data.email,
    priority: data.priority,
    phone: data.phone,
    tenant: extraParams.tenant ?? '',
    pageSource: extraParams.pageSource ?? '',
    userId: extraParams.userId ?? '',
    ...(extraParams?.tenantId && { tenantId: extraParams?.tenantId }),
  };
  return payload;
};
