import { useEffect, useState } from 'react';
import { useAcceptJs } from 'react-acceptjs';
import { useFormContext } from 'react-hook-form';

import { Alert, Grid, Typography } from '@mui/material';
import { AuthorizeDotNetPaymentEnvironment } from 'enums/Payment';
import { IPaymentForm } from 'interfaces/payment';
import { useAppSelector } from 'stores/hooks';
import { selectAuthorizeDotNet } from 'stores/Payment';
import {
  validateCardCVC,
  validateCardExpiry,
  validateCardNumber,
} from 'utils/payment';

import FilledCardDetail from './FilledCardDetail';
import MaskedCardCVCInput from './MaskedCardCVCInput';
import MaskedCardExpiryInput from './MaskedCardExpiry';
import MaskedCardInput from './MaskedCardInput';

const CardPayment = () => {
  const authorizeDotNetData = useAppSelector(selectAuthorizeDotNet);
  const { authData, environment } = authorizeDotNetData;

  const { dispatchData, loading, error } = useAcceptJs({
    environment: environment as AuthorizeDotNetPaymentEnvironment,
    authData,
  });

  const { setValue, watch } = useFormContext<IPaymentForm>();
  const { cardNumber, cardExpiry, cardCVC } = watch();

  const [cardData, setCardData] = useState({
    cardNumber: '',
    expiry: '',
    cardCode: '',
  });
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [errorRes, setErrorRes] = useState('');

  const handleChangeCardData = (name: any, data: any) => {
    setCardData((prevState) => ({ ...prevState, [name]: data }));
    setErrorRes('');
  };

  // Validate card data on input blur (cardNumber, expiry and CVC) and dispatch data to Authorize.Net server if validated.
  // Local validation is simply checking if the inputs are filled.
  const handleInputBlur = async () => {
    const { cardNumber: cardNumberLocal, expiry, cardCode } = cardData;

    const isDataFilled =
      validateCardNumber(cardNumberLocal) &&
      validateCardExpiry(expiry) &&
      validateCardCVC(cardCode);

    if (isDataFilled) {
      const payload = {
        cardNumber: cardNumberLocal,
        month: expiry.slice(0, 2),
        year: `20${expiry.slice(-2)}`, // 24 -> 2024
        cardCode,
      };

      try {
        setIsSubmitting(true);
        const response = await dispatchData({ cardData: payload });

        if (response?.messages?.resultCode === 'Ok') {
          setValue('cardToken', response?.opaqueData?.dataValue);
          setValue('cardExpiry', expiry);
          setValue('cardNumber', cardNumberLocal);
          setValue('cardCVC', cardCode);
          setValue('tokenSource', response?.opaqueData?.dataDescriptor);
        }
      } catch (err: any) {
        setErrorRes(err?.messages?.message?.[0]?.text);
      } finally {
        setIsSubmitting(false);
      }
    }
  };

  // Initialize with the existing card information if present
  useEffect(() => {
    if (cardNumber && cardExpiry && cardCVC) {
      setCardData({
        cardNumber,
        expiry: cardExpiry,
        cardCode: cardCVC,
      });
    }
  }, [cardNumber, cardExpiry, cardCVC]);

  const resetCardInformation = () => {
    setValue('cardToken', '');
    setValue('cardExpiry', '');
    setValue('cardNumber', '');
    setValue('cardCVC', '');
    setValue('tokenSource', '');

    setCardData({
      cardNumber: '',
      expiry: '',
      cardCode: '',
    });
  };

  if (loading) {
    return <>Loading...</>;
  }

  if (error) {
    return <>Error loading Authorize.Net payment gateway</>;
  }

  if (cardNumber && cardExpiry && cardCVC) {
    return (
      <FilledCardDetail
        cardExpiry={cardExpiry}
        cardNumber={cardNumber}
        onEditClick={resetCardInformation}
      />
    );
  }

  return (
    <>
      <Typography
        color="text.secondary"
        fontWeight={(theme) => theme.typography.fontWeightMedium}
        gutterBottom={false}
        variant="body1"
      >
        Card information
      </Typography>

      <Grid container>
        <Grid item xs={8}>
          <MaskedCardInput
            disabled={isSubmitting}
            onBlur={handleInputBlur}
            onChange={(data: any) =>
              handleChangeCardData('cardNumber', data.value)
            }
            value={cardData.cardNumber}
          />
        </Grid>
        <Grid item pl={2} xs={4}>
          <Grid container spacing={2}>
            <Grid item xs={6}>
              <MaskedCardExpiryInput
                disabled={isSubmitting}
                onBlur={handleInputBlur}
                onChange={(data: any) =>
                  handleChangeCardData('expiry', data.value)
                }
                value={cardData.expiry}
              />
            </Grid>
            <Grid item xs={6}>
              <MaskedCardCVCInput
                disabled={isSubmitting}
                onBlur={handleInputBlur}
                onChange={(data: any) =>
                  handleChangeCardData('cardCode', data.value)
                }
                value={cardData.cardCode}
              />
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      {isSubmitting && <p className="text-sm text-bodyColor">Please wait...</p>}
      {!!errorRes && <Alert severity="error">{errorRes}</Alert>}
    </>
  );
};

export default CardPayment;
