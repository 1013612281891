export enum OfferingSortBy {
  NAME = 'name',
  QTY_RANGE = 'qtyRange',
  AGE_RANGE = 'ageRange',
  STATUS = 'status',
}

export const enum MembershipStatus {
  ACTIVE = 'Active',
  INACTIVE = 'Inactive',
  NOT_APPLICABLE = 'Not Applicable',
}

export enum BillingInterval {
  ONE_TIME = 'One Time',
  MONTHLY = 'Monthly',
  BI_YEARLY = 'Bi-yearly',
  YEARLY = 'Yearly',
  QUARTERLY = 'Quarterly',
}

export enum BillingType {
  FAMILY = 'Family',
  GROUP = 'Group',
  INDIVIDUAL = 'Individual',
}

export enum OfferingVisibility {
  PUBLIC = 'Public',
  PRIVATE = 'Private',
}

export enum OfferingStatus {
  ACTIVE = 'Active',
  INACTIVE = 'Inactive',
}

export enum TenantOfferingCategory {
  INDIVIDUAL = 'Individual',
  GROUP = 'Group',
}

export enum TenantOfferingCategoryFull {
  INDIVIDUAL = 'Individual & Family',
  GROUP = 'Company & Group',
}

export enum FormattedBillingInterval {
  MONTHLY = 'month',
  YEARLY = 'year',
  BI_YEARLY = 'biannual',
  ONE_TIME = 'one time',
  QUARTERLY = '3 months',
}

export enum MembershipOfferingSortBy {
  NAME = 'name',
  PRICE = 'price',
  VISIBILITY = 'visibility',
}

export enum AncillaryOfferingSortBy {
  NAME = 'name',
  NON_MEMBER_PRICE = 'nonMemberPrice',
  MEMBER_PRICE = 'memberPrice',
  VISIBILITY = 'visibility',
}

export enum TenantServiceSortBy {
  NAME = 'name',
}
