import { useFormContext } from 'react-hook-form';

import { TextFieldProps } from '@mui/material';
import TagInput from 'common/ui/inputs/TagInput';

type Props = {
  name: string;
  onSelect?: (data: string[]) => void;
  showHelperText?: boolean;
} & TextFieldProps;

const FormTagInput = ({
  name,
  onSelect,
  showHelperText,
  disabled,
  ...others
}: Props): JSX.Element => {
  const methods = useFormContext();
  const {
    setValue,
    formState: { errors },
    clearErrors,
    watch,
  } = methods;

  const watchedValue = watch(name);

  const handleSelect = (value: string[]) => {
    setValue(name, value);

    clearErrors(name);

    if (onSelect !== undefined) {
      onSelect(value);
    }
  };

  return (
    <TagInput
      disabled={disabled}
      inputProps={{
        ...others,
        error: !!errors[name],
        helperText: showHelperText
          ? (errors[name]?.message as React.ReactNode)
          : null,
      }}
      onSelectHandler={handleSelect}
      value={watchedValue}
    />
  );
};

FormTagInput.defaultProps = {
  showHelperText: false,
  onSelect: undefined,
};

export default FormTagInput;
