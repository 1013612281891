/* eslint-disable no-nested-ternary */
import { CometChat } from '@cometchat-pro/chat';
import { CallActionType, CallType, SenderType } from 'enums/virtual-visit';
import {
  ICometChatMessage,
  IConversation,
} from 'interfaces/virtual-visit/comet-chat';
import { capitalizeFirstLetter } from 'utils/common';
import { momentViewFormat } from 'utils/moment';

export class ChatUtility {
  static transformChatList = (
    chatList: CometChat.Conversation[],
    userID: string
  ): IConversation[] =>
    chatList.map((convo) => {
      const otherUser = convo.getConversationWith() as CometChat.User;
      return {
        id: convo.getConversationId(),
        lastMessage: this.transformSingleMessage(
          convo.getLastMessage(),
          userID
        ),
        otherUserName: otherUser.getName(),
        otherUserAvatar: otherUser.getAvatar(),
        otherUserID: otherUser.getUid(),
      };
    });

  static transformMessages = (
    messageList: CometChat.BaseMessage[],
    userID: string
  ): ICometChatMessage[] =>
    messageList.map((item) => this.transformSingleMessage(item, userID));

  static transformSingleMessage = (
    message: CometChat.BaseMessage,
    userID: string
  ): ICometChatMessage => {
    const id = message.getId().toString();
    const conversationID = message.getConversationId();
    const isTextMessage = message instanceof CometChat.TextMessage;
    const isMediaMessage = message instanceof CometChat.MediaMessage;
    const isCallMessage = message instanceof CometChat.Call;
    const isSentByMe = userID === message.getSender().getUid();
    const initiatorName = message.getSender().getName();
    const receiverName = message.getReceiver().getName();
    const messageInitiator = isSentByMe ? SenderType.YOU : initiatorName;

    // call message configurations
    let callType: CallType | undefined;
    let callActionType: CallActionType | undefined;
    let callMessage = '';
    if (isCallMessage) {
      callType = message.getType() as CallType;
      callActionType = message.getAction() as CallActionType;
      callMessage = this.getCallMessage(
        callType,
        callActionType,
        initiatorName,
        receiverName,
        isSentByMe
      );
    }

    // media message configurations
    const mediaMessage = `${messageInitiator} sent a file.`;

    // selecting message based on message type
    let text = '';
    if (isTextMessage) {
      text = message.getText();
    } else if (isCallMessage) {
      text = callMessage;
    } else if (isMediaMessage) {
      text = mediaMessage;
    }

    const sentAt = momentViewFormat(new Date(message.getSentAt()));
    const time = momentViewFormat(sentAt, 'h a').toString();
    const date = momentViewFormat(sentAt, 'D MMM').toString();

    return {
      id,
      conversationID,
      text,
      initiatorName,
      receiverName,
      isSentByMe,
      isTextMessage,
      isCallMessage,
      isMediaMessage,
      callType,
      callActionType,
      time,
      date,
    };
  };

  static getCallMessage = (
    callType: CallType,
    callActionType: CallActionType,
    initiatorName: string,
    receiverName: string,
    isSentByMe: boolean
  ): string => {
    let callMessage = '';
    const article = callType === CallType.AUDIO ? CallType.AN : CallType.A;
    const callInitiator = isSentByMe ? SenderType.YOU : initiatorName;
    let missedCallUser = ''; // user who missed the call
    // eslint-disable-next-line default-case
    switch (callActionType) {
      case CallActionType.INITIATED:
        callMessage = `${callInitiator} started ${article} ${callType} call.`;
        break;
      case CallActionType.ONGOING:
        callMessage = `Ongoing Call.`;
        break;
      case CallActionType.ENDED:
        callMessage = `${capitalizeFirstLetter(callType)} call ended.`;
        break;
      case CallActionType.CANCELLED:
        missedCallUser = isSentByMe ? receiverName : SenderType.YOU;
        callMessage = `${missedCallUser} missed ${article} ${callType} call.`;
        break;
      case CallActionType.UNANSWERED:
        missedCallUser = isSentByMe ? receiverName : SenderType.YOU;
        callMessage = `${missedCallUser} missed ${article} ${callType} call.`;
        break;
      case CallActionType.REJECTED:
        missedCallUser = isSentByMe ? SenderType.YOU : receiverName;
        callMessage = `${missedCallUser} missed ${article} ${callType} call.`;
        break;
    }
    return callMessage;
  };
}
