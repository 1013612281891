/* eslint-disable no-param-reassign */
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import commonConstants from 'constants/common';
import { SortOrder } from 'enums/common';
import { ReportFilterBy } from 'enums/reports';
import { SalesByCompanySortBy } from 'enums/reports/sales-by-company';
import { IFilter, SortOrderType } from 'interfaces/common';
import {
  ISalesByCompanyAdvancedSearchForm,
  SalesByCompanySortByType,
} from 'interfaces/reports/sales-by-company';
import { RootState } from 'stores';

const { DEFAULT_TABLE_FILTER } = commonConstants;

const advancedSearchDefaultValues = {
  interval: ReportFilterBy.QUATERLY,
};

interface ISalesByCompanySort {
  sortBy: SalesByCompanySortByType;
  sortOrder: SortOrderType;
}

interface SalesByCompanyState {
  filters: IFilter;
  advancedSearch: ISalesByCompanyAdvancedSearchForm;
  sort: ISalesByCompanySort;
}

const initialState: SalesByCompanyState = {
  filters: { ...DEFAULT_TABLE_FILTER },
  advancedSearch: { ...advancedSearchDefaultValues },
  sort: {
    sortBy: SalesByCompanySortBy.COMPANY,
    sortOrder: SortOrder.ASC,
  },
};

export const slice = createSlice({
  name: 'salesByCompany',
  initialState,
  reducers: {
    // Changing the state directly (with mutation) in reducer
    // Redux Toolkit uses Immer under the hood (takes care of immutablility)
    changeFilters: (state, action: PayloadAction<Partial<IFilter>>) => {
      state.filters = { ...state.filters, ...action.payload };
    },

    resetFilters: (state) => {
      state.filters = { ...DEFAULT_TABLE_FILTER };
    },

    changeAdvancedSearch: (
      state,
      action: PayloadAction<Partial<ISalesByCompanyAdvancedSearchForm>>
    ) => {
      state.advancedSearch = { ...state.advancedSearch, ...action.payload };
    },

    resetAdvancedSearch: (state) => {
      state.advancedSearch = { ...advancedSearchDefaultValues };
    },

    changeSortByAndOrder: (
      state,
      action: PayloadAction<ISalesByCompanySort>
    ) => {
      state.sort = {
        sortBy: action.payload.sortBy,
        sortOrder: action.payload.sortOrder,
      };
    },
  },
});

// Actions
export const {
  changeFilters,
  changeAdvancedSearch,
  resetFilters,
  resetAdvancedSearch,
  changeSortByAndOrder,
} = slice.actions;

// Selectors
export const selectSalesByCompanyFilter = (state: RootState) =>
  state.salesByCompany.filters;
export const selectSalesByCompanySort = (state: RootState) =>
  state.salesByCompany.sort;

export const selectSalesByCompanyAdvancedSearch = (state: RootState) =>
  state.salesByCompany.advancedSearch;
export const selectSalesByCompany = (state: RootState) => state.salesByCompany;

// Reducer
export default slice.reducer;
