/* eslint-disable no-param-reassign */
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import commonConstants from 'constants/common';
import { Interval } from 'enums/common';
import { IFilter } from 'interfaces/common';
import { IActivityAdvancedSearchForm } from 'interfaces/timesheet/activity';
import { RootState } from 'stores';

const { DEFAULT_TABLE_FILTER } = commonConstants;

const advancedSearchDefaultValues = {
  interval: Interval.WEEKLY,
  advocateId: '',
  advocate: '',
  sponsorId: '',
  sponsor: '',
  memberId: '',
  member: '',
  caseId: '',
  tenantId: '',
  startDate: '',
  endDate: '',
};

interface ActivityState {
  filters: IFilter;
  advancedSearch: IActivityAdvancedSearchForm;
}

const initialState: ActivityState = {
  filters: { ...DEFAULT_TABLE_FILTER },
  advancedSearch: { ...advancedSearchDefaultValues },
};

export const slice = createSlice({
  name: 'activity',
  initialState,
  reducers: {
    // Changing the state directly (with mutation) in reducer
    // Redux Toolkit uses Immer under the hood (takes care of immutablility)
    changeFilters: (state, action: PayloadAction<Partial<IFilter>>) => {
      state.filters = { ...state.filters, ...action.payload };
    },

    resetFilters: (state) => {
      state.filters = { ...DEFAULT_TABLE_FILTER };
    },
    resetAllFilters: (state) => {
      state.advancedSearch = { ...advancedSearchDefaultValues };
      state.filters = {
        ...state.filters,
        ...DEFAULT_TABLE_FILTER,
      };
    },
    changeAdvancedSearch: (
      state,
      action: PayloadAction<Partial<IActivityAdvancedSearchForm>>
    ) => {
      state.advancedSearch = { ...state.advancedSearch, ...action.payload };
    },

    resetAdvancedSearch: (state) => {
      state.advancedSearch = { ...advancedSearchDefaultValues };
    },
  },
});

// Actions
export const {
  changeFilters,
  changeAdvancedSearch,
  resetFilters,
  resetAllFilters,
  resetAdvancedSearch,
} = slice.actions;

// Selectors
export const selectActivityFilter = (state: RootState) =>
  state.activity.filters;
export const selectActivityAdvancedSearch = (state: RootState) =>
  state.activity.advancedSearch;
export const selectActivity = (state: RootState) => state.activity;

// Reducer
export default slice.reducer;
