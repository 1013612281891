import { useEffect, useMemo, useState } from 'react';

import {
  Autocomplete,
  Box,
  CircularProgress,
  debounce,
  TextField,
  TextFieldProps,
  Typography,
} from '@mui/material';
import commonConstants from 'constants/common';
import {
  IAdaptedUserTableRow,
  IUserFilter,
} from 'interfaces/tenant-management/user';
import {
  useUserDetailQuery,
  useUsersQuery,
} from 'services/tenant-management/users';
import { selectAuthTenantData } from 'stores/auth';
import { useAppSelector } from 'stores/hooks';

const { DEFAULT_TABLE_FILTER } = commonConstants;

interface IProps {
  inputProps?: TextFieldProps;
  onSelectHandler: (user: IAdaptedUserTableRow) => void;
  userId?: string;
}

const AdvocateAutocomplete = ({
  inputProps,
  onSelectHandler,
  userId,
}: IProps): JSX.Element => {
  const [open, setOpen] = useState(false);

  const tenantData = useAppSelector(selectAuthTenantData);

  const [value, setValue] = useState<IAdaptedUserTableRow | null>(null);
  const [filters, setFilters] = useState<IUserFilter>({
    ...DEFAULT_TABLE_FILTER,
    tenantId: tenantData?.tenantAssociation.tenantId ?? '',
  });

  const usersQuery = useUsersQuery(filters, { fetchAdminUsers: true });

  const userDetail = useUserDetailQuery(userId!, {
    enabled: !!userId,
  });

  // Populates the value (edit mode)
  useEffect(() => {
    if (userDetail.data) {
      setValue(userDetail.data);
    }
  }, [userDetail.data]);

  const handleInputChange = async (_: any, newInputValue: string) => {
    setFilters((prevState) => ({ ...prevState, keyword: newInputValue }));
  };

  const onChange = async (user: IAdaptedUserTableRow | undefined) => {
    if (!user) {
      return;
    }
    onSelectHandler(user);
  };

  const debouncedInputChange = useMemo(
    () => debounce(handleInputChange, 300),
    []
  );

  const getOptionName = (option: IAdaptedUserTableRow) =>
    option.demographic.fullName;

  const options = usersQuery?.data?.rows || [];

  return (
    <Autocomplete
      autoComplete
      filterOptions={(x) => x}
      filterSelectedOptions
      getOptionLabel={(option) =>
        typeof option === 'string' ? option : getOptionName(option)
      }
      id="async-advocate-autocomplete"
      includeInputInList
      isOptionEqualToValue={(option, newValue) =>
        option.userId === newValue.userId
      }
      loading={usersQuery.isLoading}
      onChange={(_, newValue: IAdaptedUserTableRow | null) => {
        if (newValue) {
          onChange(newValue);
          setValue(newValue);
        }
      }}
      onClose={() => {
        setOpen(false);
      }}
      onInputChange={debouncedInputChange}
      onOpen={() => {
        setOpen(true);
      }}
      open={open}
      openOnFocus={false}
      options={options}
      renderInput={(params) => (
        // You can use different variations of textfield here.
        // Please refer TextfieldOverview.tsx for reference.
        <TextField
          {...params}
          {...inputProps}
          fullWidth
          InputProps={{
            ...params.InputProps,
            endAdornment: (
              <>
                {usersQuery.isLoading ? (
                  <CircularProgress color="inherit" size={20} />
                ) : null}
                {params.InputProps.endAdornment}
              </>
            ),
          }}
          label={inputProps?.label ?? 'Advocate'}
          placeholder={inputProps?.placeholder ?? 'Type name or email'}
          size="small"
          variant="standard"
        />
      )}
      renderOption={(props, option) => (
        <Box
          {...props}
          component="li"
          key={option.userId}
          sx={{
            '&:hover': {
              color: (theme) => theme.palette.secondary.main,
            },
          }}
        >
          <Typography color="inherit" gutterBottom={false} variant="body2">
            {getOptionName(option)}
          </Typography>
        </Box>
      )}
      value={value}
    />
  );
};

AdvocateAutocomplete.defaultProps = {
  inputProps: null,
  userId: '',
};

export default AdvocateAutocomplete;
