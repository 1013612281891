import { SyntheticEvent, useEffect, useMemo, useState } from 'react';

import {
  Autocomplete,
  Box,
  CircularProgress,
  debounce,
  TextField,
  TextFieldProps,
  Typography,
} from '@mui/material';
import commonConstants from 'constants/common';
import { IFilter } from 'interfaces/common';
import { IAdaptedContactTableRow } from 'interfaces/settings/contacts';
import { useContactsQuery } from 'services/settings/contacts';
import { selectAuthTenantAssociation } from 'stores/auth';
import { selectEditClientData } from 'stores/client-management';
import { useAppSelector } from 'stores/hooks';

const { PAGINATION } = commonConstants;

interface IProps {
  disabled?: boolean;
  inputProps?: TextFieldProps & { 'data-cy'?: string };
  label?: string;
  placeholder?: string;
  onSelectHandler: (contact?: IAdaptedContactTableRow) => void;
  selectedValue?: string;
}

const ReferringAgentAutocomplete = ({
  disabled,
  selectedValue,
  label,
  placeholder,
  inputProps,
  onSelectHandler,
}: IProps): JSX.Element => {
  const [open, setOpen] = useState(false);

  const [value, setValue] = useState<
    IAdaptedContactTableRow | null | undefined
  >(null);

  const authTenantId = useAppSelector(selectAuthTenantAssociation)?.tenantId;
  const editClientData = useAppSelector(selectEditClientData);
  const tenantId = editClientData ? editClientData.tenantId : authTenantId;

  const [filters, setFilters] = useState<IFilter>({
    keyword: '',
    offset: PAGINATION.MINIMUM_OFFSET,
    limit: PAGINATION.MAXIMUM_LIMIT,
  });

  const contactsQuery = useContactsQuery({ ...filters, tenantId });

  const options = useMemo(
    () => contactsQuery?.data?.rows || [],
    [contactsQuery?.data?.rows]
  );

  useEffect(() => {
    if (selectedValue && options) {
      const exist = options.find(
        (option) => option.contactId === selectedValue
      );
      if (exist) setValue(exist);
    } else {
      setValue(null);
    }
  }, [options, selectedValue]);

  const handleInputChange = async (
    _: SyntheticEvent<Element, Event>,
    newInputValue: string
  ) => {
    if (newInputValue.length > 2 || !newInputValue.length)
      setFilters((prevState) => ({ ...prevState, keyword: newInputValue }));
  };

  const onChange = async (contact?: IAdaptedContactTableRow) => {
    onSelectHandler(contact);
  };

  const debouncedInputChange = useMemo(
    () => debounce(handleInputChange, 300),
    []
  );

  const getOptionName = (option: IAdaptedContactTableRow) => option.fullName;

  return (
    <Autocomplete
      autoComplete
      disabled={disabled}
      filterOptions={(x) => x}
      filterSelectedOptions
      getOptionLabel={(option) =>
        typeof option === 'string' ? option : getOptionName(option)
      }
      id="async-referring-agent-autocomplete"
      includeInputInList
      isOptionEqualToValue={(option, newValue) =>
        option.contactId === newValue.contactId
      }
      loading={contactsQuery.isLoading}
      onChange={(_, newValue: IAdaptedContactTableRow | null) => {
        onChange(newValue || undefined);
        setValue(newValue);
      }}
      onClose={() => {
        setOpen(false);
      }}
      onInputChange={debouncedInputChange}
      onOpen={() => {
        setOpen(true);
      }}
      open={open}
      openOnFocus={false}
      options={options}
      renderInput={(params) => (
        // You can use different variations of textfield here.
        // Please refer TextfieldOverview.tsx for reference.
        <TextField
          {...params}
          {...inputProps}
          fullWidth
          InputProps={{
            ...params.InputProps,
            endAdornment: (
              <>
                {contactsQuery.isLoading ? (
                  <CircularProgress color="inherit" size={20} />
                ) : null}
                {params.InputProps.endAdornment}
              </>
            ),
          }}
          // eslint-disable-next-line react/jsx-no-duplicate-props
          inputProps={{
            ...params.inputProps,
            ...inputProps?.inputProps,
          }}
          label={inputProps?.label ?? label}
          placeholder={inputProps?.placeholder ?? placeholder}
          size="small"
          variant="standard"
        />
      )}
      renderOption={(props, option) => (
        /* eslint-disable react/jsx-props-no-spreading */
        <Box
          {...props}
          component="li"
          key={option.contactId}
          sx={{
            '&:hover': {
              color: (theme) => theme.palette.secondary.main,
            },
          }}
        >
          <Typography color="inherit" gutterBottom={false} variant="body2">
            {getOptionName(option)}
          </Typography>
        </Box>
      )}
      value={value}
    />
  );
};

ReferringAgentAutocomplete.defaultProps = {
  inputProps: null,
  label: 'Referred by',
  placeholder: 'Search',
  selectedValue: '',
  disabled: false,
};

export default ReferringAgentAutocomplete;
