import { useFormContext } from 'react-hook-form';

import { TextFieldProps } from '@mui/material';
import NetworkTaxonomyAutocomplete from 'common/autocomplete/NetworkTaxonomyAutocomplete';
import { INetworkTaxonomyAutocompleteOption } from 'interfaces/networks/network-taxonomy';

type Props = {
  name: string;
  showHelperText?: boolean;
  onSelectHandler?: (data: INetworkTaxonomyAutocompleteOption[]) => void;
  limitTags?: number;
} & TextFieldProps;

const FormNetworkTaxonomyAutocomplete = ({
  name,
  showHelperText,
  onSelectHandler,
  limitTags,
  ...others
}: Props): JSX.Element => {
  const methods = useFormContext();
  const {
    setValue,
    formState: { errors },
  } = methods;

  const defaultValue = methods.getValues(name);

  const setFormFieldValue = (values: INetworkTaxonomyAutocompleteOption[]) => {
    setValue(name, values);

    if (onSelectHandler !== undefined) {
      onSelectHandler(values);
    }
  };

  return (
    <NetworkTaxonomyAutocomplete
      defaultValue={defaultValue}
      inputProps={{
        ...others,
        error: !!errors[name],
        helperText: showHelperText
          ? (errors[name]?.message as React.ReactNode)
          : null,
      }}
      limitTags={limitTags}
      onSelectHandler={setFormFieldValue}
    />
  );
};

FormNetworkTaxonomyAutocomplete.defaultProps = {
  showHelperText: false,
  onSelectHandler: undefined,
  limitTags: undefined,
};

export default FormNetworkTaxonomyAutocomplete;
