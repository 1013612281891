/* eslint-disable no-param-reassign */
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import commonConstants from 'constants/common';
import { AppointmentSortBy } from 'enums/appointment';
import { SortOrder } from 'enums/common';
import { IFilter } from 'interfaces/common';
import { ITestReportAdvancedSearchForm } from 'interfaces/test-report/index';
import { RootState } from 'stores';
import { checkIfValuesInObjectIsEmpty } from 'utils/common';

const { DEFAULT_TABLE_FILTER } = commonConstants;

const advancedSearchDefaultValues = {
  status: '',
  isAdvancedSearch: false,
};

interface IState {
  filters: IFilter;
  advancedSearch: ITestReportAdvancedSearchForm;
  sort: any;
}

const sortDefaultValues = {
  sortBy: AppointmentSortBy.APPOINTMENT_DATE,
  sortOrder: SortOrder.DESC,
};
const initialState: IState = {
  filters: { ...DEFAULT_TABLE_FILTER },
  advancedSearch: { ...advancedSearchDefaultValues },
  sort: { ...sortDefaultValues },
};

export const slice = createSlice({
  name: 'appointment',
  initialState,
  reducers: {
    // Changing the state directly (with mutation) in reducer
    // Redux Toolkit uses Immer under the hood (takes care of immutablility)
    changeFilters: (state, action: PayloadAction<Partial<IFilter>>) => {
      state.filters = { ...state.filters, ...action.payload };
    },

    resetFilters: (state) => {
      state.filters = { ...DEFAULT_TABLE_FILTER };
    },

    resetAllFilters: (state) => {
      state.advancedSearch = { ...advancedSearchDefaultValues };
      state.filters = {
        ...state.filters,
        ...DEFAULT_TABLE_FILTER,
      };
    },

    changeAdvancedSearch: (
      state,
      action: PayloadAction<Partial<ITestReportAdvancedSearchForm>>
    ) => {
      const advanceFilters = { ...state.advancedSearch, ...action.payload };
      const { isAdvancedSearch, ...rest } = advanceFilters;
      state.advancedSearch = {
        ...advanceFilters,
        isAdvancedSearch: !checkIfValuesInObjectIsEmpty(rest),
      };
      state.advancedSearch = { ...state.advancedSearch, ...action.payload };
    },
    changeSortByAndOrder: (state, action: PayloadAction<any>) => {
      state.sort = {
        sortBy: action.payload.sortBy,
        sortOrder: action.payload.sortOrder,
      };
    },
    resetAdvancedSearch: (state) => {
      state.advancedSearch = { ...advancedSearchDefaultValues };
    },
  },
});

// Actions
export const {
  changeFilters,
  changeAdvancedSearch,
  changeSortByAndOrder,
  resetFilters,
  resetAllFilters,
  resetAdvancedSearch,
} = slice.actions;

// Selectors
export const selectAppointmentFilter = (state: RootState) =>
  state.appointment.filters;
export const selectAppointmentAdvancedSearch = (state: RootState) =>
  state.appointment.advancedSearch;
export const selectAppointment = (state: RootState) => state.appointment;
export const selectAppointmentSort = (state: RootState) =>
  state.appointment.sort;

// Reducer
export default slice.reducer;
