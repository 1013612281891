import { Box, Typography } from '@mui/material';
import { selectAuthTenantData } from 'stores/auth';
import { useAppSelector } from 'stores/hooks';

const Home = () => {
  const tenantData = useAppSelector(selectAuthTenantData);
  const username = tenantData
    ? `${tenantData?.firstName} ${tenantData?.lastName},`
    : '';
  return (
    <Box
      alignItems="center"
      display="flex"
      flexDirection="column"
      height="80vh"
      justifyContent="center"
      mt={5}
    >
      <Typography color="text.secondary" textAlign="center" variant="h1">
        Welcome {username}
      </Typography>
      <Typography color="text.secondary" textAlign="center" variant="body1">
        Use the menu on the left to proceed.
      </Typography>
    </Box>
  );
};
export default Home;
