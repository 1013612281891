/* eslint-disable no-param-reassign */
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import config from 'config';
import {
  AuthorizeDotNetPaymentEnvironment,
  GatewayType,
  PaymentEnvironment,
} from 'enums/Payment';
import { IGatewayDetail } from 'interfaces/payment';
import { RootState } from 'stores';

interface IPaymentState {
  gatewayDetail: IGatewayDetail | null;
  cardConnect: {
    environment: PaymentEnvironment | '';
    baseUrl: string;
  };
  authorizeDotNet: {
    environment: AuthorizeDotNetPaymentEnvironment | '';
    authData: {
      clientKey: string;
      apiLoginID: string;
    };
  };
  stripe: {
    environment: PaymentEnvironment | '';
    publishableKey: string;
    secretKey: string;
  };
}

const initialState: IPaymentState = {
  gatewayDetail: null,
  cardConnect: {
    environment: '',
    baseUrl: '',
  },
  authorizeDotNet: {
    environment: '',
    authData: {
      clientKey: '',
      apiLoginID: '',
    },
  },
  stripe: {
    environment: '',
    publishableKey: '',
    secretKey: '',
  },
};

export const slice = createSlice({
  name: 'payment',
  initialState,
  reducers: {
    // Changing the state directly (with mutation) in reducer
    // Redux Toolkit uses Immer under the hood (takes care of immutablility)
    setGatewayDetail: (state, action: PayloadAction<IGatewayDetail>) => {
      const {
        environment,
        gatewayType,
        clientKey_live: clientKeyLive,
        clientKey_test: clientKeyTest,
        merchantId_live: merchantIdLive,
        merchantId_test: merchantIdTest,
        authorization_live: authorizationLive,
        authorization_test: authorizationTest,
      } = action.payload;

      state.gatewayDetail = {
        ...action.payload,
      };

      if (gatewayType === GatewayType.CARD_CONNECT) {
        state.cardConnect = {
          environment,
          baseUrl:
            environment.toLowerCase() === PaymentEnvironment.TEST.toLowerCase()
              ? config.card_connect_iframe_url_test
              : config.card_connect_iframe_url_live,
        };
      }

      if (gatewayType === GatewayType.AUTHORIZE_DOT_NET) {
        const isTestEnv =
          environment.toLowerCase() === PaymentEnvironment.TEST.toLowerCase();
        state.authorizeDotNet = {
          environment: isTestEnv
            ? AuthorizeDotNetPaymentEnvironment.TEST
            : AuthorizeDotNetPaymentEnvironment.LIVE,
          authData: {
            clientKey: isTestEnv ? clientKeyTest! : clientKeyLive!,
            apiLoginID: isTestEnv ? merchantIdTest : merchantIdLive,
          },
        };
      }

      if (gatewayType === GatewayType.STRIPE) {
        const isTestEnv =
          environment.toLowerCase() === PaymentEnvironment.TEST.toLowerCase();
        state.stripe = {
          environment,
          publishableKey: isTestEnv ? authorizationTest : authorizationLive,
          secretKey: isTestEnv ? clientKeyTest! : clientKeyLive!,
        };
      }
    },
  },
});

// Actions
export const { setGatewayDetail } = slice.actions;

// Selectors
export const selectGatewayDetail = (state: RootState) =>
  state.payment.gatewayDetail;
export const selectCardConnect = (state: RootState) =>
  state.payment.cardConnect;
export const selectAuthorizeDotNet = (state: RootState) =>
  state.payment.authorizeDotNet;
export const selectStripe = (state: RootState) => state.payment.stripe;

// Reducer
export default slice.reducer;
