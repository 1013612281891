import { Avatar, SxProps } from '@mui/material';
import AvatarPlaceholder from 'assets/images/png/avatar-placeholder.png';

interface Props {
  src?: string;
  alt?: string;
  sx?: SxProps;
  fallbackImage?: any;
}

const AvatarDisplay = ({ alt = 'avatar', src, sx, fallbackImage }: Props) => (
  <Avatar alt={alt} src={src || fallbackImage} sx={sx} />
);

AvatarDisplay.defaultProps = {
  src: undefined,
  alt: 'avatar',
  sx: null,
  fallbackImage: AvatarPlaceholder,
};

export default AvatarDisplay;
