import { useMemo } from 'react';
import { Link as RouteLink, useLocation } from 'react-router-dom';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Box, Typography } from '@mui/material';
import { IPermission } from 'interfaces/common';
import { Icons } from 'lib/font-awesome';

interface IProps {
  menu: IPermission;
  isActive: boolean;
}

const SidebarMenu = ({ menu, isActive }: IProps) => {
  const location = useLocation();

  // a random icon if icon not present.
  // Needed 'cause Typescript was showing type error
  // Not rendered whatsoever if icon is not present
  const Icon = Icons[menu.icon] ? Icons[menu.icon] : Icons.faFile;

  const routePath = useMemo(
    () =>
      menu.children && menu.children.length > 0
        ? menu.children[0].routePath
        : menu.routePath,
    [menu]
  );

  const isOnSameRoute = location.pathname === routePath;
  const currentRoutePath = location.pathname + location.search;

  return (
    <Box
      className={`sidebar-menu ${isActive && 'sidebar-menu--active'}`}
      component={RouteLink}
      to={isOnSameRoute ? currentRoutePath : routePath || ''}
    >
      <Box
        color={isActive ? 'secondary.main' : 'primary.main'}
        component={FontAwesomeIcon}
        height={22}
        icon={Icon}
      />
      <Typography color="gray.main" textAlign="center" variant="caption">
        {menu.name}
      </Typography>
    </Box>
  );
};

export default SidebarMenu;
