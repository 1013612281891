/* eslint-disable no-param-reassign */
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import commonConstants from 'constants/common';
import { SortOrder } from 'enums/common';
import { NetworkServiceSortBy } from 'enums/networks/network-services';
import { IFilter, SortOrderType } from 'interfaces/common';
import {
  INetworkServiceAdvancedSearchForm,
  ISelectedNetwork,
  NetworkServiceSortByType,
} from 'interfaces/networks/network-services';
import { RootState } from 'stores';
import { checkIfValuesInObjectIsEmpty } from 'utils/common';

const { DEFAULT_TABLE_FILTER } = commonConstants;

const advancedSearchDefaultValues = {
  status: '',
  isAdvancedSearch: false,
};

const defaultSelectedNetwork = {
  networkId: '',
  networkCode: '',
};

const sortDefaultValues = {
  sortBy: NetworkServiceSortBy.NAME,
  sortOrder: SortOrder.ASC,
};

interface INetworkServiceSort {
  sortBy: NetworkServiceSortByType;
  sortOrder: SortOrderType;
}

interface NetworkServiceState {
  advancedSearch: INetworkServiceAdvancedSearchForm;
  filters: IFilter;
  sort: INetworkServiceSort;
  selectedNetwork: ISelectedNetwork;
}

const initialState: NetworkServiceState = {
  advancedSearch: { ...advancedSearchDefaultValues },
  filters: { ...DEFAULT_TABLE_FILTER },
  sort: { ...sortDefaultValues },
  selectedNetwork: { ...defaultSelectedNetwork },
};

export const slice = createSlice({
  name: 'network-service',
  initialState,
  reducers: {
    // Changing the state directly (with mutation) in reducer
    // Redux Toolkit uses Immer under the hood (takes care of immutablility)
    changeFilters: (state, action: PayloadAction<Partial<IFilter>>) => {
      state.filters = { ...state.filters, ...action.payload };
    },

    changeSelectedNetwork: (
      state,
      action: PayloadAction<Partial<ISelectedNetwork>>
    ) => {
      state.selectedNetwork = { ...state.selectedNetwork, ...action.payload };
    },

    resetFilters: (state) => {
      state.filters = { ...DEFAULT_TABLE_FILTER };
    },

    changeSortByAndOrder: (
      state,
      action: PayloadAction<INetworkServiceSort>
    ) => {
      state.sort = {
        sortBy: action.payload.sortBy,
        sortOrder: action.payload.sortOrder,
      };
    },

    changeAdvancedSearch: (
      state,
      action: PayloadAction<Partial<INetworkServiceAdvancedSearchForm>>
    ) => {
      const advanceFilters = { ...state.advancedSearch, ...action.payload };
      const { isAdvancedSearch, ...rest } = advanceFilters;
      state.advancedSearch = {
        ...advanceFilters,
        isAdvancedSearch: !checkIfValuesInObjectIsEmpty(rest),
      };
      state.advancedSearch = { ...state.advancedSearch, ...action.payload };
    },

    resetAdvancedSearch: (state) => {
      state.advancedSearch = { ...advancedSearchDefaultValues };
    },

    resetAllFilters: (state) => {
      state.advancedSearch = { ...advancedSearchDefaultValues };
      state.filters = { ...DEFAULT_TABLE_FILTER };
      state.sort = { ...sortDefaultValues };
      state.selectedNetwork = { ...defaultSelectedNetwork };
    },
  },
});

// Actions
export const {
  changeFilters,
  changeSelectedNetwork,
  changeAdvancedSearch,
  resetFilters,
  changeSortByAndOrder,
  resetAdvancedSearch,
  resetAllFilters,
} = slice.actions;

// Selectors
export const selectNetworkServiceAdvancedSearch = (state: RootState) =>
  state.networkService.advancedSearch;
export const selectNetworkServiceFilter = (state: RootState) =>
  state.networkService.filters;
export const selectNetworkServiceSort = (state: RootState) =>
  state.networkService.sort;
export const selectNetworkServiceSelectedNetwork = (state: RootState) =>
  state.networkService.selectedNetwork;

// Reducer
export default slice.reducer;
