/* eslint-disable no-param-reassign */
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import commonConstants from 'constants/common';
import { Signature } from 'enums/common';
import {
  IClientAgreement,
  IClientAgreementDrawSignature,
  IClientAgreementTypeSignaturePayload,
} from 'interfaces/client-agreement';
import { RootState } from 'stores';

const initialState: IClientAgreement = {
  selectedService: '',
  isCanvasSelected: false,
  isSignatureSubmitted: false,
  isServiceSelected: false,
  signatureType: '',
  type: {
    fullName: '',
    fullNameStyle: '',
    previewName: '',
    defaultName: '',
    previewFullNameStyle: '',
  },
  draw: { signature: '', dataPoint: '' },
};

export const slice = createSlice({
  name: 'client-agreement',
  initialState,
  reducers: {
    // Changing the state directly (with mutation) in reducer
    // Redux Toolkit uses Immer under the hood (takes care of immutablility)
    cleareAgreement: (state) => {
      state = {
        ...initialState,
        type: {
          ...initialState.type,
          defaultName: state.type.defaultName,
          fullNameStyle: commonConstants.DIGITAL_SIGNATURE_FONTS[0],
          previewFullNameStyle: commonConstants.DIGITAL_SIGNATURE_FONTS[0],
        },
        selectedService: state.selectedService,
        isServiceSelected: state.selectedService !== '',
      };
      return state;
    },
    cancelAgreement: (state) => {
      state = {
        ...state,
        type: {
          ...state.type,
          fullName: state.type.fullName,
          previewName: state.type.fullName,
          previewFullNameStyle: state.type.fullNameStyle,
        },
        selectedService: state.selectedService,
        isServiceSelected: state.selectedService !== '',
      };
      return state;
    },
    changeAgreementDrawSignature: (
      state,
      action: PayloadAction<IClientAgreementDrawSignature>
    ) => {
      state.isSignatureSubmitted = true;
      state.signatureType = Signature.DRAW;
      state.draw = { ...state.draw, signature: action.payload.signature };
    },
    changeAgreementDrawSignaturePointData: (
      state,
      action: PayloadAction<string>
    ) => {
      state.draw.dataPoint = action.payload;
      state.isCanvasSelected = true;
    },
    changeAgreementTypeSignature: (
      state,
      action: PayloadAction<IClientAgreementTypeSignaturePayload>
    ) => {
      state.isSignatureSubmitted = action.payload.isSignatureSubmitted;
      state.signatureType = Signature.TYPE;
      state.type = { ...state.type, ...action.payload.type };
    },
    changeAgreementService: (state, action: PayloadAction<string>) => {
      state.isServiceSelected = true;
      state.selectedService = action.payload;
    },
    changeSelectedService: (state, action: PayloadAction<string>) => {
      state.selectedService = action.payload;
      state.isServiceSelected = action.payload !== '';
    },
    changeSelectedFont: (state, action: PayloadAction<string>) => {
      state.type.previewFullNameStyle = action.payload;
    },
    changeClientName: (state, action: PayloadAction<string>) => {
      state.type.previewName = action.payload;
    },
  },
});

// Actions
export const {
  cleareAgreement,
  changeAgreementDrawSignature,
  changeAgreementDrawSignaturePointData,
  changeAgreementTypeSignature,
  changeAgreementService,
  changeSelectedService,
  changeSelectedFont,
  changeClientName,
  cancelAgreement,
} = slice.actions;

// Selectors
export const selectAgreementSignature = (state: RootState) =>
  state.clientAgreement;

// Reducer
export default slice.reducer;
