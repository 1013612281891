import { faEllipsisV } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Box, List, useTheme } from '@mui/material';
import { Popover } from 'common';

interface IProps {
  children: React.ReactNode;
  dataCy?: string;
  open?: boolean;
}

const EllipseMenu = ({ children, open, dataCy }: IProps): JSX.Element => {
  const theme = useTheme();

  return (
    <Popover
      buttonIcon={<FontAwesomeIcon icon={faEllipsisV} size="xs" />}
      buttonSx={{ width: 32, height: 32 }}
      datacy={dataCy}
      open={open}
      sx={{
        borderRadius: theme.customBorderRadius.sm,
        padding: 1,
        minWidth: 180,
      }}
    >
      <Box>
        <List className="ellipse-menu-list" data-cy="popover-container">
          {children}
        </List>
      </Box>
    </Popover>
  );
};

EllipseMenu.defaultProps = {
  open: false,
  dataCy: '',
};

export default EllipseMenu;
