import commonConstants from 'constants/common';
import { ILocationDetail } from 'interfaces/common';

const { DEFAULT_LOCATION } = commonConstants;

export const getLocationDetails = (
  location: GeolocationPosition
): Promise<ILocationDetail> =>
  new Promise((resolve) => {
    const loc: ILocationDetail = {
      lat: '',
      lng: '',
      city: '',
      state: '',
      zip: '',
      displayName: '',
    };

    if (Math.round(location.coords.longitude) === 85) {
      loc.lat = DEFAULT_LOCATION.lat;
      loc.lng = DEFAULT_LOCATION.lng;
      loc.city = DEFAULT_LOCATION.city;
      loc.state = DEFAULT_LOCATION.state;
      loc.zip = DEFAULT_LOCATION.zip;
      loc.displayName = DEFAULT_LOCATION.displayName;

      resolve(loc);
    } else {
      loc.lat = location.coords.latitude;
      loc.lng = location.coords.longitude;

      const geocoder = new (window as any).google.maps.Geocoder({
        fields: [
          'name',
          'address_components',
          'formatted_address',
          'geometry.location',
        ],
      });

      const latlng = new (window as any).google.maps.LatLng(loc.lat, loc.lng);

      geocoder.geocode({ location: latlng }, (results: any, status: string) => {
        if (status === 'OK') {
          results.forEach((result: any) => {
            if (!!result && result !== 'null') {
              result.address_components.forEach((x: any) => {
                if (x.types[0] === 'locality') {
                  loc.city = `${x.long_name}`;
                }
                if (x.types[0] === 'administrative_area_level_1') {
                  loc.state = `${x.short_name}`;
                }
                if (x.types[0] === 'postal_code') {
                  loc.zip = `${x.long_name}`;
                }
              });
            }
          });

          loc.displayName = loc?.city ? `${loc.city}, ${loc.state}` : '';
          resolve(loc);
        }
      });
    }
  });
