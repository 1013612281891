import { useCheckUserIsClient } from './useCheckUserIsClient';
import { useCheckUserIsClientGroup } from './useCheckUserIsClientGroup';

/**
 * Checks whether the logged in user is allowed to modify membership (Approve/Cancel/Change/Complete/Decline).
 * Modify Membership doesn't include Add Membership here.
 *
 * Checks are from the user role level only.
 *
 * Only the group admin have the permission to modify membership on client login.
 *
 */
export const useCheckPermissionToModifyMembership = () => {
  const isUserClient = useCheckUserIsClient();
  const isUserGroupAdmin = useCheckUserIsClientGroup();

  if (!isUserClient) {
    return true;
  }

  return !!isUserGroupAdmin;
};
