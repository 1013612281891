import { useNavigate, useSearchParams } from 'react-router-dom';

import { DialogContent, Typography } from '@mui/material';
import { Modal, ModalFooter } from 'common';
import { useSendClientAgreementMutation } from 'services/client-agreement';

const SendAgreementModal = () => {
  const navigate = useNavigate();
  const handleClose = () => navigate(-1);
  const clientAgreementMutation = useSendClientAgreementMutation();

  const [searchParams] = useSearchParams();
  const tenantId = searchParams.get('tenantId');
  const clientId = searchParams.get('clientId');
  const clientName = searchParams.get('clientName');
  const clientEnrollmentIdFromParams = searchParams.get('clientEnrollmentId');

  const onSubmitClick = () => {
    const body = {
      tenantId: tenantId ?? '',
      clientId: clientId ?? '',
      clientEnrollmentId: clientEnrollmentIdFromParams ?? '',
    };
    clientAgreementMutation.mutate(
      { data: body },
      {
        onSuccess: () => {
          handleClose();
        },
      }
    );
  };

  return (
    <Modal handleClose={handleClose} maxWidth="md" open title="Send Agreement">
      <DialogContent sx={{ marginTop: 4, marginBottom: 2 }}>
        <Typography>
          You are sending a membership agreement to{' '}
          <Typography
            component="span"
            display="inline-block"
            fontWeight={(theme) => theme.typography.fontWeightMedium}
            gutterBottom={false}
          >
            {clientName}
          </Typography>
          .
        </Typography>
      </DialogContent>
      <ModalFooter
        actionButtonLabel="Submit"
        actionButtonType="button"
        isCancelRequired={false}
        isLoading={clientAgreementMutation.isLoading}
        onActionButtonClick={onSubmitClick}
        onCancelButtonClick={handleClose}
      />
    </Modal>
  );
};

export default SendAgreementModal;
