import {
  faSort,
  faSortDown,
  faSortUp,
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Box, useTheme } from '@mui/material';
import { SortOrder } from 'enums/common';

interface IProps {
  type?: SortOrder.ASC | SortOrder.DESC | '';
  dataCy?: string;
}

const SortIcon = ({ type, dataCy }: IProps): JSX.Element => {
  const theme = useTheme();

  const color =
    type === SortOrder.ASC || type === SortOrder.DESC
      ? theme.palette.text.primary
      : theme.palette.gray.main;

  const getIcon = () => {
    if (type === SortOrder.ASC) {
      return faSortUp;
    }
    if (type === SortOrder.DESC) {
      return faSortDown;
    }
    return faSort;
  };

  return (
    <Box
      component={FontAwesomeIcon}
      data-cy={dataCy}
      icon={getIcon()}
      ml={1}
      size="sm"
      sx={{
        color,
      }}
    />
  );
};

SortIcon.defaultProps = {
  type: '',
  dataCy: '',
};

export default SortIcon;
