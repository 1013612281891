import BankInformationDisplay from '../BankInformationDisplay';

interface IProps {
  title?: string;
  subtitle?: string;
  bankToken: string;
  onEditClick?: VoidFunction;
}

const FilledBankDetail = ({
  title,
  subtitle,
  bankToken,
  onEditClick,
}: IProps) => {
  const getFormattedAccountNumber = (token: string) =>
    `************${token.slice(-4)}`;

  return (
    <BankInformationDisplay
      accountNumber={getFormattedAccountNumber(bankToken)}
      onEditClick={onEditClick}
      subtitle={subtitle}
      title={title}
    />
  );
};

FilledBankDetail.defaultProps = {
  onEditClick: null,
  title: '',
  subtitle: 'Use recently entered bank information',
};

export default FilledBankDetail;
