/* eslint-disable no-param-reassign */
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import commonConstants from 'constants/common';
import { Interval } from 'enums/common';
import { MembershipStatus } from 'enums/settings/tenant-offerings';
import { IFilter } from 'interfaces/common';
import { IClientsReportAdvancedSearchForm } from 'interfaces/reports/clients-report';
import { RootState } from 'stores';
import { getDateRange } from 'utils/moment';

const { DEFAULT_TABLE_FILTER } = commonConstants;

interface ClientsReportState {
  filters: IFilter;
  advancedSearch: IClientsReportAdvancedSearchForm
}

const advancedSearchDefaultValues: IClientsReportAdvancedSearchForm = {
  type: commonConstants.TYPE.ALL,
  groups: [],
  membershipStatus: MembershipStatus.ACTIVE,
  startDate: getDateRange(Interval.WEEKLY).startDate,
  endDate: getDateRange(Interval.WEEKLY).endDate,
  interval: Interval.WEEKLY,
  newMembership: true,
  firstTimeMembership: false,
};

const initialState: ClientsReportState = {
  filters: { ...DEFAULT_TABLE_FILTER },
  advancedSearch: { ...advancedSearchDefaultValues }
};

export const slice = createSlice({
  name: 'clientsReport',
  initialState,
  reducers: {
    // Changing the state directly (with mutation) in reducer
    // Redux Toolkit uses Immer under the hood (takes care of immutablility)
    changeFilters: (state, action: PayloadAction<Partial<IFilter>>) => {
      state.filters = { ...state.filters, ...action.payload };
    },

    resetFilters: (state) => {
      state.filters = { ...DEFAULT_TABLE_FILTER };
    },
    changeAdvancedSearch: (
      state,
      action: PayloadAction<Partial<IClientsReportAdvancedSearchForm>>
    ) => {
      state.advancedSearch = { ...state.advancedSearch, ...action.payload };
    },

    resetAdvancedSearch: (state) => {
      state.advancedSearch = { ...advancedSearchDefaultValues };
    },

    resetAllFilters: (state) => {
      state.advancedSearch = { ...advancedSearchDefaultValues };
      state.filters = {

        ...DEFAULT_TABLE_FILTER,
      };
    },
  },
});

// Actions
export const { changeFilters, changeAdvancedSearch, resetAdvancedSearch, resetAllFilters, resetFilters } = slice.actions;

// Selectors
export const selectClientsReportFilter = (state: RootState) =>
  state.clientsReport.filters;
export const selectClientsReportAdvancedSearch = (state: RootState) =>
  state.clientsReport.advancedSearch;
export const selectClientsReport = (state: RootState) =>
  state.clientsReport;

// Reducer
export default slice.reducer;
