import CardInformationDisplay from '../CardInformationDisplay';

interface IProps {
  title?: string;
  subtitle?: string;
  cardNumber: string;
  cardExpiry: string;
  onEditClick?: VoidFunction;
}

const FilledCardDetail = ({
  title,
  subtitle,
  cardNumber,
  cardExpiry,
  onEditClick,
}: IProps) => {
  const getFormattedCardNumber = (number: string) => number;

  const getFormattedExpiryDate = (expiry: string) => expiry;

  return (
    <CardInformationDisplay
      cardExpiry={getFormattedExpiryDate(cardExpiry)}
      cardNumber={getFormattedCardNumber(cardNumber)}
      onEditClick={onEditClick}
      subtitle={subtitle}
      title={title}
    />
  );
};

FilledCardDetail.defaultProps = {
  onEditClick: null,
  title: '',
  subtitle: 'Use recently entered card information',
};

export default FilledCardDetail;
