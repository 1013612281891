import { useState } from 'react';

import { faCheckCircle } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Box, Paper, Typography, useTheme } from '@mui/material';

const FileList = ({ info, handleSelectedFile }: any) => {
  const theme = useTheme();
  const [checked, setChecked] = useState(false);
  return (
    <Paper
      onClick={() => {
        setChecked(!checked);
        handleSelectedFile(info, !checked);
      }}
      sx={{
        border: (t) => `1px solid ${t.palette.gray.lighter}`,
        marginY: 1,
        padding: 1,
        cursor: 'pointer',
        display: 'flex',
        boxShadow: 'none',
      }}
    >
      <Box
        alignItems="center"
        data-cy="case-files"
        display="flex"
        justifyContent="flex-start"
        width="30px"
      >
        {info.isSelected && (
          <FontAwesomeIcon
            data-cy="case-files-selected"
            icon={faCheckCircle}
            size="lg"
            style={{ color: theme.palette.primary.main }}
          />
        )}
      </Box>

      <Typography gutterBottom={false}>{info.name}</Typography>
    </Paper>
  );
};

export default FileList;
