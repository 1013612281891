export enum AppointmentStatus {
  NEW = 'New',
  CHECKEDIN = 'CheckedIn',
  SCHEDULED = 'Scheduled',
  CHECKEDOUT = 'CheckedOut',
  DELETED = 'Deleted',
}

export enum AppointmentSortBy {
  PATIENT_NAME = 'patient_name',
  EMAIL = 'email',
  APPOINTMENT_DATE = 'appointmentDate',
  REQUESTED_DATE = 'requestedDate',
}
