/* eslint-disable no-param-reassign */
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import commonConstants from 'constants/common';
import { SortOrder } from 'enums/common';
import { ActivityLogSortByType, IActivityLogAdvanceSearchForm } from 'interfaces/activity-log';
import { IFilter, SortOrderType } from 'interfaces/common';
import { RootState } from 'stores';
import { checkIfValuesInObjectIsEmpty } from 'utils/common';

import { ActivityLogSortBy } from "../../enums/activity-log/index";

const { DEFAULT_TABLE_FILTER } = commonConstants;

const advancedSearchDefaultValues = {
  interval: '',
  isAdvancedSearch: false,
};

interface IActivityLogSort {
  sortBy: ActivityLogSortByType | '';
  sortOrder: SortOrderType | '';
}

interface IState {
  filters: IFilter;
  advancedSearch: IActivityLogAdvanceSearchForm;
  sort: IActivityLogSort;
}

const sortDefaultValues = {
  sortBy: ActivityLogSortBy.TIMESTAMP,
  sortOrder: SortOrder.DESC,
};
const initialState: IState = {
  filters: { ...DEFAULT_TABLE_FILTER },
  advancedSearch: { ...advancedSearchDefaultValues },
  sort: { ...sortDefaultValues },
};

export const slice = createSlice({
  name: 'activity-log',
  initialState,
  reducers: {
    // Changing the state directly (with mutation) in reducer
    // Redux Toolkit uses Immer under the hood (takes care of immutablility)
    changeFilters: (state, action: PayloadAction<Partial<IFilter>>) => {
      state.filters = { ...state.filters, ...action.payload };
    },

    resetFilters: (state) => {
      state.filters = { ...DEFAULT_TABLE_FILTER };
    },

    resetAllFilters: (state) => {
      state.advancedSearch = { ...advancedSearchDefaultValues };
      state.filters = {
        ...state.filters,
        ...DEFAULT_TABLE_FILTER,
      };
    },

    changeAdvancedSearch: (
      state,
      action: PayloadAction<Partial<IActivityLogAdvanceSearchForm>>
    ) => {
      const advanceFilters = { ...state.advancedSearch, ...action.payload };
      const { isAdvancedSearch, ...rest } = advanceFilters;
      state.advancedSearch = {
        ...advanceFilters,
        isAdvancedSearch: !checkIfValuesInObjectIsEmpty(rest),
      };
      state.advancedSearch = { ...state.advancedSearch, ...action.payload };
    },
    changeSortByAndOrder: (state, action: PayloadAction<IActivityLogSort>) => {
      state.sort = {
        sortBy: action.payload.sortBy,
        sortOrder: action.payload.sortOrder,
      };
    },
    resetAdvancedSearch: (state) => {
      state.advancedSearch = { ...advancedSearchDefaultValues };
    },
  },
});

// Actions
export const {
  changeFilters,
  changeAdvancedSearch,
  changeSortByAndOrder,
  resetFilters,
  resetAllFilters,
  resetAdvancedSearch,
} = slice.actions;

// Selectors
export const selectActivityLogFilter = (state: RootState) =>
  state.activityLog.filters;
export const selectActivityLogAdvancedSearch = (state: RootState) =>
  state.activityLog.advancedSearch;
export const selectActivityLog = (state: RootState) => state.activityLog;
export const selectActivityLogSort = (state: RootState) =>
  state.activityLog.sort;

// Reducer
export default slice.reducer;
