import React from 'react';
import NumberFormat from 'react-number-format';

import { TextField, TextFieldProps } from '@mui/material';

interface ICustomProps {
  onChange: (event: any) => void;
  name: string;
}

const CustomNumberFormat = React.forwardRef<NumberFormat<any>, ICustomProps>(
  (props, ref) => {
    const { onChange, ...other } = props;

    return (
      <NumberFormat
        {...other}
        format="##/##"
        getInputRef={ref}
        mask={['M', 'M', 'Y', 'Y']}
        onValueChange={onChange}
      />
    );
  }
);

const MaskedCardExpiryInput = ({ ...typeFieldProps }: TextFieldProps) => (
  <TextField
    className="filled-variant"
    fullWidth
    InputProps={{
      inputComponent: CustomNumberFormat as any,
    }}
    placeholder="MM/YY"
    size="small"
    {...typeFieldProps}
  />
);

export default MaskedCardExpiryInput;
