export enum PaymentEnvironment {
  TEST = 'test',
  LIVE = 'live',
}

export enum AuthorizeDotNetPaymentEnvironment {
  TEST = 'SANDBOX',
  LIVE = 'PRODUCTION',
}

export enum PaymentMethod {
  CARD = 'Card',
  BANK = 'Bank',
  CASH = 'Cash',
  CHECK = 'Check',
  CARD_ON_FILE = 'Card on File',
  SPONSOR_ACCOUNT = 'Sponsor on AC',
  OTHERS = 'Other',
}

export enum ClientCategory {
  OTHERS = 'Others',
}

export enum PaymentSourceStatus {
  PRIMARY = 'Primary',
  SECONDARY = 'Secondary',
}

export enum GatewayType {
  AUTHORIZE_DOT_NET = 'Authorize.Net',
  // TODO: FirstData and CardConnect are the same. Refactor to have only one type
  CARD_CONNECT = 'CardConnect',
  FIRST_DATA = 'FirstData',
  STRIPE = 'Stripe',
}

export enum AuthorizeDotNetEnvironment {
  TEST = 'SANDBOX',
  LIVE = 'PRODUCTION',
}

export const PAYMENT_GATEWAY_LINKS = {
  AUTHORIZE_DOT_NET: 'https://www.authorize.net/',
  CARD_CONNECT: 'https://www.firstdata.com/',
};

export enum AuthorizeDotNetBankAccountType {
  CHECKING = 'checking',
  SAVINGS = 'savings',
  BUSINESS_CHECKING = 'businessChecking',
}
