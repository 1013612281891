import { IconProp } from '@fortawesome/fontawesome-svg-core';
import { faEdit, IconDefinition } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  Box,
  IconButton,
  Stack,
  Tooltip,
  Typography,
  useTheme,
} from '@mui/material';
import { ReferralCaseStatus } from 'enums/case';
import { IRequestedFrom, IRequestedTo } from 'interfaces/cases';

interface ICustomIconButtonProps {
  onClick: VoidFunction;
  icon?: IconProp;
  iconColor?: string;
  label?: string | JSX.Element;
  size?: 'small' | 'medium' | 'large';
  datacy?: string;
}

interface IStackIconAndTypographyProps {
  label: string | JSX.Element | null;
  icon: IconDefinition;
  dataCy?: string;
}

interface IStackIconAndTypographySeparateHover {
  label: string | JSX.Element | null;
  icon: IconDefinition;
  iconLabel: string;
  originalLabel: string;
  datacy?: string;
}

export const referralCaseStatusData = [
  {
    value: ReferralCaseStatus.REQUESTED.toUpperCase(),
    label: ReferralCaseStatus.REQUESTED,
  },
  {
    value: ReferralCaseStatus.ACKNOWLEDGED.toUpperCase(),
    label: ReferralCaseStatus.ACKNOWLEDGED,
  },
  {
    value: ReferralCaseStatus.INPROGRESS.toUpperCase(),
    label: ReferralCaseStatus.INPROGRESS,
  },
  {
    value: ReferralCaseStatus.COMPLETED.toUpperCase(),
    label: ReferralCaseStatus.COMPLETED,
  },
  {
    value: ReferralCaseStatus.CLOSED.toUpperCase(),
    label: ReferralCaseStatus.CLOSED,
  },
  {
    value: ReferralCaseStatus.DELETED.toUpperCase(),
    label: ReferralCaseStatus.DELETED,
  },
];

export const mapOldAddressImplementation = (
  address:
    | {
        line1: string;
        line2: string;
        city: string;
        state: string;
        zip: string;
      }
    | undefined,
  data?: any
) => {
  if (address)
    return {
      addressLine1: address.line1,
      addressLine2: address.line2,
      city: address.city,
      state: address.state,
      zip: address.zip,
    };
  if (data)
    return {
      addressLine1: data.addressLine1,
      addressLine2: data.addressLine2,
      city: data.city,
      state: data.state,
      zip: data.zip,
    };
  return {
    addressLine1: 'N/A',
    addressLine2: 'N/A',
    city: 'N/A',
    state: 'N/A',
    zip: 'N/A',
  };
};

export const StackIconAndTypography = ({
  label,
  icon,
  dataCy,
}: IStackIconAndTypographyProps) => {
  const theme = useTheme();
  return (
    <Stack direction="row" spacing={1} sx={{ alignItems: 'flex-start' }}>
      <Box
        display="flex"
        height="14px"
        justifyContent="center"
        mt="3.5px"
        width="14px"
      >
        <FontAwesomeIcon
          icon={icon}
          size="sm"
          style={{ color: theme.palette.gray.main }}
        />
      </Box>
      <Typography
        className="text-overflow-ellipses"
        data-cy={dataCy}
        gutterBottom={false}
        variant="body2"
      >
        {label}
      </Typography>
    </Stack>
  );
};

StackIconAndTypography.defaultProps = {
  dataCy: '',
};

export const StackIconAndTypographySeparateHover = ({
  icon,
  iconLabel,
  label,
  originalLabel,
  datacy,
}: IStackIconAndTypographySeparateHover) => {
  const theme = useTheme();
  return (
    <Stack direction="row" spacing={1} sx={{ alignItems: 'flex-start' }}>
      <Tooltip title={iconLabel}>
        <Box
          display="flex"
          height="14px"
          justifyContent="center"
          mt="3.5px"
          width="14px"
        >
          <FontAwesomeIcon
            icon={icon}
            size="sm"
            style={{ color: theme.palette.gray.main }}
          />
        </Box>
      </Tooltip>
      <Tooltip title={originalLabel || ''}>
        <Typography
          className="text-overflow-ellipses"
          data-cy={datacy}
          gutterBottom={false}
          variant="body2"
        >
          {label}
        </Typography>
      </Tooltip>
    </Stack>
  );
};
StackIconAndTypographySeparateHover.defaultProps = {
  datacy: '',
};

export const CustomIconButton = ({
  onClick,
  icon,
  label,
  size = 'medium',
  iconColor,
  datacy,
}: ICustomIconButtonProps) => {
  const theme = useTheme();
  return (
    <IconButton aria-label="add" data-cy={datacy} onClick={onClick} size={size}>
      <FontAwesomeIcon
        icon={icon ?? faEdit}
        style={{
          color: iconColor ?? theme.palette.gray.main,
        }}
      />
      {label}
    </IconButton>
  );
};
CustomIconButton.defaultProps = {
  icon: undefined,
  label: undefined,
  iconColor: undefined,
  size: 'medium',
  datacy: '',
};

export const getRequestedPhoneNumber = (d: IRequestedTo | IRequestedFrom) => {
  if (d.assignedTo) {
    if (!d.assignedTo.phone) return d.phone;
    return d.assignedTo.phone;
  }
  if (d.phone) return d.phone;
  return '';
};
export const getRequestedEmail = (d: IRequestedTo | IRequestedFrom) => {
  if (d.assignedTo) {
    if (!d.assignedTo.email) return d.email;
    return d.assignedTo.email;
  }
  if (d.email) return d.email;
  return 'N/A';
};
