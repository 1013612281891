import { useQuery } from 'react-query';

import * as intervalAPI from 'api/interval';

export const intervalKeys = {
  all: ['interval'] as const,
  lists: () => [...intervalKeys.all, 'list'] as const,
  list: () => [...intervalKeys.lists()] as const,
};

export const useIntervalQuery = () => {
  const queryInfo = useQuery(intervalKeys.list(), () =>
    intervalAPI.getIntervals()
  );

  return {
    ...queryInfo,
    data: queryInfo.data?.data,
  };
};
