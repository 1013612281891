/* eslint-disable no-param-reassign */
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import commonConstants from 'constants/common';
import { Interval, SortOrder } from 'enums/common';
import { AdvocateSortBy } from 'enums/timesheet/advocate';
import { IFilter, SortOrderType } from 'interfaces/common';
import {
  AdvocateSortByType,
  ITimesheetByAdvocateAdvancedSearchForm,
} from 'interfaces/timesheet/advocate';
import { RootState } from 'stores';

const { DEFAULT_TABLE_FILTER } = commonConstants;

const advancedSearchDefaultValues = {
  interval: Interval.WEEKLY,
  advocate: '',
  tenantId: '',
};

interface IAdvocateSort {
  sortBy: AdvocateSortByType;
  sortOrder: SortOrderType;
}

interface AdvocateState {
  filters: IFilter;
  advancedSearch: ITimesheetByAdvocateAdvancedSearchForm;
  sort: IAdvocateSort;
}

const initialState: AdvocateState = {
  filters: { ...DEFAULT_TABLE_FILTER },
  advancedSearch: { ...advancedSearchDefaultValues },
  sort: {
    sortBy: AdvocateSortBy.ADVOCATE,
    sortOrder: SortOrder.ASC,
  },
};

export const slice = createSlice({
  name: 'timesheetByAdvocate',
  initialState,
  reducers: {
    // Changing the state directly (with mutation) in reducer
    // Redux Toolkit uses Immer under the hood (takes care of immutablility)
    changeFilters: (state, action: PayloadAction<Partial<IFilter>>) => {
      state.filters = { ...state.filters, ...action.payload };
    },

    resetFilters: (state) => {
      state.filters = { ...DEFAULT_TABLE_FILTER };
    },

    changeAdvancedSearch: (
      state,
      action: PayloadAction<Partial<ITimesheetByAdvocateAdvancedSearchForm>>
    ) => {
      state.advancedSearch = { ...state.advancedSearch, ...action.payload };
    },

    resetAdvancedSearch: (state) => {
      state.advancedSearch = { ...advancedSearchDefaultValues };
    },

    changeSortByAndOrder: (state, action: PayloadAction<IAdvocateSort>) => {
      state.sort = {
        sortBy: action.payload.sortBy,
        sortOrder: action.payload.sortOrder,
      };
    },

    resetAllFilters: (state) => {
      state.advancedSearch = { ...advancedSearchDefaultValues };
      state.filters = {
        ...state.filters,
        ...DEFAULT_TABLE_FILTER,
      };
      state.sort = initialState.sort;
    },
  },
});

// Actions
export const {
  changeFilters,
  changeAdvancedSearch,
  resetFilters,
  resetAllFilters,
  resetAdvancedSearch,
  changeSortByAndOrder,
} = slice.actions;

// Selectors
export const selectTimesheetByAdvocateFilter = (state: RootState) =>
  state.timesheetByAdvocate.filters;
export const selectTimesheetByAdvocateSort = (state: RootState) =>
  state.timesheetByAdvocate.sort;

export const selectTimesheetByAdvocateAdvancedSearch = (state: RootState) =>
  state.timesheetByAdvocate.advancedSearch;
export const selectTimesheetByAdvocate = (state: RootState) =>
  state.timesheetByAdvocate;

// Reducer
export default slice.reducer;
