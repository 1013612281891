import { IResponse } from 'interfaces/http';
import { IAdaptedLocation, ILocation } from 'interfaces/location';

export const adaptLocationList = (
  res: IResponse<ILocation[]>
): IResponse<IAdaptedLocation[]> => ({
  ...res,
  data: res.data.map((e) => ({
    ...e,
    name: `${e.city}, ${e.st}`,
  })),
});
