/* eslint-disable no-param-reassign */
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import commonConstants from 'constants/common';
import { SortOrder } from 'enums/common';
import { TestReportSortBy } from 'enums/test-report';
import { IClient } from 'interfaces/client-management';
import { ITestReport } from 'interfaces/client-management/test-report';
import { IFilter } from 'interfaces/common';
import { ITestReportAdvancedSearchForm } from 'interfaces/test-report/index';
import { RootState } from 'stores';
import { checkIfValuesInObjectIsEmpty } from 'utils/common';

const { DEFAULT_TABLE_FILTER } = commonConstants;

const advancedSearchDefaultValues = {
  status: '',
  startDate: '',
  endDate: '',
  isAdvancedSearch: false,
};

interface IState {
  filters: IFilter;
  advancedSearch: ITestReportAdvancedSearchForm;
  sort: any;
  sendOptions: {
    step: number;
    userDetails: IClient | null;
    previousData: ITestReport[];
  };
}

const sortDefaultValues = {
  sortBy: TestReportSortBy.DATE,
  sortOrder: SortOrder.DESC,
};
const initialState: IState = {
  filters: { ...DEFAULT_TABLE_FILTER },
  advancedSearch: { ...advancedSearchDefaultValues },
  sort: { ...sortDefaultValues },
  sendOptions: {
    step: 0,
    userDetails: null,
    previousData: [],
  },
};

export const slice = createSlice({
  name: 'testReport',
  initialState,
  reducers: {
    // Changing the state directly (with mutation) in reducer
    // Redux Toolkit uses Immer under the hood (takes care of immutablility)
    changeFilters: (state, action: PayloadAction<Partial<IFilter>>) => {
      state.filters = { ...state.filters, ...action.payload };
    },

    resetFilters: (state) => {
      state.filters = { ...DEFAULT_TABLE_FILTER };
    },

    resetAllFilters: (state) => {
      state.advancedSearch = { ...advancedSearchDefaultValues };
      state.filters = {
        ...state.filters,
        ...DEFAULT_TABLE_FILTER,
      };
    },

    changeAdvancedSearch: (
      state,
      action: PayloadAction<Partial<ITestReportAdvancedSearchForm>>
    ) => {
      const advanceFilters = { ...state.advancedSearch, ...action.payload };
      const { isAdvancedSearch, ...rest } = advanceFilters;
      state.advancedSearch = {
        ...advanceFilters,
        isAdvancedSearch: !checkIfValuesInObjectIsEmpty(rest),
      };
      state.advancedSearch = { ...state.advancedSearch, ...action.payload };
    },
    changeSortByAndOrder: (state, action: PayloadAction<any>) => {
      state.sort = {
        sortBy: action.payload.sortBy,
        sortOrder: action.payload.sortOrder,
      };
    },
    resetAdvancedSearch: (state) => {
      state.advancedSearch = { ...advancedSearchDefaultValues };
    },
    changeSendMedicalReport: (state, action: PayloadAction<any>) => {
      state.sendOptions = {
        ...state.sendOptions,
        ...action.payload,
      };
    },
  },
});

// Actions
export const {
  changeFilters,
  changeAdvancedSearch,
  changeSortByAndOrder,
  resetFilters,
  resetAllFilters,
  resetAdvancedSearch,
  changeSendMedicalReport,
} = slice.actions;

// Selectors
export const selectTestReportFilter = (state: RootState) =>
  state.testReport.filters;
export const selectTestReportAdvancedSearch = (state: RootState) =>
  state.testReport.advancedSearch;
export const selectTestReport = (state: RootState) => state.testReport;
export const selectTestReportSort = (state: RootState) => state.testReport.sort;
export const selectTestReportSendOptions = (state: RootState) =>
  state.testReport.sendOptions;

// Reducer
export default slice.reducer;
