import { Box, Button } from '@mui/material';

interface Props {
  children?: JSX.Element;
  handleClear?: VoidFunction;
  clearText?: string;
  submitText?: string;
}

const AdvancedFilterLayout = ({
  children,
  handleClear,
  clearText,
  submitText,
}: Props) => (
  <Box>
    {children}
    <Box display="flex" justifyContent="space-between" mt={2}>
      <Button
        data-cy="advance-filter-clear"
        onClick={handleClear}
        size="small"
        variant="outlined"
      >
        {clearText}
      </Button>
      <Button
        data-cy="advance-filter-apply"
        size="small"
        type="submit"
        variant="contained"
      >
        {submitText}
      </Button>
    </Box>
  </Box>
);

AdvancedFilterLayout.defaultProps = {
  children: undefined,
  handleClear: undefined,
  clearText: 'Clear',
  submitText: 'Submit',
};

export default AdvancedFilterLayout;
