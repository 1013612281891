import { Typography } from '@mui/material';
import { GatewayType } from 'enums/Payment';
import { useAppSelector } from 'stores/hooks';
import { selectGatewayDetail } from 'stores/Payment';

import AuthorizeDotNetBankPayment from './authorize-dot-net/BankPayment';
import CardConnectBankPayment from './card-connect/BankPayment';

const BankPayment = () => {
  const gatewayDetail = useAppSelector(selectGatewayDetail);

  if (gatewayDetail?.gatewayType === GatewayType.AUTHORIZE_DOT_NET) {
    return <AuthorizeDotNetBankPayment />;
  }

  if (gatewayDetail?.gatewayType === GatewayType.CARD_CONNECT) {
    return <CardConnectBankPayment />;
  }

  return (
    <Typography mt={4} variant="body1">
      Payment Gateway not found.
    </Typography>
  );
};

export default BankPayment;
