import { Box } from '@mui/material';
import UploadFromURI from 'common/documents/UploadFromURI';
import { DocumentSubType } from 'enums/documents';
import {
  IAdaptedClientGroup,
  IAdaptedClientIndividual,
} from 'interfaces/client-management';
import { selectEditClientData } from 'stores/client-management';
import { useAppSelector } from 'stores/hooks';

interface Props {
  setStep: React.Dispatch<React.SetStateAction<number>>;
}
const FromURI = ({ setStep }: Props) => {
  const editClientData = useAppSelector(
    selectEditClientData
  ) as IAdaptedClientGroup & IAdaptedClientIndividual;

  const handleClose = () => {
    setStep(0);
  };

  return (
    <Box>
      <UploadFromURI
        associationId={editClientData.clientId}
        handleClose={handleClose}
        subType={DocumentSubType.Client}
      />
    </Box>
  );
};

export default FromURI;
