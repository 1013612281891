import { Outlet } from 'react-router-dom';

import { Box } from '@mui/material';

const ReceiptLayout = () => (
  <Box
    alignItems="flex-start"
    display="flex"
    justifyContent="center"
    minHeight="100vh"
    sx={{ backgroundColor: (theme) => theme.palette.gray.light }}
  >
    <Box width="740px">
      <Outlet />
    </Box>
  </Box>
);

export default ReceiptLayout;
