import { ReferenceSubType, ReferenceType } from 'enums/common';
import { selectAuthTenantData } from 'stores/auth';
import { useAppSelector } from 'stores/hooks';

/**
 * Checks if the logged in user is tenant admin
 *
 */
export const useCheckUserIsTenantAdmin = () => {
  const tenantData = useAppSelector(selectAuthTenantData);
  const referenceType = tenantData?.tenantAssociation?.referenceType;
  const referenceSubType = tenantData?.tenantAssociation?.referenceSubType;

  const isTenantAdmin =
    referenceType === ReferenceType.TENANT &&
    referenceSubType === ReferenceSubType.TENANT;

  return isTenantAdmin;
};
