import apiRoutes from 'constants/apiRoutes';
import { IListResponse, IResponse } from 'interfaces/http';
import {
  IAddPaymentSourceRes,
  IAddPaymentSourceSchema,
  IMerchantProfile,
  IPaymentSource,
  IPaymentSourceFilter,
  IRemovePaymentSourceResponse,
  ISetPrimaryPaymentSourceSchema,
} from 'interfaces/payment';
import { baseRequest } from 'utils/axios';

const { payment } = apiRoutes;

export const addPaymentSource = async (
  clientId: string,
  data: IAddPaymentSourceSchema
): Promise<IResponse<IAddPaymentSourceRes>> => {
  const { response, error } = await baseRequest({
    method: 'POST',
    url: payment.addPaymentSource.replace(':clientId', clientId),
    data,
  });

  if (error) {
    throw new Error(error.message);
  }

  return response?.data;
};

export const getPaymentSources = async (
  params: IPaymentSourceFilter
): Promise<IResponse<IListResponse<IPaymentSource>>> => {
  const { response, error } = await baseRequest({
    method: 'GET',
    url: payment.getPaymentSources,
    params,
  });

  if (error) {
    throw new Error(error.message);
  }

  return response?.data;
};

export const removePaymentSource = async (
  merchantCustomerId: string,
  paymentSourceId: string
): Promise<IResponse<IRemovePaymentSourceResponse>> => {
  const { response, error } = await baseRequest({
    method: 'DELETE',
    url: payment.deletePaymentSource
      .replace(':merchantCustomerId', merchantCustomerId)
      .replace(':paymentSourceId', paymentSourceId),
  });

  if (error) {
    throw new Error(error.message);
  }

  return response?.data;
};

export const setPrimaryPaymentSource = async (
  merchantCustomerId: string,
  paymentSourceId: string,
  data: ISetPrimaryPaymentSourceSchema
): Promise<IResponse<IAddPaymentSourceRes>> => {
  const { response, error } = await baseRequest({
    method: 'PATCH',
    url: payment.setPrimaryPaymentSource
      .replace(':merchantCustomerId', merchantCustomerId)
      .replace(':paymentSourceId', paymentSourceId),
    data,
  });

  if (error) {
    throw new Error(error.message);
  }

  return response?.data;
};

export const getMerchantProfile = async (
  tenantId: string
): Promise<IResponse<IMerchantProfile>> => {
  const { response, error } = await baseRequest({
    method: 'GET',
    url: payment.fetchMerchantProfile.replace(':tenantId', tenantId),
  });

  if (error) {
    throw new Error(error.message);
  }

  return response?.data;
};
