/* eslint-disable no-param-reassign */
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import commonConstants from 'constants/common';
import {
  IVirtualVisitAdvancedSearchForm,
  IVirtualVisitFilter,
} from 'interfaces/virtual-visit';
import { IVirtualVisitState } from 'interfaces/virtual-visit/comet-chat';
import { RootState } from 'stores';
import {
  checkIfValuesInObjectIsEmpty,
  removeEmptyValuesFromObject,
} from 'utils/common';

const { DEFAULT_TABLE_FILTER } = commonConstants;

const advancedSearchDefaultValues = {
  type: '',
  title: '',
  createdDate: '',
};

const initialState: IVirtualVisitState = {
  isLoading: true,
  uploadFiles: [],
  users: {},
  messages: [],
  filters: {
    ...DEFAULT_TABLE_FILTER,
    tenantGroupCode: '',
  },
  advancedSearch: {
    ...advancedSearchDefaultValues,
  },
  sort: {
    sortBy: null,
    sortOrder: null,
  },
};

export const slice = createSlice({
  name: 'virtualVisit',
  initialState,
  reducers: {
    setIsLoading: (state, action) => ({
      ...state,
      isLoading: action.payload.isLoading || false,
    }),
    setUser: (state, action) => ({
      ...state,
      user: action.payload.user,
      messages: action.payload.messages,
    }),
    setMessage: (state, action) => ({
      ...state,
      messages: action.payload.messages,
      isLoading: action.payload.isLoading ?? true,
    }),
    appendMessage: (state, action) => ({
      ...state,
      messages: [...state.messages, action.payload],
    }),

    setFileUpload: (state, action) => {
      state.uploadFiles = [...state.uploadFiles, action.payload];
    },

    setFileUploadComplete: (state, action) => {
      const files = state.uploadFiles;
      const modifyFileUpload = files
        .map((item) => {
          if (item.id === action.payload.id) {
            return {
              ...item,
              isUploading: false,
            };
          }
          return item;
        })
        .filter((item) => item?.isUploading);
      state.uploadFiles = modifyFileUpload;
    },
    // Changing the state directly (with mutation) in reducer
    // Redux Toolkit uses Immer under the hood (takes care of immutablility)
    changeFilters: (
      state,
      action: PayloadAction<Partial<IVirtualVisitFilter>>
    ) => {
      state.filters = { ...state.filters, ...action.payload };
    },
    changeAdvancedSearch: (
      state,
      action: PayloadAction<Partial<IVirtualVisitAdvancedSearchForm>>
    ) => {
      const advanceFilters = { ...state.advancedSearch, ...action.payload };
      const { isAdvancedSearch, ...rest } = advanceFilters;
      state.advancedSearch = {
        ...advanceFilters,
        isAdvancedSearch: !checkIfValuesInObjectIsEmpty(rest),
      };
      state.advancedSearch = {
        ...removeEmptyValuesFromObject(state.advancedSearch),
        ...action.payload,
      };
    },

    resetAdvancedSearch: (state) => {
      state.advancedSearch = { ...advancedSearchDefaultValues };
    },

    resetFilters: (state) => {
      state.filters = {
        ...state.filters,
        ...DEFAULT_TABLE_FILTER,
      };
    },
  },
});

// Actions
export const {
  setUser,
  setMessage,
  appendMessage,
  changeFilters,
  resetFilters,
  resetAdvancedSearch,
  changeAdvancedSearch,
  setIsLoading,
  setFileUpload,
  setFileUploadComplete,
} = slice.actions;

// Selectors
export const selectVirtualVisitMessage = (state: RootState) =>
  state.virtualVisit.messages;
export const selectVirtualVisitUser = (state: RootState) =>
  state.virtualVisit.users;
export const selectFileUpload = (state: RootState) =>
  state.virtualVisit.uploadFiles;

// Selectors
export const selectIsLoading = (state: RootState) =>
  state.virtualVisit.isLoading;
export const selectVirtualVisitFilter = (state: RootState) =>
  state.virtualVisit.filters;
export const selectVirtualVisitSort = (state: RootState) =>
  state.virtualVisit.sort;
export const selectVirtualVisitAdvancedSearch = (state: RootState) =>
  state.virtualVisit.advancedSearch;
// Reducer
export default slice.reducer;
