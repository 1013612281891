import apiRoutes from 'constants/apiRoutes';
import { IResponse } from 'interfaces/http';
import { ILocationFilter } from 'interfaces/location';
import { baseRequest } from 'utils/axios';

const { location } = apiRoutes;

export const getLocation = async (
  params: ILocationFilter
): Promise<IResponse<any>> => {
  const { response, error } = await baseRequest({
    method: 'GET',
    url: location.getAll,
    params,
  });

  if (error) {
    throw new Error(error.message);
  }

  return response?.data;
};
