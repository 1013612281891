import { ReferenceSubType } from 'enums/common';
import { selectAuthTenantData, selectAuthUserClient } from 'stores/auth';
import { useAppSelector } from 'stores/hooks';

import { useCheckUserIsClient } from './useCheckUserIsClient';

/**
 * Checks if the logged in user is a primary individual client
 *
 */
export const useCheckUserIsClientIndividual = () => {
  const tenantData = useAppSelector(selectAuthTenantData);
  const userData = useAppSelector(selectAuthUserClient);

  const isUserClient = useCheckUserIsClient();

  const referenceSubType = tenantData?.tenantAssociation?.referenceSubType;

  return !!(
    (
      isUserClient &&
      referenceSubType === ReferenceSubType.INDIVIDUAL &&
      !userData?.relationship?.groupId && // Group Member also has `referenceSubType` = 'Individual', so need to check `groupId`
      !userData?.relationship?.primaryId
    ) // Dependents have `primaryId`
  );
};
