/* eslint-disable no-param-reassign */
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import commonConstants from 'constants/common';
import { PaymentMethod } from 'enums/Payment';
import { OfferingSortBy } from 'enums/settings/tenant-offerings';
import {
  ITransactionAdvancedSearchForm,
  ITransactionDetailTableRow,
  ITransactionFilter,
} from 'interfaces/billings/transactions';
import {
  IAdaptedClientGroup,
  IAdaptedClientIndividual,
} from 'interfaces/client-management';
import { IFilter, SortOrderType } from 'interfaces/common';
import { IOfferingOption } from 'interfaces/tenant-management/tenant/tenantAncillaryOffering';
import { RootState } from 'stores';
import { checkIfValuesInObjectIsEmpty } from 'utils/common';

const { DEFAULT_TABLE_FILTER } = commonConstants;

const advancedSearchDefaultValues = {
  firstName: '',
  lastName: '',
  descriptor: '',
  transStart: '',
  transEnd: '',
  isAdvancedSearch: false,
};

interface IOfferingSort {
  sortBy: OfferingSortBy | '';
  sortOrder: SortOrderType | '';
}

interface IPayment {
  paymentMethod: PaymentMethod | null;
  cardToken: string;
  cardExpiry: string;
  bankToken: string;
  agreeTerms: boolean;

  // Specific to Authorize.Net
  cardNumber?: string;
  cardCVC?: string;
  tokenSource?: string;
  accountNumber?: string;
  routingNumber?: string;
  accountType?: string;
}

interface State {
  filters: IFilter;
  advancedSearch: ITransactionAdvancedSearchForm;
  sort: IOfferingSort;
  // Usage: AddTransaction
  editPatientData: {
    client: IAdaptedClientGroup | IAdaptedClientIndividual | null;
    offeringDetail: {
      note: string;
      offerings: IOfferingOption[];
    };
    payment: IPayment;
  };
  transactionReceiptData: ITransactionDetailTableRow | null;
}

const initialState: State = {
  filters: { ...DEFAULT_TABLE_FILTER },
  advancedSearch: { ...advancedSearchDefaultValues },
  sort: {
    sortBy: '',
    sortOrder: '',
  },
  editPatientData: {
    client: null,
    offeringDetail: {
      note: '',
      offerings: [],
    },
    payment: {
      paymentMethod: null,
      cardToken: '',
      cardExpiry: '',
      bankToken: '',
      agreeTerms: false,
    },
  },
  transactionReceiptData: null,
};

export const slice = createSlice({
  name: 'transactions',
  initialState,
  reducers: {
    // Changing the state directly (with mutation) in reducer
    // Redux Toolkit uses Immer under the hood (takes care of immutablility)
    changeFilters: (
      state,
      action: PayloadAction<Partial<ITransactionFilter>>
    ) => {
      state.filters = { ...state.filters, ...action.payload };
    },

    resetFilters: (state) => {
      state.filters = { ...DEFAULT_TABLE_FILTER };
    },

    changeAdvancedSearch: (
      state,
      action: PayloadAction<Partial<ITransactionAdvancedSearchForm>>
    ) => {
      const advanceFilters = { ...state.advancedSearch, ...action.payload };
      const { isAdvancedSearch, ...rest } = advanceFilters;
      state.advancedSearch = {
        ...advanceFilters,
        isAdvancedSearch: !checkIfValuesInObjectIsEmpty(rest),
      };
      state.advancedSearch = { ...state.advancedSearch, ...action.payload };
    },

    resetAdvancedSearch: (state) => {
      state.advancedSearch = { ...advancedSearchDefaultValues };
    },

    changeSortByAndOrder: (state, action: PayloadAction<IOfferingSort>) => {
      state.sort = {
        sortBy: action.payload.sortBy,
        sortOrder: action.payload.sortOrder,
      };
    },

    resetEditPatientData: (state) => {
      state.editPatientData = {
        client: null,
        offeringDetail: {
          note: '',
          offerings: [],
        },
        payment: {
          paymentMethod: null,
          cardToken: '',
          cardExpiry: '',
          bankToken: '',
          agreeTerms: false,
        },
      };
    },

    changePatient: (
      state,
      action: PayloadAction<IAdaptedClientGroup | IAdaptedClientIndividual>
    ) => {
      state.editPatientData = {
        ...state.editPatientData,
        client: action.payload,
      };
    },

    changePatientOfferings: (
      state,
      action: PayloadAction<IOfferingOption[]>
    ) => {
      state.editPatientData = {
        ...state.editPatientData,
        offeringDetail: {
          ...state.editPatientData.offeringDetail,
          offerings: action.payload,
        },
      };
    },

    changePatientOfferingNote: (state, action: PayloadAction<string>) => {
      state.editPatientData = {
        ...state.editPatientData,
        offeringDetail: {
          ...state.editPatientData.offeringDetail,
          note: action.payload,
        },
      };
    },

    changePatientPayment: (state, action: PayloadAction<Partial<IPayment>>) => {
      state.editPatientData = {
        ...state.editPatientData,
        payment: {
          ...state.editPatientData.payment,
          ...action.payload,
        },
      };
    },

    changeTransactionReceiptData: (state, action) => {
      state.transactionReceiptData = {
        ...state.transactionReceiptData,
        ...action.payload,
      };
    },

    resetAllFilters: (state) => {
      state.advancedSearch = { ...advancedSearchDefaultValues };
      state.filters = { ...DEFAULT_TABLE_FILTER };
      state.sort = initialState.sort;
    },
  },
});

// Actions
export const {
  changeFilters,
  changeAdvancedSearch,
  resetFilters,
  resetAdvancedSearch,
  changeSortByAndOrder,
  resetEditPatientData,
  changePatient,
  changePatientOfferings,
  changePatientOfferingNote,
  changePatientPayment,
  resetAllFilters,
  changeTransactionReceiptData,
} = slice.actions;

// Selectors
export const selectTransactionFilter = (state: RootState) =>
  state.transactions.filters;
export const selectTransactionSort = (state: RootState) =>
  state.transactions.sort;
export const selectTransactionAdvancedSearch = (state: RootState) =>
  state.transactions.advancedSearch;
export const selectPatient = (state: RootState) =>
  state.transactions.editPatientData?.client;
export const selectPatientOfferingDetail = (state: RootState) =>
  state.transactions.editPatientData?.offeringDetail;
export const selectPatientPayment = (state: RootState) =>
  state.transactions.editPatientData?.payment;
export const selectTransactionReceiptData = (state: RootState) =>
  state.transactions.transactionReceiptData;

// Reducer
export default slice.reducer;
