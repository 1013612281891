import AuthorizeDotNetImg from 'assets/images/png/authorize-dot-net.png';
import CardConnectImg from 'assets/images/png/first-data.png';
import StripeImg from 'assets/images/svg/Stripe.svg';
import { AuthorizeDotNetBankAccountType, GatewayType } from 'enums/Payment';

const { CHECKING, SAVINGS, BUSINESS_CHECKING } = AuthorizeDotNetBankAccountType;

const paymentConstants = {
  cardConnect: {
    ENCODED_STYLES:
      "body %7B font-family: 'Heebo', sans-serif; margin-left: 0;%0A%7D%0Ainput %7B border: 1.5px solid %23d4d4d4; height: 40px; background-color: %23fafafa; font-size: 16px; text-indent: 16px; margin-top: 10px; color: %23333;%0A%7D%0Ainput:focus %7B outline: none !important; border: 1.5px solid %2300a1be; box-shadow: 0 0 3px %23b0b0b0; background-color: %23ffffff;%0A%7D%0Aselect %7B border: 1.5px solid %23d4d4d4; height: 44.5px; background-color: %23fafafa; font-size: 16px; margin-top: 10px; color: %23333; text-indent: 8px;%0A%7D%0Aselect:focus %7B outline: none !important; border: 1.5px solid %2300a1be; box-shadow: 0 0 3px %23b0b0b0; background-color: %23ffffff;%0A%7D%0Aselect:not(:-internal-list-box) %7B overflow: visible !important;%0A%7D%0A%23cccardlabel,%0A%23ccexpirylabel,%0A%23cccvvlabel %7B display: none;%0A%7D%0A%23ccnumfield %7B width: 100%25; display: block; margin-bottom: 0.4rem; margin-right: 15px;%0A%7D%0A%23ccexpirymonth %7B width: 6.5rem; margin-right: 1rem;%0A%7D%0A%23ccexpiryyear %7B width: 8.5rem; margin-right: 1rem;%0A%7D%0A%23cccvvfield %7B width: 12.5rem;%0A%7D%0A%23tokenform %7B display: flex;%0A%7D",
    ENCODED_STYLES_MOBILE:
      "body %7B font-family: 'Heebo', sans-serif; margin-left: 0;%0A%7D%0Ainput %7B border: 1.5px solid %23d4d4d4; height: 40px; background-color: %23fafafa; font-size: 16px; text-indent: 16px; margin-top: 10px; color: %23333;%0A%7D input:focus %7B outline: none !important; border: 1.5px solid %2300a1be; box-shadow: 0 0 3px %23b0b0b0; background-color: %23ffffff;%0A%7D select %7B border: 1.5px solid %23d4d4d4; height: 44.5px; background-color: %23fafafa; font-size: 16px; margin-top: 10px; color: %23333; text-indent: 8px;%0A%7D select:focus %7B outline: none !important; border: 1.5px solid %2300a1be; box-shadow: 0 0 3px %23b0b0b0; background-color: %23ffffff;%0A%7D select:not(:-internal-list-box) %7B overflow: visible !important;%0A%7D %23cccardlabel,%0A%23ccexpirylabel,%0A%23cccvvlabel %7B display: none;%0A%7D %23ccnumfield %7B width: 100%25; display: block; margin-bottom: 0.4rem;%0A%7D %23ccexpirymonth %7B width: 4rem; margin-right: 1rem;%0A%7D %23ccexpiryyear %7B width: 5rem; margin-right: 1rem;%0A%7D",
    CARD_INPUT_PARAMETERS:
      'formatinput=true&cardinputmaxlength=19&cardnumbernumericonly=true&useexpiry=true&usecvv=true&orientation=custom&placeholder=Card Number&placeholdercvv=CVV&placeholdermonth=MM&placeholderyear=YYYY&invalidinputevent=true&invalidexpiryevent=true&invalidcvvevent=true&enhancedresponse=true',
    BANK_INPUT_PARAMETERS:
      'invalidinputevent=true&enhancedresponse=true&placeholder=Routing Number     Account Number',
  },
  label: {
    CARD: 'Credit/Debit Card',
    BANK: 'Bank Transfer',
  },
  AuthorizeDotNetBankAccountTypeOptions: [
    { value: CHECKING, text: 'Checking' },
    { value: SAVINGS, text: 'Savings' },
    { value: BUSINESS_CHECKING, text: 'Business Checking' },
  ],
  gatewayMetadata: {
    [GatewayType.AUTHORIZE_DOT_NET]: {
      image: AuthorizeDotNetImg,
      link: 'https://www.authorize.net/',
      imageHeight: 20,
      displayText: GatewayType.AUTHORIZE_DOT_NET,
    },
    [GatewayType.CARD_CONNECT]: {
      image: CardConnectImg,
      link: 'https://www.firstdata.com/',
      imageHeight: 32,
      displayText: 'Fiserv',
    },
    [GatewayType.FIRST_DATA]: {
      image: CardConnectImg,
      link: 'https://www.firstdata.com/',
      imageHeight: 32,
      displayText: 'Fiserv',
    },
    [GatewayType.STRIPE]: {
      image: StripeImg,
      link: 'https://stripe.com/',
      imageHeight: 24,
      displayText: GatewayType.STRIPE,
    },
  },
};

export default paymentConstants;
