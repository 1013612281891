import { Typography } from '@mui/material';

interface IProps {
  children: React.ReactNode;
}

/**
 * Works similar to <strong></strong> or <b></b> for fontWeight: 500
 *
 */
const MediumSpanText = ({ children }: IProps): JSX.Element => (
  <Typography component="span" fontWeight="medium">
    {children}
  </Typography>
);

export default MediumSpanText;
