import { useQuery } from 'react-query';

import * as langDictAPI from 'api/language-dictionary';
import { ILangDictFilter } from 'interfaces/language-dictionary';

export const langDict = {
  all: ['language-dictionary'] as const,
  lists: () => [...langDict.all, 'list'] as const,
  list: (filters: ILangDictFilter) =>
    [...langDict.lists(), { filters }] as const,
};

export const useLanguageDictionaryQuery = (
  filters: ILangDictFilter,
  {
    enabled,
  }: {
    enabled: boolean;
  }
) => {
  const queryInfo = useQuery(
    langDict.list(filters),
    () => langDictAPI.getLanguageDictionary(filters),
    {
      enabled,
    }
  );
  return {
    ...queryInfo,
    data: queryInfo.data?.data,
  };
};
