import {
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
} from '@mui/material';
import PermissionGate from 'common/PermissionGate';

interface IProps {
  iconElement: React.ReactNode;
  text: string;
  onClick?: VoidFunction | undefined;
  checkPermission?: boolean;
  disabled?: boolean;
  dataCy?: string;
}

const EllipseMenuItem = ({
  iconElement,
  text,
  onClick,
  checkPermission,
  disabled,
  dataCy,
}: IProps) =>
  checkPermission ? (
    <PermissionGate>
      <ListItem data-cy={dataCy} disablePadding onClick={onClick}>
        <ListItemButton disabled={disabled}>
          <ListItemIcon>{iconElement}</ListItemIcon>
          <ListItemText primary={text} />
        </ListItemButton>
      </ListItem>
    </PermissionGate>
  ) : (
    <ListItem data-cy={dataCy} disablePadding onClick={onClick}>
      <ListItemButton disabled={disabled}>
        <ListItemIcon>{iconElement}</ListItemIcon>
        <ListItemText primary={text} />
      </ListItemButton>
    </ListItem>
  );

EllipseMenuItem.defaultProps = {
  onClick: undefined,
  checkPermission: true,
  disabled: false,
  dataCy: '',
};

export default EllipseMenuItem;
