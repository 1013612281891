import { useLocation } from 'react-router-dom';

import { faClose } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  FormControl,
  FormControlProps,
  IconButton,
  MenuItem,
  MenuListProps,
  Select,
  SelectChangeEvent,
} from '@mui/material';
import LoadingIndicator from 'common/LoadingIndicator';
import { useIntervalQuery } from 'services/interval';

interface IProps extends Omit<FormControlProps, 'onChange'> {
  name: string;
  onChange: (event: SelectChangeEvent<string>) => void;
  selectedValue: string | undefined;
}

const IntervalSelect = ({
  name,
  selectedValue,
  onChange,
  variant = 'standard',
}: IProps): JSX.Element => {
  const location = useLocation();
  const { data, isLoading } = useIntervalQuery();
  const isPathReports = location.pathname.includes('/reports');
  const isPathTimesheet = location.pathname.includes('/timesheet');

  if (isLoading) return <LoadingIndicator containerHeight="2vh" size="2rem" />;
  const handleClear = () =>
    onChange?.({
      target: {
        name,
        value: '',
      },
    } as SelectChangeEvent<string>);

  return (
    <FormControl
      className="select-filled-variant--hide-label-on-focus"
      fullWidth
      size="small"
      variant={variant}
    >
      {data && (
        <Select
          data-cy="client-interval-select-input"
          endAdornment={
            <IconButton
              onClick={handleClear}
              size="small"
              sx={{
                display:
                  selectedValue && !isPathReports && !isPathTimesheet
                    ? 'inherit'
                    : 'none',
                position: 'absolute',
                right: 15,
              }}
            >
              <FontAwesomeIcon icon={faClose} size="xs" />
            </IconButton>
          }
          MenuProps={{
            MenuListProps: {
              'data-cy': 'client-interval-select-container',
            } as Partial<MenuListProps>,
          }}
          name={name}
          onChange={onChange}
          value={selectedValue}
        >
          {data.rows.map((interval: any) => (
            <MenuItem
              data-cy={`client-interval-select-${interval.name}`}
              key={interval.id}
              value={interval.value}
            >
              {interval.name}
            </MenuItem>
          ))}
        </Select>
      )}
    </FormControl>
  );
};

export default IntervalSelect;
