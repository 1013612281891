import { useMutation, useQuery, useQueryClient } from 'react-query';

import * as paymentAPI from 'api/payment';
import { IFilter } from 'interfaces/common';
import { IError } from 'interfaces/http';
import {
  IAddPaymentSourceSchema,
  IPaymentSourceFilter,
  ISetPrimaryPaymentSourceSchema,
} from 'interfaces/payment';
import { useSnackbar } from 'notistack';
import { adaptPaymentSourceList } from 'utils/payment';

export const paymentSourceKeys = {
  all: ['payment-sources'] as const,
  lists: () => [...paymentSourceKeys.all, 'list'] as const,
  list: (filters: IFilter) =>
    [...paymentSourceKeys.lists(), { filters }] as const,
  details: () => [...paymentSourceKeys.all, 'detail'] as const,
  detail: (id: number | string) =>
    [...paymentSourceKeys.details(), id] as const,
  merchantProfile: (tenantId: string) =>
    ['merchant-profile', tenantId] as const,
};

export const usePaymentSourceQuery = (
  filters: IPaymentSourceFilter,
  { enabled }: { enabled: boolean }
) => {
  const queryInfo = useQuery(
    paymentSourceKeys.list(filters),
    () => paymentAPI.getPaymentSources(filters),
    {
      enabled,
      select: adaptPaymentSourceList,
    }
  );
  return {
    ...queryInfo,
    data: queryInfo.data?.data,
  };
};

export const useAddPaymentSourceMutation = () => {
  const { enqueueSnackbar } = useSnackbar();
  const queryClient = useQueryClient();

  return useMutation(
    ({ clientId, data }: { clientId: string; data: IAddPaymentSourceSchema }) =>
      paymentAPI.addPaymentSource(clientId, data),
    {
      onSuccess: (res) => {
        queryClient.invalidateQueries(paymentSourceKeys.lists());
        enqueueSnackbar(res.message, {
          variant: 'success',
        });
      },
      onError: (err: IError) => {
        enqueueSnackbar(err.message, {
          variant: 'error',
        });
      },
    }
  );
};

export const useRemovePaymentSourceMutation = () => {
  const { enqueueSnackbar } = useSnackbar();
  const queryClient = useQueryClient();

  return useMutation(
    ({
      merchantCustomerId,
      paymentSourceId,
    }: {
      merchantCustomerId: string;
      paymentSourceId: string;
    }) => paymentAPI.removePaymentSource(merchantCustomerId, paymentSourceId),
    {
      onSuccess: (res) => {
        queryClient.invalidateQueries(paymentSourceKeys.lists());
        enqueueSnackbar(res.message, {
          variant: 'success',
        });
      },
      onError: (err: IError) => {
        enqueueSnackbar(err.message, {
          variant: 'error',
        });
      },
    }
  );
};

export const useSetPrimaryPaymentSourceMutation = () => {
  const { enqueueSnackbar } = useSnackbar();
  const queryClient = useQueryClient();

  return useMutation(
    ({
      merchantCustomerId,
      paymentSourceId,
      data,
    }: {
      merchantCustomerId: string;
      paymentSourceId: string;
      data: ISetPrimaryPaymentSourceSchema;
    }) =>
      paymentAPI.setPrimaryPaymentSource(
        merchantCustomerId,
        paymentSourceId,
        data
      ),
    {
      onSuccess: (res) => {
        queryClient.invalidateQueries(paymentSourceKeys.lists());
        enqueueSnackbar(res.message, {
          variant: 'success',
        });
      },
      onError: (err: IError) => {
        enqueueSnackbar(err.message, {
          variant: 'error',
        });
      },
    }
  );
};

export const useMerchantProfileQuery = (
  tenantId: string,
  { enabled }: { enabled: boolean }
) => {
  const queryInfo = useQuery(
    paymentSourceKeys.merchantProfile(tenantId),
    () => paymentAPI.getMerchantProfile(tenantId),
    {
      enabled,
    }
  );
  return {
    ...queryInfo,
    data: queryInfo.data?.data,
  };
};
