import { useQuery } from 'react-query';

import { getLocation } from 'api/location';
import { ILocationFilter } from 'interfaces/location';
import { adaptLocationList } from 'utils/location';

export const locationKeys = {
  all: ['location'] as const,
  lists: () => [...locationKeys.all, 'list'] as const,
  list: (filters: ILocationFilter) =>
    [...locationKeys.lists(), { filters }] as const,
};

export const useLocationQuery = (filters: ILocationFilter) => {
  const queryInfo = useQuery(
    locationKeys.list(filters),
    () => getLocation(filters),
    {
      select: adaptLocationList,
    }
  );
  return {
    ...queryInfo,
    data: queryInfo.data?.data,
  };
};
