import { useMutation } from 'react-query';

import * as supportInquiryAPI from 'api/support-inquiry';
import { IError } from 'interfaces/http';
import { ISupportInquirySchema } from 'interfaces/support-inquiry';
import { useSnackbar } from 'notistack';

export const useSaveSupportInquiryMutation = () => {
  const { enqueueSnackbar } = useSnackbar();
  return useMutation(
    ({ data }: { data: ISupportInquirySchema }) =>
      supportInquiryAPI.addEmailCommunicationTemplate(data),
    {
      onSuccess: (res) => {
        enqueueSnackbar(res.message, {
          variant: 'success',
        });
      },

      onError: (err: IError) => {
        enqueueSnackbar(err.message, {
          variant: 'error',
        });
      },
    }
  );
};
