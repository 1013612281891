import apiRoutes from 'constants/apiRoutes';
import { IFilter } from 'interfaces/common';
import { IListResponse, IResponse } from 'interfaces/http';
import {
  IContact,
  IContactSchema,
  IContactTableRow,
} from 'interfaces/settings/contacts';
import { baseRequest } from 'utils/axios';

const { contacts } = apiRoutes.setting;

export const getContacts = async (
  params: IFilter
): Promise<IResponse<IListResponse<IContactTableRow>>> => {
  const { response, error } = await baseRequest({
    method: 'GET',
    url: contacts.getAll,
    params,
  });

  if (error) {
    throw new Error(error.message);
  }

  return response?.data;
};

export const getContactDetail = async (
  contactId: string
): Promise<IResponse<IContact>> => {
  const { response, error } = await baseRequest({
    method: 'GET',
    url: contacts.getOne.replace(':id', contactId),
  });

  if (error) {
    throw new Error(error.message);
  }

  return response?.data;
};

export const addContact = async (
  data: IContactSchema
): Promise<IResponse<IContact>> => {
  const { response, error } = await baseRequest({
    method: 'POST',
    url: contacts.add,
    data,
  });

  if (error) {
    throw new Error(error.message);
  }

  return response?.data;
};

export const editContact = async (
  contactId: string,
  data: IContactSchema
): Promise<IResponse<IContact>> => {
  const { response, error } = await baseRequest({
    method: 'PUT',
    url: contacts.edit.replace(':id', contactId),
    data,
  });

  if (error) {
    throw new Error(error.message);
  }

  return response?.data;
};

export const removeContact = async (
  contactId: string
): Promise<IResponse<{ contactId: string }>> => {
  const { response, error } = await baseRequest({
    method: 'DELETE',
    url: contacts.remove.replace(':id', contactId),
  });

  if (error) {
    throw new Error(error.message);
  }

  return response?.data;
};
