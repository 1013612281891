import { useEffect, useState } from 'react';

import { Box, TextField } from '@mui/material';
import AdvancedFilterFormLayout from 'common/layouts/advanced-filter/AdvancedFilterFormLayout';
import { IUserAdvancedSearchForm } from 'interfaces/tenant-management/user';

interface IProps {
  data: IUserAdvancedSearchForm;
}

const UserAdvancedFilter = ({ data }: IProps) => {
  const [formData, setFormData] = useState<IUserAdvancedSearchForm>({
    firstName: '',
    lastName: '',
    email: '',
    phone: '',
  });

  const onChange = (
    key: 'firstName' | 'lastName' | 'email' | 'phone',
    value: string
  ) => {
    setFormData((prevState) => ({
      ...prevState,
      [key]: value,
    }));
  };

  useEffect(() => {
    setFormData({
      firstName: data.firstName,
      lastName: data.lastName,
      email: data.email,
      phone: data.phone,
    });
  }, [data]);

  return (
    <Box>
      <AdvancedFilterFormLayout label="First Name">
        <TextField
          fullWidth
          name="firstName"
          onChange={(e) => onChange('firstName', e.target.value)}
          size="medium"
          value={formData.firstName}
          variant="standard"
        />
      </AdvancedFilterFormLayout>
      <AdvancedFilterFormLayout label="Last Name">
        <TextField
          fullWidth
          name="lastName"
          onChange={(e) => onChange('lastName', e.target.value)}
          size="medium"
          value={formData.lastName}
          variant="standard"
        />
      </AdvancedFilterFormLayout>
      <AdvancedFilterFormLayout label="Email">
        <TextField
          fullWidth
          name="email"
          onChange={(e) => onChange('email', e.target.value)}
          size="medium"
          value={formData.email}
          variant="standard"
        />
      </AdvancedFilterFormLayout>
      <AdvancedFilterFormLayout label="Phone">
        <TextField
          fullWidth
          name="phone"
          onChange={(e) => onChange('phone', e.target.value)}
          size="medium"
          value={formData.phone}
          variant="standard"
        />
      </AdvancedFilterFormLayout>
    </Box>
  );
};

export default UserAdvancedFilter;
