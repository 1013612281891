import { useLocation, useNavigate } from 'react-router-dom';

import { faCircleCheck } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Box, Typography, useTheme } from '@mui/material';
import ModalFooter from 'common/ui/modal/ModalFooter';
import { ChangeMembershipActionType } from 'enums/client-management';
import { EnrollmentCode } from 'enums/tenant-management/tenant';
import {
  selectChangeMembershipModal,
  setChangeMembershipClientData,
  setChangeMembershipSelectedData,
  setChangeMembershipStep,
  setCurrentMembershipSelectedData,
} from 'stores/client-management';
import { useAppDispatch, useAppSelector } from 'stores/hooks';
import { checkIsEnrollmentSettingEnabled } from 'utils/tenantSetting';

const ChangeMembershipSuccess = () => {
  const theme = useTheme();
  const navigate = useNavigate();
  const location = useLocation();

  const changeMembershipActionType = useAppSelector(
    selectChangeMembershipModal
  )?.actionType;

  const previousStep =
    changeMembershipActionType === ChangeMembershipActionType.COMPLETE ? 4 : 2;

  const isCompleteMembershipEnabled = checkIsEnrollmentSettingEnabled(
    EnrollmentCode.COMPLETEMEMBERSHIP
  );

  const dispatch = useAppDispatch();
  const onDoneClick = () => {
    navigate(location.pathname.split('/modal')[0]);
    dispatch(setChangeMembershipClientData(null));
    dispatch(setCurrentMembershipSelectedData(null));
    dispatch(
      setChangeMembershipSelectedData({
        selectedMembershipType: null,
        startDate: null,
        endDate: null,
        selectedOffering: null,
      })
    );
    setTimeout(() => {
      dispatch(
        setChangeMembershipStep({
          step: 0,
          actionType: null,
        })
      );
    }, 1000);
  };

  const handlePrevious = () =>
    dispatch(
      setChangeMembershipStep({
        step: previousStep,
        actionType: changeMembershipActionType,
      })
    );

  const getTextForCompleteAndCancel = () => {
    if (changeMembershipActionType === ChangeMembershipActionType.CANCEL) {
      if (isCompleteMembershipEnabled) {
        return 'completed';
      }
      return 'cancelled';
    }
    return '';
  };

  return (
    <Box>
      <Box paddingY={6} sx={{ textAlign: 'center' }}>
        <FontAwesomeIcon
          color={theme.palette.success.main}
          icon={faCircleCheck}
          size="3x"
        />
        <Typography
          color="gray.darker"
          fontWeight="medium"
          marginY={1}
          variant="body1"
        >
          Membership{' '}
          {changeMembershipActionType === ChangeMembershipActionType.ADD &&
            'added'}{' '}
          {changeMembershipActionType === ChangeMembershipActionType.APPROVE &&
            'approved'}{' '}
          {getTextForCompleteAndCancel()}{' '}
          {changeMembershipActionType === ChangeMembershipActionType.COMPLETE &&
            'completed'}{' '}
          {changeMembershipActionType === ChangeMembershipActionType.DECLINE &&
            'declined'}{' '}
          {changeMembershipActionType === ChangeMembershipActionType.CHANGE &&
            'updated'}{' '}
          successfully.
        </Typography>
      </Box>
      <ModalFooter
        actionButtonLabel="Done"
        actionButtonType="button"
        cancelButtonType="previous"
        isCancelRequired={false}
        isLoading={false}
        onActionButtonClick={onDoneClick}
        onCancelButtonClick={handlePrevious}
      />
    </Box>
  );
};

export default ChangeMembershipSuccess;
