import {
  SyntheticEvent,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from 'react';

import {
  Autocomplete,
  AutocompleteChangeReason,
  Box,
  debounce,
  FormControl,
  TextField,
  TextFieldProps,
  Typography,
  useTheme,
} from '@mui/material';
import commonConstants from 'constants/common';
import { INetworkTaxonomyAutocompleteOption } from 'interfaces/networks/network-taxonomy';
import { useNetworkTaxonomiesQuery } from 'services/networks/network-taxonomies';

const { DEFAULT_AUTOCOMPLETE_TABLE_FILTER } = commonConstants;

interface IProps {
  onSelectHandler: (data: INetworkTaxonomyAutocompleteOption[]) => void;
  inputProps?: TextFieldProps;
  defaultValue?: INetworkTaxonomyAutocompleteOption[];
  limitTags?: number;
}

const NetworkTaxonomyAutocomplete = ({
  onSelectHandler,
  inputProps,
  defaultValue = [],
  limitTags,
}: IProps) => {
  const [value, setValue] = useState<INetworkTaxonomyAutocompleteOption[]>([]);
  const [filters, setFilters] = useState({
    ...DEFAULT_AUTOCOMPLETE_TABLE_FILTER,
  });

  const taxonomyQuery = useNetworkTaxonomiesQuery(filters, {
    enabled: true,
  });

  const theme = useTheme();

  const rows = taxonomyQuery?.data?.rows;

  const options = useMemo(() => {
    const autocompleteOptions =
      rows?.map((item) => ({
        name: item.name,
        code: item.code,
      })) || [];

    return defaultValue?.length
      ? [...autocompleteOptions, ...defaultValue]
      : autocompleteOptions;
  }, [rows, defaultValue]);

  const onChange = useCallback(
    (data: INetworkTaxonomyAutocompleteOption[]) => onSelectHandler(data),
    [onSelectHandler]
  );

  const handleInputChange = async (_: any, newInputValue: string) => {
    if (newInputValue.length > 2 || !newInputValue.length)
      setFilters((prevState) => ({ ...prevState, keyword: newInputValue }));
  };

  const debouncedInputChange = useMemo(
    () => debounce(handleInputChange, 300),
    []
  );

  // For pre-selecting the value
  useEffect(() => {
    if (defaultValue?.length) {
      setValue(defaultValue);
    }
  }, [defaultValue]);

  return (
    <div>
      <Autocomplete
        autoComplete
        filterOptions={(x) => x}
        filterSelectedOptions
        getOptionLabel={(option) =>
          typeof option === 'string' ? option : `${option?.name}`
        }
        id="network-taxonomy-autocomplete"
        includeInputInList
        isOptionEqualToValue={(option, newValue) =>
          option.code === newValue?.code
        }
        limitTags={limitTags}
        loading={taxonomyQuery.isLoading}
        multiple
        onChange={(
          event: SyntheticEvent<Element, Event>,
          newValue: INetworkTaxonomyAutocompleteOption[],
          reason: AutocompleteChangeReason
        ) => {
          if (reason === 'clear') {
            onChange([]);
            setValue([]);
          } else {
            onChange(newValue);
            setValue(newValue);
          }
        }}
        onInputChange={debouncedInputChange}
        options={options}
        renderInput={(params) => (
          <FormControl fullWidth={!!inputProps?.fullWidth} variant="standard">
            <TextField
              {...params}
              {...inputProps}
              fullWidth
              size="small"
              variant="standard"
            />
          </FormControl>
        )}
        renderOption={(props, option) => (
          <Box {...props} component="li" key={option?.code}>
            <Box display="flex" flexDirection="column">
              <Typography
                gutterBottom={false}
                sx={{ color: theme.palette.text.primary }}
                variant="body2"
              >
                {option.name}{' '}
              </Typography>
              <Typography variant="caption"> {option?.code}</Typography>
            </Box>
          </Box>
        )}
        value={value}
      />
    </div>
  );
};

NetworkTaxonomyAutocomplete.defaultProps = {
  inputProps: undefined,
  limitTags: undefined,
  defaultValue: [],
};

export default NetworkTaxonomyAutocomplete;
