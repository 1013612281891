// using js..since ts is giving error
import * as html2pdf from 'html2pdf.js';

export const convertHtmlToPdfJs = (element, option) =>
  html2pdf().from(element).set(option).outputPdf('blob');

export const downloadAgreement = (element, option) =>
  html2pdf().from(element).set(option).toPdf().then().save();

export const getFileSize = (base64String) => {
  const stringLength = base64String.length;
  const sizeInBytes = 4 * Math.ceil(stringLength / 3) * 0.5624896334383812;
  const sizeInKb = sizeInBytes / 1000;
  return sizeInKb;
};
