/* eslint-disable no-param-reassign */
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import commonConstants from 'constants/common';
import { INoteState } from 'interfaces/note';
import { RootState } from 'stores';

const { DEFAULT_TABLE_FILTER } = commonConstants;
const initialState: INoteState = {
  filter: {
    ...DEFAULT_TABLE_FILTER,
  },
};

export const slice = createSlice({
  name: 'note',
  initialState,
  reducers: {
    changeFilters: (state, action: PayloadAction<INoteState>) => {
      state.filter = { ...state.filter, ...action.payload };
    },
  },
});

export const { changeFilters } = slice.actions;

export const selectIndividualFilter = (state: RootState) => state.note.filter;

export default slice.reducer;
