import { groupKeys } from 'services/client-management/group';
import { individualKeys } from 'services/client-management/individual';
import { selectAuthTenantAssociation } from 'stores/auth';
import { selectGroup } from 'stores/client-management/group';
import { selectIndividual } from 'stores/client-management/individual';
import { useAppSelector } from 'stores/hooks';
import { combineFilters } from 'utils/misc';

/**
 * Provide queryKey for clientGroup and queryIndividual
 *
 */
const useClientListQueryKey = () => {
  const clientGroupStore = useAppSelector(selectGroup);
  const clientIndividualStore = useAppSelector(selectIndividual);
  const authTenantAssociationData = useAppSelector(selectAuthTenantAssociation);

  const groupQueryKey = groupKeys.list(
    combineFilters(
      {
        ...clientGroupStore.filters,
        ...clientGroupStore.sort,
        groupCode: authTenantAssociationData?.groupCode,
      },
      clientGroupStore.advancedSearch
    )
  );

  const individualQueryKey = individualKeys.list(
    combineFilters(
      {
        ...clientIndividualStore.filters,
        ...clientIndividualStore.sort,
        groupCode: authTenantAssociationData?.groupCode,
      },
      clientIndividualStore.advancedSearch
    )
  );

  return {
    groupQueryKey,
    individualQueryKey,
  };
};

export default useClientListQueryKey;
