/* eslint-disable no-param-reassign */
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import commonConstants from 'constants/common';
import { Interval } from 'enums/common';
import { IFilter } from 'interfaces/common';
import { IMyTimesheetAdvancedSearchForm } from 'interfaces/timesheet/my-timesheet';
import { RootState } from 'stores';

const { DEFAULT_TABLE_FILTER } = commonConstants;

const advancedSearchDefaultValues = {
  interval: Interval.WEEKLY,
  startDate: '',
  endDate: '',
};

interface MyTimesheetState {
  filters: IFilter;
  advancedSearch: IMyTimesheetAdvancedSearchForm;
}

const initialState: MyTimesheetState = {
  filters: { ...DEFAULT_TABLE_FILTER },
  advancedSearch: { ...advancedSearchDefaultValues },
};

export const slice = createSlice({
  name: 'myTimesheet',
  initialState,
  reducers: {
    // Changing the state directly (with mutation) in reducer
    // Redux Toolkit uses Immer under the hood (takes care of immutablility)
    changeFilters: (state, action: PayloadAction<Partial<IFilter>>) => {
      state.filters = { ...state.filters, ...action.payload };
    },

    resetFilters: (state) => {
      state.filters = { ...DEFAULT_TABLE_FILTER };
    },

    resetAllFilters: (state) => {
      state.advancedSearch = { ...advancedSearchDefaultValues };
      state.filters = {
        ...state.filters,
        ...DEFAULT_TABLE_FILTER,
      };
    },

    changeAdvancedSearch: (
      state,
      action: PayloadAction<Partial<IMyTimesheetAdvancedSearchForm>>
    ) => {
      state.advancedSearch = { ...state.advancedSearch, ...action.payload };
    },

    resetAdvancedSearch: (state) => {
      state.advancedSearch = { ...advancedSearchDefaultValues };
    },
  },
});

// Actions
export const {
  changeFilters,
  changeAdvancedSearch,
  resetFilters,
  resetAllFilters,
  resetAdvancedSearch,
} = slice.actions;

// Selectors
export const selectMyTimesheetFilter = (state: RootState) =>
  state.myTimesheet.filters;
export const selectMyTimesheetAdvancedSearch = (state: RootState) =>
  state.myTimesheet.advancedSearch;
export const selectMyTimesheet = (state: RootState) => state.myTimesheet;

// Reducer
export default slice.reducer;
