import { useQuery } from 'react-query';

import * as networkTaxonomiesAPI from 'api/networks/network-taxonomies';
import { IFilter } from 'interfaces/common';

export const networkTaxonomiesKeys = {
  all: ['network-taxonomies'] as const,
  lists: () => [...networkTaxonomiesKeys.all, 'list'] as const,
  list: (filters: IFilter) =>
    [...networkTaxonomiesKeys.lists(), { filters }] as const,
};

export const useNetworkTaxonomiesQuery = (
  filters: IFilter,
  { enabled }: { enabled: boolean }
) => {
  const queryInfo = useQuery(
    networkTaxonomiesKeys.list(filters),
    () => networkTaxonomiesAPI.getAllNetworkTaxonomies(filters),
    {
      enabled,
    }
  );
  return {
    ...queryInfo,
    data: queryInfo.data?.data,
  };
};
