import apiRoutes from 'constants/apiRoutes';
import { IListResponse, IResponse } from 'interfaces/http';
import {
  IAddTenantMembershipOfferingForm,
  ITenantMembershipOffering,
  ITenantMembershipOfferingFilter,
  ITenantMembershipOfferingTableRow,
} from 'interfaces/tenant-management/tenant/tenantMembershipOffering';
import { baseRequest } from 'utils/axios';

const { membershipOfferings } = apiRoutes.tenantManagement.tenant;

export const getTenantOfferings = async (
  tenantId: string,
  params: ITenantMembershipOfferingFilter
): Promise<IResponse<IListResponse<ITenantMembershipOfferingTableRow>>> => {
  const { response, error } = await baseRequest({
    method: 'GET',
    url: membershipOfferings.getAll.replace(':id', tenantId),
    params,
  });

  if (error) {
    throw new Error(error.message);
  }

  return response?.data;
};

export const addTenantOffering = async (
  tenantId: string,
  data: IAddTenantMembershipOfferingForm
): Promise<IResponse<ITenantMembershipOfferingTableRow>> => {
  const { response, error } = await baseRequest({
    method: 'POST',
    url: membershipOfferings.add.replace(':id', tenantId),
    data,
  });

  if (error) {
    throw new Error(error.message);
  }

  return response?.data;
};

export const editTenantOffering = async (
  tenantId: string,
  tenantOfferingId: string,
  data: IAddTenantMembershipOfferingForm
): Promise<IResponse<ITenantMembershipOfferingTableRow>> => {
  const { response, error } = await baseRequest({
    method: 'PUT',
    url: membershipOfferings.edit
      .replace(':id', tenantId)
      .replace(':tenantOfferingId', tenantOfferingId),
    data,
  });
  if (error) {
    throw new Error(error.message);
  }

  return response?.data;
};

export const getTenantOfferingDetail = async (
  tenantId: string,
  tenantOfferingId: string
): Promise<IResponse<ITenantMembershipOffering>> => {
  const { response, error } = await baseRequest({
    method: 'GET',
    url: membershipOfferings.getOne
      .replace(':id', tenantId)
      .replace(':tenantOfferingId', tenantOfferingId),
  });

  if (error) {
    throw new Error(error.message);
  }

  return response?.data;
};

export const removeTenantOffering = async (
  tenantId: string,
  tenantOfferingId: string
): Promise<IResponse<ITenantMembershipOffering>> => {
  const { response, error } = await baseRequest({
    method: 'DELETE',
    url: membershipOfferings.remove
      .replace(':id', tenantId)
      .replace(':tenantOfferingId', tenantOfferingId),
  });

  if (error) {
    throw new Error(error.message);
  }

  return response?.data;
};
