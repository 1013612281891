import React from 'react';
import NumberFormat, { NumberFormatValues } from 'react-number-format';

import { TextField, TextFieldProps } from '@mui/material';

interface ICustomProps {
  onChange: (data: NumberFormatValues) => void;
  name: string;
}

const CustomNumberFormat = React.forwardRef<NumberFormat<any>, ICustomProps>(
  (props, ref) => {
    const { onChange, ...other } = props;

    return (
      <NumberFormat
        {...other}
        format="#################"
        getInputRef={ref}
        onValueChange={onChange}
      />
    );
  }
);

const MaskedAccountNumberInput = ({ ...textFieldProps }: TextFieldProps) => (
  <TextField
    className="filled-variant"
    fullWidth
    InputProps={{
      inputComponent: CustomNumberFormat as any,
    }}
    placeholder="Account Number"
    size="small"
    {...textFieldProps}
  />
);

MaskedAccountNumberInput.defaultProps = {
  name: '',
};

export default MaskedAccountNumberInput;
