import { useEffect, useMemo, useState } from 'react';

import {
  faCalendarCheck,
  faHandshakeSlash,
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  Alert,
  Box,
  MenuItem,
  MenuListProps,
  SelectProps,
  TextField,
  Typography,
  useTheme,
} from '@mui/material';
import DatePicker from 'common/DatePicker';
import MediumSpanText from 'common/display/MediumSpanText';
import ModalFooter from 'common/ui/modal/ModalFooter';
import { ChangeMembershipActionType } from 'enums/client-management';
import { TextTense } from 'enums/common';
import { BillingInterval, BillingType } from 'enums/settings/tenant-offerings';
import { EnrollmentCode } from 'enums/tenant-management/tenant';
import UserType from 'enums/User';
import { useCancelClientEnrollmentMutation } from 'services/client-management/enrollment';
import {
  selectChangeMembershipClientData,
  selectChangeMembershipModal,
  selectCurrentMembershipSelectedData,
  setChangeMembershipStep,
} from 'stores/client-management';
import { useAppDispatch, useAppSelector } from 'stores/hooks';
import { checkIsClientPrimary } from 'utils/client-management';
import {
  getNextBillingDate,
  getNextBillingDateSelectOptions,
} from 'utils/client-management/enrollment';
import { formatDateView, getMomentDate } from 'utils/moment';
import { checkIsEnrollmentSettingEnabled } from 'utils/tenantSetting';

const ChangeMembershipCompletion = () => {
  const [endDate, setEndDate] = useState<string>('');
  const [cancelNote, setCancelNote] = useState<string>('');
  const [isSelectedDateInvalid, setIsSelectedDateInvalid] =
    useState<boolean>(false);

  const theme = useTheme();
  const changeMembershipActionType = useAppSelector(
    selectChangeMembershipModal
  )?.actionType;
  const clientData = useAppSelector(selectChangeMembershipClientData) || null;
  const currentMembershipSelectedData =
    useAppSelector(selectCurrentMembershipSelectedData) || null;
  const currentBillingInterval =
    currentMembershipSelectedData?.offerings.billingInterval;
  const isClientPrimary = checkIsClientPrimary(clientData);
  const isMembershipBillingTypeFamily =
    currentMembershipSelectedData?.offerings.billingType === BillingType.FAMILY;

  const isCompleteMembershipEnabled = checkIsEnrollmentSettingEnabled(
    EnrollmentCode.COMPLETEMEMBERSHIP
  );

  const dispatch = useAppDispatch();
  const cancelClientEnrollmentMutation = useCancelClientEnrollmentMutation();

  const clientType = currentMembershipSelectedData?.client.type;

  const handlePrevious = () =>
    dispatch(
      setChangeMembershipStep({
        step: 0,
        actionType: changeMembershipActionType,
      })
    );

  const onConfirmCompletion = () => {
    const payload = {
      endDate,
      note: cancelNote,
    };
    cancelClientEnrollmentMutation.mutate(
      {
        clientId: clientData?.clientId ? clientData.clientId : '',
        enrollmentId: currentMembershipSelectedData?.clientEnrollmentId
          ? currentMembershipSelectedData.clientEnrollmentId
          : '',
        data: payload,
        clientType: clientType as UserType,
      },
      {
        onSuccess: () => {
          dispatch(
            setChangeMembershipStep({
              step: 3,
              actionType: changeMembershipActionType,
            })
          );
        },
      }
    );
  };

  const endDateOptions = useMemo(() => {
    const billingInterval =
      currentMembershipSelectedData?.offerings?.billingInterval;

    // Dropdown option is not shown if the billing interval is 'One Time'
    if (billingInterval === BillingInterval.ONE_TIME) {
      return [];
    }

    const startDate = currentMembershipSelectedData?.startDate;

    if (!(billingInterval && startDate)) return [];

    const nextBillingDate = getNextBillingDate(
      startDate,
      billingInterval as BillingInterval
    );

    return getNextBillingDateSelectOptions({
      billingInterval: billingInterval as BillingInterval,
      nextBillingDate: nextBillingDate as Date | string,
      benefitStartDate: startDate,
    });
  }, [
    currentMembershipSelectedData?.offerings?.billingInterval,
    currentMembershipSelectedData?.startDate,
  ]);

  useEffect(() => {
    if (endDateOptions.length > 0) {
      setEndDate(endDateOptions[0]?.value as string);
    }
  }, [endDateOptions]);

  const getActionText = (tense: TextTense) => {
    if (
      changeMembershipActionType === ChangeMembershipActionType.CANCEL &&
      isCompleteMembershipEnabled
    ) {
      if (tense === TextTense.PAST) return 'completed';
      if (tense === TextTense.PRESENT_PARTICIPLE) return 'completing';
      return 'complete';
    }
    if (tense === TextTense.PAST) return 'cancelled';
    if (tense === TextTense.PRESENT_PARTICIPLE) return 'canceling';
    return 'cancel';
  };

  return (
    <Box>
      <Box paddingY={6} sx={{ textAlign: 'center' }}>
        <FontAwesomeIcon
          color={theme.palette.primary.main}
          icon={faHandshakeSlash}
          size="3x"
        />
        <Typography display="block" marginY={2} variant="body1">
          <Typography display="inline" variant="body2">
            You are {getActionText(TextTense.PRESENT_PARTICIPLE)}{' '}
          </Typography>
          <Typography display="inline" fontWeight="medium" variant="body2">
            {currentMembershipSelectedData?.offerings.name}
          </Typography>
          <Typography display="inline" variant="body2">
            {' '}
            for{' '}
          </Typography>
          <Typography textTransform="capitalize" variant="body2">
            {clientData?.fullName
              ? clientData?.fullName
              : clientData?.businessName}
            .
          </Typography>
          {isClientPrimary && isMembershipBillingTypeFamily && (
            <Typography variant="body2">
              <MediumSpanText>
                This will {getActionText(TextTense.PRESENT)} the membership for
                all dependents if any.
              </MediumSpanText>
            </Typography>
          )}
        </Typography>
        <Box
          sx={{
            width: '30rem',
            margin: '0 auto',
            background: theme.palette.gray.lighter,
            padding: '1rem 0 1.5rem',
            borderRadius: '5px',
            border: `2px solid ${theme.palette.gray.light}`,
          }}
        >
          <Typography
            color={theme.palette.common.black}
            style={{ margin: '0 auto' }}
            variant="body2"
            width="20rem"
          >
            Please select a date that you would like to{' '}
            {getActionText(TextTense.PRESENT)} the membership on
          </Typography>
          <Box
            sx={{
              width: '15rem',
              margin: '1rem auto 0',
            }}
          >
            {endDateOptions.length > 0 ? (
              <>
                <FontAwesomeIcon
                  color={theme.palette.primary.main}
                  icon={faCalendarCheck}
                  style={{
                    margin: '0.5rem 0.5rem 0 0',
                  }}
                />
                <TextField
                  data-cy="client-membership-dropdown-input"
                  onChange={(e) => setEndDate(e.target.value)}
                  select
                  SelectProps={
                    {
                      'data-cy': 'client-membership-before',
                      MenuProps: {
                        MenuListProps: {
                          'data-cy': `client-membership-${getActionText(
                            TextTense.PRESENT
                          )}-container`,
                        } as Partial<MenuListProps>,
                      },
                    } as Partial<SelectProps<unknown>>
                  }
                  size="small"
                  value={endDate}
                  variant="standard"
                >
                  {endDateOptions.map((x: any) => (
                    <MenuItem value={x.value}>{x.label}</MenuItem>
                  ))}
                </TextField>
              </>
            ) : (
              <DatePicker
                // disableFuture
                inputProps={{
                  variant: 'standard',
                  'data-cy': `client-membership-${getActionText(
                    TextTense.PRESENT
                  )}-date-input`,
                }}
                inputStyle={{ width: '20rem' }}
                label="End Date"
                minDate={
                  currentMembershipSelectedData?.startDate
                    ? getMomentDate(currentMembershipSelectedData?.startDate)
                    : undefined
                }
                onChange={(date) =>
                  setEndDate(formatDateView(date as string) || '')
                }
                onError={(e) => {
                  if (e === null) {
                    setIsSelectedDateInvalid(false);
                  } else {
                    setIsSelectedDateInvalid(true);
                  }
                }}
                value={endDate}
              />
            )}
          </Box>
        </Box>
        <Box
          sx={{
            width: '30rem',
            margin: '1rem auto',
          }}
        >
          <TextField
            fullWidth
            label="Add Note"
            multiline
            onChange={(e) => setCancelNote(e.target.value)}
            rows={3}
            size="small"
            value={cancelNote}
            variant="standard"
          />
        </Box>
        <Box
          sx={{
            width: '34rem',
            margin: '0 auto 1rem',
          }}
        >
          <Alert
            severity="info"
            sx={{
              marginX: '2rem !important',
              textAlign: 'left',
            }}
          >
            {currentBillingInterval === BillingInterval.ONE_TIME
              ? `Membership can be ${getActionText(
                  TextTense.PAST
                )} at anytime. Please confirm to ${getActionText(
                  TextTense.PRESENT
                )} membership.`
              : `Membership can be ${getActionText(
                  TextTense.PAST
                )} at the end of billing cycle. Please confirm to ${getActionText(
                  TextTense.PRESENT
                )} membership.`}
          </Alert>
        </Box>
      </Box>
      <ModalFooter
        actionButtonLabel="Confirm"
        actionButtonType="button"
        cancelButtonType="previous"
        isDisabled={!endDate || isSelectedDateInvalid}
        isLoading={cancelClientEnrollmentMutation.isLoading}
        onActionButtonClick={onConfirmCompletion}
        onCancelButtonClick={handlePrevious}
      />
    </Box>
  );
};

export default ChangeMembershipCompletion;
