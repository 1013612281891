/* eslint-disable no-param-reassign */
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { ILangDict } from 'interfaces/language-dictionary';
import { RootState } from 'stores';

interface ILangDictState {
  dict: ILangDict | null;
}

const initialState: ILangDictState = {
  dict: null,
};

export const slice = createSlice({
  name: 'langDict',
  initialState,
  reducers: {
    // Changing the state directly (with mutation) in reducer
    // Redux Toolkit uses Immer under the hood (takes care of immutablility)
    setLangDict: (state, action: PayloadAction<any>) => {
      state.dict = action.payload;
    },
  },
});

// Actions
export const { setLangDict } = slice.actions;

// Selectors
export const selectLangDict = (state: RootState) => state.langDict;

// Reducer
export default slice.reducer;
