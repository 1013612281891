import { SyntheticEvent } from 'react';

import {
  Autocomplete,
  AutocompleteChangeReason,
  Chip,
  TextField,
  TextFieldProps,
} from '@mui/material';

interface IProps {
  inputProps?: TextFieldProps;
  onSelectHandler: (value: string[]) => void;
  disabled?: boolean;
  value?: string[];
}

const TagInput = ({
  disabled,
  inputProps,
  onSelectHandler,
  value = [],
}: IProps) => {
  const onChange = (tagValue: string[]) => {
    onSelectHandler(tagValue);
  };

  return (
    <Autocomplete
      disabled={disabled}
      freeSolo
      id="tags-input"
      multiple
      onChange={(
        event: SyntheticEvent<Element, Event>,
        newValue: string[],
        reason: AutocompleteChangeReason
      ) => {
        const filteredValue = newValue.filter((item: string) => !!item.trim());
        if (reason === 'clear') {
          onChange([]);
        } else {
          onChange(filteredValue);
        }
      }}
      options={[]}
      renderInput={(params) => (
        <TextField
          {...params}
          {...inputProps}
          inputProps={{
            ...params.inputProps,
            ...inputProps?.inputProps,
          }}
          variant={inputProps?.variant || 'standard'}
        />
      )}
      renderTags={(tagValue: readonly string[], getTagProps) =>
        tagValue.map((option: string, index: number) => (
          <Chip label={option} variant="outlined" {...getTagProps({ index })} />
        ))
      }
      value={value}
    />
  );
};

TagInput.defaultProps = {
  inputProps: null,
  value: [],
  disabled: false,
};

export default TagInput;
