import apiRoutes from 'constants/apiRoutes';
import { IResponse } from 'interfaces/http';
import { ISupportInquirySchema } from 'interfaces/support-inquiry';
import { baseRequest } from 'utils/axios';

export const addEmailCommunicationTemplate = async (
  data: ISupportInquirySchema
): Promise<IResponse<null>> => {
  const { response, error } = await baseRequest({
    method: 'POST',
    url: apiRoutes.supportInquiry,
    data,
  });

  if (error) {
    throw new Error(error.message);
  }

  return response?.data;
};
