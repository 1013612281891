import regex from 'constants/regex';
import validationMessage from 'constants/validationMessage';
import * as Yup from 'yup';

const { REQUIRED, INVALID } = validationMessage;

export const AddSupportInquirySchema = Yup.object().shape({
  name: Yup.string().nullable().trim().required(REQUIRED),
  email: Yup.string()
    .nullable()
    .trim()
    .required(REQUIRED)
    .matches(regex.EMAIL, {
      message: INVALID,
    }),
  description: Yup.string().nullable().trim().required(REQUIRED),
  phone: Yup.string().nullable().matches(regex.PHONE, {
    excludeEmptyString: true,
    message: INVALID,
  }),
  priority: Yup.string().nullable(),
});
