import { faInfoCircle, faWarning } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Components } from '@mui/material';
import { Theme } from '@mui/material/styles';

const Alert = (theme: Theme): Components<Theme> => ({
  MuiAlert: {
    defaultProps: {
      iconMapping: {
        success: (
          <FontAwesomeIcon
            color={theme.palette.success.dark}
            icon={faInfoCircle}
          />
        ),
        error: (
          <FontAwesomeIcon
            color={theme.palette.error.dark}
            icon={faInfoCircle}
          />
        ),
        info: (
          <FontAwesomeIcon
            color={theme.palette.info.dark}
            icon={faInfoCircle}
          />
        ),
        warning: (
          <FontAwesomeIcon
            color={theme.palette.warning.dark}
            icon={faWarning}
          />
        ),
      },
    },
    styleOverrides: {
      root: {
        padding: theme.spacing(2),
      },
      icon: {
        padding: 0,
      },
      message: {
        padding: 0,
      },
      standard: {
        backgroundColor: theme.palette.gray.lighter,
        color: theme.palette.gray.dark,
      },
      standardSuccess: {
        backgroundColor: theme.palette.success.lighter,
        color: theme.palette.success.dark,
      },
      standardError: {
        backgroundColor: theme.palette.error.lighter,
        color: theme.palette.error.dark,
      },
      standardWarning: {
        backgroundColor: theme.palette.warning.lighter,
        color: theme.palette.warning.dark,
      },
      standardInfo: {
        backgroundColor: theme.palette.info.lighter,
        color: theme.palette.info.dark,
      },
    },
  },
});

export default Alert;
