import { useMutation, useQuery, useQueryClient } from 'react-query';

import * as contactAPI from 'api/settings/contacts';
import { IContactsFilter } from 'interfaces/client-management';
import { IFilter } from 'interfaces/common';
import { IError } from 'interfaces/http';
import { IContactSchema, IContactTableRow } from 'interfaces/settings/contacts';
import { useSnackbar } from 'notistack';
import { RootState, store } from 'stores';
import { adaptContact, adaptContactList } from 'utils/settings/contacts';

export const contactKeys = {
  all: ['contacts'] as const,
  lists: () => [...contactKeys.all, 'list'] as const,
  list: (filters: IFilter) => [...contactKeys.lists(), { filters }] as const,
  details: () => [...contactKeys.all, 'detail'] as const,
  detail: (id: number | string) => [...contactKeys.details(), id] as const,
};

export const useContactsQuery = (filters: IContactsFilter) => {
  const queryInfo = useQuery(
    contactKeys.list(filters),
    () => contactAPI.getContacts(filters),
    {
      select: adaptContactList,
    }
  );

  return {
    ...queryInfo,
    data: queryInfo.data?.data,
  };
};

export const useContactDetailQuery = (
  contactId: string,
  { enabled }: { enabled: boolean }
) => {
  const queryInfo = useQuery(
    contactKeys.detail(contactId),
    () => contactAPI.getContactDetail(contactId),
    {
      enabled,
      select: adaptContact,
    }
  );

  return {
    ...queryInfo,
    data: queryInfo.data?.data,
  };
};

export const useAddContactMutation = () => {
  const { enqueueSnackbar } = useSnackbar();
  const queryClient = useQueryClient();

  const rootState: RootState = store.getState();
  const contactStore = rootState.referringAgent;

  return useMutation(
    ({ data }: { data: IContactSchema }) => contactAPI.addContact(data),
    {
      onSuccess: (res) => {
        enqueueSnackbar(res.message, {
          variant: 'success',
        });

        // add the newly created item to the list
        queryClient.setQueryData(
          contactKeys.list({ ...contactStore.filters, ...contactStore.sort }),
          (oldData: any) => ({
            ...oldData,
            data: {
              ...oldData.data,
              rows: [res.data, ...oldData.data.rows],
            },
          })
        );
      },
      onError: (err: IError) => {
        enqueueSnackbar(err.message, {
          variant: 'error',
        });
      },
    }
  );
};

export const useEditContactMutation = () => {
  const { enqueueSnackbar } = useSnackbar();
  const queryClient = useQueryClient();

  const rootState: RootState = store.getState();
  const contactStore = rootState.referringAgent;

  return useMutation(
    ({ contactId, data }: { contactId: string; data: IContactSchema }) =>
      contactAPI.editContact(contactId, data),
    {
      onSuccess: (res) => {
        enqueueSnackbar(res.message, {
          variant: 'success',
        });

        // update the list with the updated item
        queryClient.setQueryData(
          contactKeys.list({ ...contactStore.filters, ...contactStore.sort }),
          (oldData: any) => ({
            ...oldData,
            data: {
              ...oldData.data,
              rows: oldData.data.rows.map((item: IContactTableRow) => {
                if (item.contactId === res.data.contactId) {
                  return res.data;
                }
                return item;
              }),
            },
          })
        );
      },
      onError: (err: IError) => {
        enqueueSnackbar(err.message, {
          variant: 'error',
        });
      },
    }
  );
};

export const useRemoveContactMutation = () => {
  const { enqueueSnackbar } = useSnackbar();
  const queryClient = useQueryClient();

  const rootState: RootState = store.getState();
  const contactStore = rootState.referringAgent;

  return useMutation(
    ({ contactId }: { contactId: string }) =>
      contactAPI.removeContact(contactId),
    {
      onSuccess: (res) => {
        enqueueSnackbar(res.message, {
          variant: 'success',
        });

        // remove the item from the list
        queryClient.setQueryData(
          contactKeys.list({ ...contactStore.filters, ...contactStore.sort }),
          (oldData: any) => ({
            ...oldData,
            data: {
              ...oldData.data,
              count: oldData.data.count - 1,
              rows: oldData.data.rows.filter(
                (item: IContactTableRow) =>
                  item.contactId !== res.data.contactId
              ),
            },
          })
        );
      },
      onError: (err: IError) => {
        enqueueSnackbar(err.message, {
          variant: 'error',
        });
      },
    }
  );
};
