/* eslint-disable no-param-reassign */
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import commonConstants from 'constants/common';
import { SortOrder } from 'enums/common';
import { TenantServiceSortBy } from 'enums/settings/tenant-offerings';
import { IFilter, SortOrderType } from 'interfaces/common';
import { RootState } from 'stores';

const { DEFAULT_TABLE_FILTER } = commonConstants;

const sortDefaultValues = {
  sortBy: TenantServiceSortBy.NAME,
  sortOrder: SortOrder.ASC,
};

interface IOfferingSort {
  sortBy: TenantServiceSortBy;
  sortOrder: SortOrderType;
}
interface ITenantServiceState {
  filters: IFilter;
  sort: IOfferingSort;
}

const initialState: ITenantServiceState = {
  filters: { ...DEFAULT_TABLE_FILTER },
  sort: { ...sortDefaultValues },
};

export const slice = createSlice({
  name: 'tenantService',
  initialState,
  reducers: {
    // Changing the state directly (with mutation) in reducer
    // Redux Toolkit uses Immer under the hood (takes care of immutablility)
    changeFilters: (state, action: PayloadAction<Partial<IFilter>>) => {
      state.filters = { ...state.filters, ...action.payload };
    },

    resetFilters: (state) => {
      state.filters = { ...DEFAULT_TABLE_FILTER };
    },

    changeSortByAndOrder: (state, action: PayloadAction<IOfferingSort>) => {
      state.sort = {
        sortBy: action.payload.sortBy,
        sortOrder: action.payload.sortOrder,
      };
    },

    resetAllFilters: (state) => {
      state.sort = { ...sortDefaultValues };
      state.filters = { ...DEFAULT_TABLE_FILTER };
    },
  },
});

// Actions
export const {
  changeFilters,
  resetFilters,
  changeSortByAndOrder,
  resetAllFilters,
} = slice.actions;

// Selectors
export const selectTenantServiceFilter = (state: RootState) =>
  state.tenantService.filters;
export const selectTenantServiceSort = (state: RootState) =>
  state.tenantService.sort;

// Reducer
export default slice.reducer;
