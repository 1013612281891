import { useFormContext } from 'react-hook-form';

import { FormTextfield } from 'common';

interface IProps {
  isDisabled?: boolean;
}

const FormTenantCodeField = ({ isDisabled = false }: IProps) => {
  const methods = useFormContext();
  const {
    formState: { errors },
  } = methods;

  return (
    <FormTextfield
      disabled={isDisabled}
      label="Code"
      name="code"
      required
      showHelperText={errors.code?.type === 'checkIfCodeIsUnique'}
    />
  );
};

FormTenantCodeField.defaultProps = {
  isDisabled: false,
};

export default FormTenantCodeField;
