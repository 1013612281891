import CardInformationDisplay from '../CardInformationDisplay';

interface IProps {
  title?: string;
  subtitle?: string;
  cardToken: string;
  cardExpiry: string;
  onEditClick?: VoidFunction;
}

const FilledCardDetail = ({
  title,
  subtitle,
  cardToken,
  cardExpiry,
  onEditClick,
}: IProps) => {
  const getFormattedCardNumber = (token: string) =>
    `${token.slice(1, 3)}*********${token.slice(-4)}`;

  const getFormattedExpiryDate = (expiry: string) => {
    let month = expiry.slice(4);
    month = month && month.length === 1 ? `0${month}` : month;
    const year = expiry.slice(2, 4);
    return `${month}/${year}`;
  };

  return (
    <CardInformationDisplay
      cardExpiry={getFormattedExpiryDate(cardExpiry)}
      cardNumber={getFormattedCardNumber(cardToken)}
      onEditClick={onEditClick}
      subtitle={subtitle}
      title={title}
    />
  );
};

FilledCardDetail.defaultProps = {
  onEditClick: null,
  title: '',
  subtitle: 'Use recently entered card information',
};

export default FilledCardDetail;
