import themeVars from 'assets/scss/_theme-vars.module.scss';

const customShadows = {
  low: themeVars.shadowLow,
  medium: themeVars.shadowMedium,
  high: themeVars.shadowHigh,
  shadowInset: themeVars.shadowInset,
  shadowFocus: themeVars.shadowFocus,
};

export default customShadows;
