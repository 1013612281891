import { Box, Container } from '@mui/material';

interface IProps {
  children: React.ReactNode;
  maxWidth?: 'md' | 'lg' | 'sm';
}

const TabPanelLayout = ({ children, maxWidth = 'md' }: IProps) => (
  <Container maxWidth={maxWidth}>
    <Box mb={12} mt={4}>
      {children}
    </Box>
  </Container>
);

TabPanelLayout.defaultProps = {
  maxWidth: 'md',
};

export default TabPanelLayout;
