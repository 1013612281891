import { useState } from 'react';
import { useSearchParams } from 'react-router-dom';

import { faAdd } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Box, Button, DialogContent, Stack } from '@mui/material';
import LabelBoxCard from 'common/LabelBoxCard';
import LoadingIndicator from 'common/LoadingIndicator';
import {
  IAdaptedClientGroup,
  IAdaptedClientIndividual,
} from 'interfaces/client-management';
import { useDocumentListQuery } from 'services/documents';
import { selectEditClientData } from 'stores/client-management';
import { useAppSelector } from 'stores/hooks';
import { getFullName } from 'utils/common';

import ChooseDropZoneClientFiles from './ChooseDropZoneClientFiles';
import ChooseFileUploadOptions from './ChooseFileUploadOptions';
import File from './File';
import FromDocuments from './FromDocuments';
import FromURI from './FromURI';

const FileList = () => {
  const [step, setStep] = useState(0);
  const editClientData = useAppSelector(
    selectEditClientData
  ) as IAdaptedClientGroup & IAdaptedClientIndividual;

  const [searchParams] = useSearchParams();

  const clientIdFromParams = searchParams.get('id');

  const fullName =
    editClientData.businessName ??
    getFullName(
      editClientData?.firstName,
      editClientData?.lastName,
      editClientData?.middleName
    );

  const documentListQuery = useDocumentListQuery(
    {
      associationId: editClientData?.clientId || clientIdFromParams,
    },
    { enabled: !!editClientData?.clientId || !!clientIdFromParams }
  );

  const handleOnClick = () => {
    setStep(1);
  };

  if (documentListQuery.isLoading) return <LoadingIndicator />;
  const commonDetails = (removeUpload = false) => {
    const returnMessage = () => {
      if (removeUpload)
        return 'Below are the available options to upload files';
      if (documentListQuery?.data?.length)
        return 'Below are the list of files attached to the profile';
      return 'Click upload files to add files in profile';
    };
    return (
      <Box
        padding={2}
        sx={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
          background: (t) => t.palette.gray.lighter,
        }}
      >
        <LabelBoxCard
          label={returnMessage()}
          padding={0}
          title={`Upload files for ${fullName}`}
        />

        {!removeUpload && (
          <Box>
            <Button
              data-cy="client-upload-files"
              onClick={handleOnClick}
              size="medium"
              startIcon={<FontAwesomeIcon icon={faAdd} />}
              variant="outlined"
            >
              Upload Files
            </Button>
          </Box>
        )}
      </Box>
    );
  };
  return (
    <Box>
      {step === 0 && (
        <>
          {commonDetails()}
          <Stack minHeight={200} spacing={2}>
            <Box minHeight="60vh" padding={2}>
              <DialogContent
                className="modal-content modal-content--fixed-height scrollbar"
                sx={{
                  maxHeight: '60vh !important',
                  padding: '0px 8px 0px 0px',
                }}
              >
                {documentListQuery?.data?.length ? (
                  <File files={documentListQuery.data} />
                ) : (
                  ''
                )}
              </DialogContent>
            </Box>
          </Stack>
        </>
      )}
      {step === 1 && (
        <>
          {commonDetails(true)}
          <Stack minHeight={200} spacing={2}>
            <ChooseFileUploadOptions setStep={setStep} />
          </Stack>
        </>
      )}
      {/* Upload Option */}
      {step === 2 && <ChooseDropZoneClientFiles setStep={setStep} />}
      {/* Documents */}
      {step === 3 && (
        <FromDocuments files={documentListQuery.data} setStep={setStep} />
      )}
      {/* Documents */}
      {step === 4 && <FromURI setStep={setStep} />}
    </Box>
  );
};

export default FileList;
