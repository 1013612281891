import { useEffect, useState } from 'react';
import { useAcceptJs } from 'react-acceptjs';
import { useFormContext } from 'react-hook-form';
import { NumberFormatValues } from 'react-number-format';

import { Alert, Grid, Typography } from '@mui/material';
import {
  AuthorizeDotNetBankAccountType,
  AuthorizeDotNetPaymentEnvironment,
} from 'enums/Payment';
import { IPaymentForm } from 'interfaces/payment';
import { useAppSelector } from 'stores/hooks';
import { selectAuthorizeDotNet } from 'stores/Payment';
import {
  validateBankAccountNumber,
  validateBankAccountType,
  validateBankRoutingNumber,
} from 'utils/payment';

import AccountTypeSelect from './AccountTypeSelect';
import FilledBankDetail from './FilledBankDetail';
import MaskedAccountNumberInput from './MaskedAccountNumberInput';
import MaskedRoutingNumberInput from './MaskedRoutingNumberInput';

const BankPayment = () => {
  const authorizeDotNetData = useAppSelector(selectAuthorizeDotNet);
  const { authData, environment } = authorizeDotNetData;

  const { dispatchData, loading, error } = useAcceptJs({
    environment: environment as AuthorizeDotNetPaymentEnvironment,
    authData,
  });

  const { setValue, watch } = useFormContext<IPaymentForm>();
  const { accountNumber, routingNumber, accountType } = watch();

  const [bankData, setBankData] = useState({
    accountNumber: '',
    routingNumber: '',
    accountType: '',
  });
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [errorRes, setErrorRes] = useState('');

  const handleChangeBankData = (name: any, data: any) => {
    setBankData((prevState) => ({ ...prevState, [name]: data }));
    setErrorRes('');
  };

  // Validate bank data on input blur (accountNumber, routingNumber and accountType) and dispatch data to Authorize.Net server if validated.
  // Local validation is simply checking if the inputs are filled.
  const handleInputBlur = async () => {
    const {
      accountNumber: accountNumberLocal,
      routingNumber: routingNumberLocal,
      accountType: accountTypeLocal,
    } = bankData;

    const isDataFilled =
      validateBankAccountNumber(accountNumberLocal) &&
      validateBankRoutingNumber(routingNumberLocal) &&
      validateBankAccountType(accountTypeLocal);

    if (isDataFilled) {
      try {
        setIsSubmitting(true);
        const response = await dispatchData({
          bankData: {
            accountNumber: accountNumberLocal,
            routingNumber: routingNumberLocal,
            accountType: accountTypeLocal as AuthorizeDotNetBankAccountType,
            nameOnAccount: '', // Don't we need a separate field for Cardholder name?
          },
        });

        if (response?.messages?.resultCode === 'Ok') {
          setValue('bankToken', response?.opaqueData?.dataValue);
          setValue('tokenSource', response?.opaqueData?.dataDescriptor);
          setValue('accountNumber', accountNumberLocal);
          setValue('routingNumber', routingNumberLocal);
          setValue('accountType', accountTypeLocal);
        }
      } catch (err: any) {
        setErrorRes(err?.messages?.message?.[0]?.text);
      } finally {
        setIsSubmitting(false);
      }
    }
  };

  // Initialize with the existing bank information if present
  useEffect(() => {
    if (accountNumber && routingNumber && accountType) {
      setBankData({
        accountNumber,
        routingNumber,
        accountType,
      });
    }
  }, [accountNumber, routingNumber, accountType]);

  const resetBankInformation = () => {
    setValue('bankToken', '');
    setValue('accountNumber', '');
    setValue('routingNumber', '');
    setValue('accountType', '');

    setBankData({
      accountNumber: '',
      routingNumber: '',
      accountType: '',
    });
  };

  if (loading) {
    return <>Loading...</>;
  }

  if (error) {
    return <>Error loading Authorize.Net payment gateway</>;
  }

  if (accountNumber && routingNumber && accountType) {
    return (
      <FilledBankDetail
        accountNumber={accountNumber}
        onEditClick={resetBankInformation}
      />
    );
  }

  return (
    <>
      <Typography
        color="text.secondary"
        fontWeight={(theme) => theme.typography.fontWeightMedium}
        gutterBottom={false}
        variant="body1"
      >
        Bank information
      </Typography>

      <Grid container>
        <Grid item xs={4}>
          <MaskedAccountNumberInput
            disabled={isSubmitting}
            onBlur={handleInputBlur}
            onChange={(data) =>
              handleChangeBankData(
                'accountNumber',
                (data as unknown as NumberFormatValues).value
              )
            }
            value={bankData.accountNumber}
          />
        </Grid>
        <Grid item pl={2} xs={8}>
          <Grid container spacing={2}>
            <Grid item xs={6}>
              <MaskedRoutingNumberInput
                disabled={isSubmitting}
                onBlur={handleInputBlur}
                onChange={(data) =>
                  handleChangeBankData(
                    'routingNumber',
                    (data as unknown as NumberFormatValues).value
                  )
                }
                value={bankData.routingNumber}
              />
            </Grid>
            <Grid item xs={6}>
              <AccountTypeSelect
                disabled={isSubmitting}
                onBlur={handleInputBlur}
                onChange={(event) =>
                  handleChangeBankData('accountType', event.target.value)
                }
                value={bankData.accountType}
              />
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      {isSubmitting && <p className="text-sm text-bodyColor">Please wait...</p>}
      {!!errorRes && <Alert severity="error">{errorRes}</Alert>}
    </>
  );
};

export default BankPayment;
