enum UserType {
  INDIVIDUAL = 'Individual',
  GROUP = 'Group',
}

export enum RelationshipUserType {
  PRIMARY = 'Primary',
}

export default UserType;
