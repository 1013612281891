import { useMemo } from 'react';
import { Link as RouterLink, useLocation } from 'react-router-dom';

import { Box, Typography } from '@mui/material';
import { IPermission } from 'interfaces/common';

interface IProps {
  menu: IPermission;
}

const HeaderMenuChild = ({ menu }: IProps) => {
  const location = useLocation();

  const isActive = useMemo(
    () => menu.routePath === location.pathname,
    [location.pathname, menu.routePath]
  );

  return (
    <Box
      className={`header-menu ${isActive && 'header-menu--active'}`}
      component={RouterLink}
      sx={{ textDecoration: 'none' }}
      to={menu.routePath || ''}
    >
      <Typography
        className="header-menu__menu-item"
        data-cy={`header-menu-${menu.name}`}
        gutterBottom={false}
        variant="body1"
      >
        {menu.name}
      </Typography>
    </Box>
  );
};

export default HeaderMenuChild;
