export enum NetworkServiceSortBy {
  NAME = 'name',
  CODE = 'primaryCodes',
  DESCRIPTION = 'whatsIncluded',
}

export enum NetworkServiceStatus {
  ALL = 'All',
  ACTIVE = 'Active',
  INACTIVE = 'Inactive',
}
