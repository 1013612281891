import themeVars from 'assets/scss/_theme-vars.module.scss';

/**
 * Update the necessary typings for the custom variants if any
 *  * */
// declare module '@mui/material/styles' {
//   interface TypographyVariants {
//     customHeading: React.CSSProperties;
//   }

//   // allow configuration using `createTheme`
//   interface TypographyVariantsOptions {
//     customHeading?: React.CSSProperties;
//   }
// }

// // Update the Typography's variant prop options
// declare module '@mui/material/Typography' {
//   interface TypographyPropsVariantOverrides {
//     customHeading: true;
//   }
// }
/**
 * -------------------------------------------------------------
 *  * */

/**
 * Convert pixels to rem
 *
 * @param {number} value :: Value in pixels
 * @returns {string} :: respective value in rem
 */
const pxToRem = (value: number) => `${value / 16}rem`;

/**
 * Get media query object for the font sizes
 *
 * @param {number, number, number} obj :: Font sizes for {sm, md, lg}
 * @returns {Object} :: Object containing media queries
 */
// const responsiveFontSizes = ({
//   sm,
//   md,
//   lg,
// }: {
//   sm: number;
//   md: number;
//   lg: number;
// }) => ({
//   '@media (min-width:600px)': {
//     fontSize: pxToRem(sm),
//   },
//   '@media (min-width:900px)': {
//     fontSize: pxToRem(md),
//   },
//   '@media (min-width:1200px)': {
//     fontSize: pxToRem(lg),
//   },
// });

const typography = {
  fontFamily: themeVars.fontPrimary,
  fontWeightRegular: themeVars.fontWeightRegular,
  fontWeightMedium: themeVars.fontWeightMedium,
  // fontWeightMedium: themeVars.fontWeightMedium,
  h1: {
    fontWeight: themeVars.fontWeightMedium,
    lineHeight: pxToRem(+themeVars.h1LineHeight),
    fontSize: pxToRem(+themeVars.h1FontSize),
    color: themeVars.grayDarker,
    // TODO: Responsive font sizes to be determinted after consulting  with the Design Team
    // ...responsiveFontSizes({ sm: 52, md: 58, lg: 48 }), // NOTE: only lg fontSize are determined for typography
  },
  h2: {
    fontWeight: themeVars.fontWeightMedium,
    lineHeight: pxToRem(+themeVars.h2LineHeight),
    fontSize: pxToRem(+themeVars.h2FontSize),
    color: themeVars.grayDarker,
    // ...responsiveFontSizes({ sm: 40, md: 44, lg: 42 }),
  },
  h3: {
    fontWeight: themeVars.fontWeightMedium,
    lineHeight: pxToRem(+themeVars.h3LineHeight),
    fontSize: pxToRem(+themeVars.h3FontSize),
    color: themeVars.grayDarker,
    // ...responsiveFontSizes({ sm: 26, md: 30, lg: 36 }),
  },
  h4: {
    fontWeight: themeVars.fontWeightMedium,
    lineHeight: pxToRem(+themeVars.h4LineHeight),
    fontSize: pxToRem(+themeVars.h4FontSize),
    color: themeVars.grayDarker,
    // ...responsiveFontSizes({ sm: 20, md: 24, lg: 30 }),
  },
  h5: {
    fontWeight: themeVars.fontWeightMedium,
    lineHeight: pxToRem(+themeVars.h5LineHeight),
    fontSize: pxToRem(+themeVars.h5FontSize),
    color: themeVars.grayDarker,
    // ...responsiveFontSizes({ sm: 19, md: 20, lg: 24 }),
  },
  h6: {
    fontWeight: themeVars.fontWeightMedium,
    lineHeight: pxToRem(+themeVars.h6LineHeight),
    fontSize: pxToRem(+themeVars.h6FontSize),
    color: themeVars.grayDarker,
    // ...responsiveFontSizes({ sm: 18, md: 18, lg: 18 }),
  },

  // To use medium weight of body1/body2/caption, use fontWeight="medium" prop in typography as follows:
  // <Typography
  // fontWeight="medium"
  // variant="body1"
  // >Sth</Typography>
  body1: {
    fontWeight: themeVars.fontWeightRegular,
    lineHeight: pxToRem(+themeVars.body1LineHeight),
    fontSize: pxToRem(+themeVars.body1FontSize),
  },
  body2: {
    fontWeight: themeVars.fontWeightRegular,
    lineHeight: pxToRem(+themeVars.body2LineHeight),
    fontSize: pxToRem(+themeVars.body2FontSize),
  },
  caption: {
    fontWeight: themeVars.fontWeightRegular,
    lineHeight: pxToRem(+themeVars.captionLineHeight),
    fontSize: pxToRem(+themeVars.captionFontSize),
  },

  // Disable default variants provided by MUI which are not required (which are not included in the style guide)
  subtitle1: undefined,
  subtitle2: undefined,
  overline: undefined,
  button: undefined,

  // Custom variants
  // You can add custom variants as well.
  // Note: customHeading is just for demo purpose
  // customHeading: {
  //   fontWeight: 700,
  //   lineHeight: 1.5,
  //   fontSize: pxToRem(20),
  //   textDecoration: 'underline',
  //   ...responsiveFontSizes({ sm: 20, md: 24, lg: 24 }),
  // },
};

export default typography;
