/* eslint-disable no-param-reassign */
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { ChangeMembershipActionType } from 'enums/client-management';
import {
  IAdaptedClient,
  IAdaptedClientGroup,
  IAdaptedClientIndividual,
} from 'interfaces/client-management';
import { IAdaptedClientEnrollmentMembership } from 'interfaces/client-management/enrollment';
import { IChangeMembershipSelectedOffering } from 'interfaces/tenant-management/tenant/tenantMembershipOffering';
import { RootState } from 'stores';

interface IChangeMembershipModal {
  step: number;
  actionType: ChangeMembershipActionType | null;
}

interface IChangeMembershipSelectedOfferings {
  selectedMembershipType?: string | null;
  selectedOffering?: IChangeMembershipSelectedOffering | null;
  startDate?: string | null;
  endDate?: string | null;
}

interface IClientState {
  editClientData: IAdaptedClientGroup | IAdaptedClientIndividual | null;
  parentClient: IAdaptedClientGroup | IAdaptedClientIndividual | null;
  changeMembershipModal: IChangeMembershipModal;
  changeMembershipClientData: IAdaptedClient | null;
  currentMembershipSelectedData: IAdaptedClientEnrollmentMembership | null;
  changeMembershipSelectedOffering: IChangeMembershipSelectedOfferings | null;
}

const initialState: IClientState = {
  // stores the client data which is currently being edited (used in Add client modal)
  editClientData: null,
  parentClient: null,
  changeMembershipModal: {
    step: 0,
    actionType: null,
  },
  changeMembershipClientData: null,
  currentMembershipSelectedData: null,
  changeMembershipSelectedOffering: {
    selectedMembershipType: null,
    selectedOffering: null,
    startDate: null,
    endDate: null,
  },
};

export const slice = createSlice({
  name: 'client',
  initialState,
  reducers: {
    // Changing the state directly (with mutation) in reducer
    // Redux Toolkit uses Immer under the hood (takes care of immutablility)
    setEditClientData: (
      state,
      action: PayloadAction<IAdaptedClientGroup | IAdaptedClientIndividual>
    ) => {
      state.editClientData = action.payload;
    },
    clearEditClientData: (state) => {
      state.editClientData = null;
    },
    setParentClient: (
      state,
      action: PayloadAction<IAdaptedClientGroup | IAdaptedClientIndividual>
    ) => {
      state.parentClient = action.payload;
    },
    clearParentClient: (state) => {
      state.parentClient = null;
    },
    setChangeMembershipStep: (
      state,
      action: PayloadAction<{
        step: number;
        actionType: ChangeMembershipActionType | null;
      }>
    ) => {
      const { step, actionType } = action.payload;
      state.changeMembershipModal = {
        ...state.changeMembershipModal,
        step,
        actionType,
      };
    },
    setChangeMembershipClientData: (
      state,
      action: PayloadAction<IAdaptedClient | null>
    ) => {
      state.changeMembershipClientData = action.payload;
    },
    setCurrentMembershipSelectedData: (
      state,
      action: PayloadAction<IAdaptedClientEnrollmentMembership | null>
    ) => {
      state.currentMembershipSelectedData = action.payload;
    },
    setChangeMembershipSelectedData: (
      state,
      action: PayloadAction<Partial<IChangeMembershipSelectedOfferings>>
    ) => {
      state.changeMembershipSelectedOffering = {
        ...state.changeMembershipSelectedOffering,
        ...action.payload,
      };
    },
  },
});

// Actions
export const {
  setEditClientData,
  clearEditClientData,
  setParentClient,
  clearParentClient,
  setChangeMembershipStep,
  setChangeMembershipClientData,
  setChangeMembershipSelectedData,
  setCurrentMembershipSelectedData,
} = slice.actions;

// Selectors
export const selectEditClientData = (state: RootState) =>
  state.client.editClientData;
export const selectParentClient = (state: RootState) =>
  state.client.parentClient;
export const selectChangeMembershipModal = (state: RootState) =>
  state.client.changeMembershipModal;
export const selectChangeMembershipClientData = (state: RootState) =>
  state.client.changeMembershipClientData;
export const selectCurrentMembershipSelectedData = (state: RootState) =>
  state.client.currentMembershipSelectedData;
export const selectChangeMembershipSelectedData = (state: RootState) =>
  state.client.changeMembershipSelectedOffering;

// Reducer
export default slice.reducer;
