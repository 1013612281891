/* eslint-disable no-param-reassign */
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { DEFAULT_EVENT_FILTER } from 'constants/calender';
import {
  ICalenderState,
  IEventFilter,
  ISelectedCalendarTenant,
} from 'interfaces/calender';
import { Moment } from 'moment';
import { RootState } from 'stores';
import { MomentDate } from 'utils/moment';
import { getSession, setSession } from 'utils/storage';

import { ICalendarUser } from '../../interfaces/calender/index';

const initialState: ICalenderState = {
  calenderDate: null,
  filter: { ...DEFAULT_EVENT_FILTER },
  filteredPeople: {} as ISelectedCalendarTenant,
};

export const slice = createSlice({
  name: 'calender',
  initialState,
  reducers: {
    setCalenderDate: (state, action: PayloadAction<Moment>) => {
      state.calenderDate = action.payload;
    },
    changeCalenderFilters: (state, action: PayloadAction<IEventFilter>) => {
      state.filter = { ...state.filter, ...action.payload };
    },
    setPeopleFilter: (
      state,
      action: PayloadAction<ICalendarUser & { id: string }>
    ) => {
      const { id, ...rest } = action.payload;
      const existingData = state.filteredPeople[id] || [];
      existingData.push(rest);
      state.filteredPeople[id] = existingData;
      setSession(id, existingData);
    },
    setDeleteFilteredPeople: (
      state,
      action: PayloadAction<{ id: string; userId: string }>
    ) => {
      const { id, userId } = action.payload;
      const filteredData = state.filteredPeople[id].filter(
        (item) => item.userId !== userId
      );
      state.filteredPeople[id] = filteredData;
      setSession(id, filteredData);
    },
    getPeopleFromSession: (state, action: PayloadAction<{ id: string }>) => {
      const { id } = action.payload;
      state.filteredPeople[id] = getSession(id, true) || [];
    },
    resetCalendarFilter: (state) => {
      state.filter = { ...DEFAULT_EVENT_FILTER };
      state.calenderDate = MomentDate();
    },
  },
});

export const {
  setCalenderDate,
  changeCalenderFilters,
  setPeopleFilter,
  resetCalendarFilter,
  setDeleteFilteredPeople,
  getPeopleFromSession,
} = slice.actions;

export const selectCalenderDate = (state: RootState) =>
  state.calender.calenderDate;
export const selectEventFilter = (state: RootState) => state.calender.filter;
export const selectFilteredPeople = (state: RootState) =>
  state.calender.filteredPeople;

export default slice.reducer;
