import { ILangDict } from 'interfaces/language-dictionary';

/**
 * Get the value from language dictionary
 *
 * Priority
 * langDict with both groupCode and tenantCode > langDict with groupCode > langDict
 *
 * For example:
 * _HCM_WA_CLIENT > _HCM_CLIENT -> _CLIENT
 *
 */
export const getTextFromLangDict = (
  langDict: ILangDict | null,
  {
    key,
    groupCode,
    tenantCode,
  }: {
    key: string;
    groupCode: string;
    tenantCode: string;
  }
) => {
  if (!langDict) return '';
  return (
    langDict[`_${groupCode}_${tenantCode}${key}`] ||
    langDict[`_${groupCode}${key}`] ||
    langDict[key] ||
    ''
  );
};
