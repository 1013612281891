import React, { ReactNode } from 'react';

import {
  Avatar,
  AvatarProps,
  Stack,
  Typography,
  TypographyProps,
} from '@mui/material';
import EmptyListImage from 'assets/images/png/emptyList.png';

const EmptySearchList = ({
  message,
  avatarProps,
  typographyProps,
}: {
  message: string | ReactNode;
  avatarProps?: AvatarProps;
  typographyProps?: TypographyProps;
}) => (
  <Stack
    spacing={2}
    sx={{ alignItems: 'center', padding: 3, textAlign: 'center' }}
  >
    <Avatar
      alt="empty"
      src={EmptyListImage}
      sx={{ width: '80px', height: '80px' }}
      variant="square"
      {...avatarProps}
    />
    <Typography variant="body2" {...typographyProps}>
      {message}
    </Typography>
  </Stack>
);

EmptySearchList.defaultProps = {
  avatarProps: {},
  typographyProps: {},
};
export default EmptySearchList;
