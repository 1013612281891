export const countryCodesAndPhoneExtension = [
  {
    countryName: 'United States',
    phoneExtension: '+1',
    countryCode: 'US',
  },
  {
    countryName: 'Canada',
    phoneExtension: '+1',
    countryCode: 'CA',
  },
  {
    countryName: 'Afghanistan',
    phoneExtension: '+93',
    countryCode: 'AF',
  },
  {
    countryName: 'Aland Islands',
    phoneExtension: '+358',
    countryCode: 'AX',
  },
  {
    countryName: 'Albania',
    phoneExtension: '+355',
    countryCode: 'AL',
  },
  {
    countryName: 'Algeria',
    phoneExtension: '+213',
    countryCode: 'DZ',
  },
  {
    countryName: 'AmericanSamoa',
    phoneExtension: '+1684',
    countryCode: 'AS',
  },
  {
    countryName: 'Andorra',
    phoneExtension: '+376',
    countryCode: 'AD',
  },
  {
    countryName: 'Angola',
    phoneExtension: '+244',
    countryCode: 'AO',
  },
  {
    countryName: 'Anguilla',
    phoneExtension: '+1264',
    countryCode: 'AI',
  },
  {
    countryName: 'Antigua and Barbuda',
    phoneExtension: '+1268',
    countryCode: 'AG',
  },
  {
    countryName: 'Argentina',
    phoneExtension: '+54',
    countryCode: 'AR',
  },
  {
    countryName: 'Armenia',
    phoneExtension: '+374',
    countryCode: 'AM',
  },
  {
    countryName: 'Aruba',
    phoneExtension: '+297',
    countryCode: 'AW',
  },
  {
    countryName: 'Australia',
    phoneExtension: '+61',
    countryCode: 'AU',
  },
  {
    countryName: 'Austria',
    phoneExtension: '+43',
    countryCode: 'AT',
  },
  {
    countryName: 'Azerbaijan',
    phoneExtension: '+994',
    countryCode: 'AZ',
  },
  {
    countryName: 'Bahamas',
    phoneExtension: '+1242',
    countryCode: 'BS',
  },
  {
    countryName: 'Bahrain',
    phoneExtension: '+973',
    countryCode: 'BH',
  },
  {
    countryName: 'Bangladesh',
    phoneExtension: '+880',
    countryCode: 'BD',
  },
  {
    countryName: 'Barbados',
    phoneExtension: '+1246',
    countryCode: 'BB',
  },
  {
    countryName: 'Belarus',
    phoneExtension: '+375',
    countryCode: 'BY',
  },
  {
    countryName: 'Belgium',
    phoneExtension: '+32',
    countryCode: 'BE',
  },
  {
    countryName: 'Belize',
    phoneExtension: '+501',
    countryCode: 'BZ',
  },
  {
    countryName: 'Benin',
    phoneExtension: '+229',
    countryCode: 'BJ',
  },
  {
    countryName: 'Bermuda',
    phoneExtension: '+1441',
    countryCode: 'BM',
  },
  {
    countryName: 'Bhutan',
    phoneExtension: '+975',
    countryCode: 'BT',
  },
  {
    countryName: 'Bolivia, Plurinational State of',
    phoneExtension: '+591',
    countryCode: 'BO',
  },
  {
    countryName: 'Bosnia and Herzegovina',
    phoneExtension: '+387',
    countryCode: 'BA',
  },
  {
    countryName: 'Botswana',
    phoneExtension: '+267',
    countryCode: 'BW',
  },
  {
    countryName: 'Brazil',
    phoneExtension: '+55',
    countryCode: 'BR',
  },
  {
    countryName: 'British Indian Ocean Territory',
    phoneExtension: '+246',
    countryCode: 'IO',
  },
  {
    countryName: 'Brunei Darussalam',
    phoneExtension: '+673',
    countryCode: 'BN',
  },
  {
    countryName: 'Bulgaria',
    phoneExtension: '+359',
    countryCode: 'BG',
  },
  {
    countryName: 'Burkina Faso',
    phoneExtension: '+226',
    countryCode: 'BF',
  },
  {
    countryName: 'Burundi',
    phoneExtension: '+257',
    countryCode: 'BI',
  },
  {
    countryName: 'Cambodia',
    phoneExtension: '+855',
    countryCode: 'KH',
  },
  {
    countryName: 'Cameroon',
    phoneExtension: '+237',
    countryCode: 'CM',
  },

  {
    countryName: 'Cape Verde',
    phoneExtension: '+238',
    countryCode: 'CV',
  },
  {
    countryName: 'Cayman Islands',
    phoneExtension: '+ 345',
    countryCode: 'KY',
  },
  {
    countryName: 'Central African Republic',
    phoneExtension: '+236',
    countryCode: 'CF',
  },
  {
    countryName: 'Chad',
    phoneExtension: '+235',
    countryCode: 'TD',
  },
  {
    countryName: 'Chile',
    phoneExtension: '+56',
    countryCode: 'CL',
  },
  {
    countryName: 'China',
    phoneExtension: '+86',
    countryCode: 'CN',
  },
  {
    countryName: 'Christmas Island',
    phoneExtension: '+61',
    countryCode: 'CX',
  },
  {
    countryName: 'Cocos (Keeling) Islands',
    phoneExtension: '+61',
    countryCode: 'CC',
  },
  {
    countryName: 'Colombia',
    phoneExtension: '+57',
    countryCode: 'CO',
  },
  {
    countryName: 'Comoros',
    phoneExtension: '+269',
    countryCode: 'KM',
  },
  {
    countryName: 'Congo',
    phoneExtension: '+242',
    countryCode: 'CG',
  },
  {
    countryName: 'Congo, The Democratic Republic of the Congo',
    phoneExtension: '+243',
    countryCode: 'CD',
  },
  {
    countryName: 'Cook Islands',
    phoneExtension: '+682',
    countryCode: 'CK',
  },
  {
    countryName: 'Costa Rica',
    phoneExtension: '+506',
    countryCode: 'CR',
  },
  {
    countryName: "Cote d'Ivoire",
    phoneExtension: '+225',
    countryCode: 'CI',
  },
  {
    countryName: 'Croatia',
    phoneExtension: '+385',
    countryCode: 'HR',
  },
  {
    countryName: 'Cuba',
    phoneExtension: '+53',
    countryCode: 'CU',
  },
  {
    countryName: 'Cyprus',
    phoneExtension: '+357',
    countryCode: 'CY',
  },
  {
    countryName: 'Czech Republic',
    phoneExtension: '+420',
    countryCode: 'CZ',
  },
  {
    countryName: 'Denmark',
    phoneExtension: '+45',
    countryCode: 'DK',
  },
  {
    countryName: 'Djibouti',
    phoneExtension: '+253',
    countryCode: 'DJ',
  },
  {
    countryName: 'Dominica',
    phoneExtension: '+1767',
    countryCode: 'DM',
  },
  {
    countryName: 'Dominican Republic',
    phoneExtension: '+1849',
    countryCode: 'DO',
  },
  {
    countryName: 'Ecuador',
    phoneExtension: '+593',
    countryCode: 'EC',
  },
  {
    countryName: 'Egypt',
    phoneExtension: '+20',
    countryCode: 'EG',
  },
  {
    countryName: 'El Salvador',
    phoneExtension: '+503',
    countryCode: 'SV',
  },
  {
    countryName: 'Equatorial Guinea',
    phoneExtension: '+240',
    countryCode: 'GQ',
  },
  {
    countryName: 'Eritrea',
    phoneExtension: '+291',
    countryCode: 'ER',
  },
  {
    countryName: 'Estonia',
    phoneExtension: '+372',
    countryCode: 'EE',
  },
  {
    countryName: 'Ethiopia',
    phoneExtension: '+251',
    countryCode: 'ET',
  },
  {
    countryName: 'Falkland Islands (Malvinas)',
    phoneExtension: '+500',
    countryCode: 'FK',
  },
  {
    countryName: 'Faroe Islands',
    phoneExtension: '+298',
    countryCode: 'FO',
  },
  {
    countryName: 'Fiji',
    phoneExtension: '+679',
    countryCode: 'FJ',
  },
  {
    countryName: 'Finland',
    phoneExtension: '+358',
    countryCode: 'FI',
  },
  {
    countryName: 'France',
    phoneExtension: '+33',
    countryCode: 'FR',
  },
  {
    countryName: 'French Guiana',
    phoneExtension: '+594',
    countryCode: 'GF',
  },
  {
    countryName: 'French Polynesia',
    phoneExtension: '+689',
    countryCode: 'PF',
  },
  {
    countryName: 'Gabon',
    phoneExtension: '+241',
    countryCode: 'GA',
  },
  {
    countryName: 'Gambia',
    phoneExtension: '+220',
    countryCode: 'GM',
  },
  {
    countryName: 'Georgia',
    phoneExtension: '+995',
    countryCode: 'GE',
  },
  {
    countryName: 'Germany',
    phoneExtension: '+49',
    countryCode: 'DE',
  },
  {
    countryName: 'Ghana',
    phoneExtension: '+233',
    countryCode: 'GH',
  },
  {
    countryName: 'Gibraltar',
    phoneExtension: '+350',
    countryCode: 'GI',
  },
  {
    countryName: 'Greece',
    phoneExtension: '+30',
    countryCode: 'GR',
  },
  {
    countryName: 'Greenland',
    phoneExtension: '+299',
    countryCode: 'GL',
  },
  {
    countryName: 'Grenada',
    phoneExtension: '+1473',
    countryCode: 'GD',
  },
  {
    countryName: 'Guadeloupe',
    phoneExtension: '+590',
    countryCode: 'GP',
  },
  {
    countryName: 'Guam',
    phoneExtension: '+1671',
    countryCode: 'GU',
  },
  {
    countryName: 'Guatemala',
    phoneExtension: '+502',
    countryCode: 'GT',
  },
  {
    countryName: 'Guernsey',
    phoneExtension: '+44',
    countryCode: 'GG',
  },
  {
    countryName: 'Guinea',
    phoneExtension: '+224',
    countryCode: 'GN',
  },
  {
    countryName: 'Guinea-Bissau',
    phoneExtension: '+245',
    countryCode: 'GW',
  },
  {
    countryName: 'Guyana',
    phoneExtension: '+595',
    countryCode: 'GY',
  },
  {
    countryName: 'Haiti',
    phoneExtension: '+509',
    countryCode: 'HT',
  },
  {
    countryName: 'Holy See (Vatican City State)',
    phoneExtension: '+379',
    countryCode: 'VA',
  },
  {
    countryName: 'Honduras',
    phoneExtension: '+504',
    countryCode: 'HN',
  },
  {
    countryName: 'Hong Kong',
    phoneExtension: '+852',
    countryCode: 'HK',
  },
  {
    countryName: 'Hungary',
    phoneExtension: '+36',
    countryCode: 'HU',
  },
  {
    countryName: 'Iceland',
    phoneExtension: '+354',
    countryCode: 'IS',
  },
  {
    countryName: 'India',
    phoneExtension: '+91',
    countryCode: 'IN',
  },
  {
    countryName: 'Indonesia',
    phoneExtension: '+62',
    countryCode: 'ID',
  },
  {
    countryName: 'Iran, Islamic Republic of Persian Gulf',
    phoneExtension: '+98',
    countryCode: 'IR',
  },
  {
    countryName: 'Iraq',
    phoneExtension: '+964',
    countryCode: 'IQ',
  },
  {
    countryName: 'Ireland',
    phoneExtension: '+353',
    countryCode: 'IE',
  },
  {
    countryName: 'Isle of Man',
    phoneExtension: '+44',
    countryCode: 'IM',
  },
  {
    countryName: 'Israel',
    phoneExtension: '+972',
    countryCode: 'IL',
  },
  {
    countryName: 'Italy',
    phoneExtension: '+39',
    countryCode: 'IT',
  },
  {
    countryName: 'Jamaica',
    phoneExtension: '+1876',
    countryCode: 'JM',
  },
  {
    countryName: 'Japan',
    phoneExtension: '+81',
    countryCode: 'JP',
  },
  {
    countryName: 'Jersey',
    phoneExtension: '+44',
    countryCode: 'JE',
  },
  {
    countryName: 'Jordan',
    phoneExtension: '+962',
    countryCode: 'JO',
  },
  {
    countryName: 'Kazakhstan',
    phoneExtension: '+77',
    countryCode: 'KZ',
  },
  {
    countryName: 'Kenya',
    phoneExtension: '+254',
    countryCode: 'KE',
  },
  {
    countryName: 'Kiribati',
    phoneExtension: '+686',
    countryCode: 'KI',
  },
  {
    countryName: "Korea, Democratic People's Republic of Korea",
    phoneExtension: '+850',
    countryCode: 'KP',
  },
  {
    countryName: 'Korea, Republic of South Korea',
    phoneExtension: '+82',
    countryCode: 'KR',
  },
  {
    countryName: 'Kuwait',
    phoneExtension: '+965',
    countryCode: 'KW',
  },
  {
    countryName: 'Kyrgyzstan',
    phoneExtension: '+996',
    countryCode: 'KG',
  },
  {
    countryName: 'Laos',
    phoneExtension: '+856',
    countryCode: 'LA',
  },
  {
    countryName: 'Latvia',
    phoneExtension: '+371',
    countryCode: 'LV',
  },
  {
    countryName: 'Lebanon',
    phoneExtension: '+961',
    countryCode: 'LB',
  },
  {
    countryName: 'Lesotho',
    phoneExtension: '+266',
    countryCode: 'LS',
  },
  {
    countryName: 'Liberia',
    phoneExtension: '+231',
    countryCode: 'LR',
  },
  {
    countryName: 'Libyan Arab Jamahiriya',
    phoneExtension: '+218',
    countryCode: 'LY',
  },
  {
    countryName: 'Liechtenstein',
    phoneExtension: '+423',
    countryCode: 'LI',
  },
  {
    countryName: 'Lithuania',
    phoneExtension: '+370',
    countryCode: 'LT',
  },
  {
    countryName: 'Luxembourg',
    phoneExtension: '+352',
    countryCode: 'LU',
  },
  {
    countryName: 'Macao',
    phoneExtension: '+853',
    countryCode: 'MO',
  },
  {
    countryName: 'Macedonia',
    phoneExtension: '+389',
    countryCode: 'MK',
  },
  {
    countryName: 'Madagascar',
    phoneExtension: '+261',
    countryCode: 'MG',
  },
  {
    countryName: 'Malawi',
    phoneExtension: '+265',
    countryCode: 'MW',
  },
  {
    countryName: 'Malaysia',
    phoneExtension: '+60',
    countryCode: 'MY',
  },
  {
    countryName: 'Maldives',
    phoneExtension: '+960',
    countryCode: 'MV',
  },
  {
    countryName: 'Mali',
    phoneExtension: '+223',
    countryCode: 'ML',
  },
  {
    countryName: 'Malta',
    phoneExtension: '+356',
    countryCode: 'MT',
  },
  {
    countryName: 'Marshall Islands',
    phoneExtension: '+692',
    countryCode: 'MH',
  },
  {
    countryName: 'Martinique',
    phoneExtension: '+596',
    countryCode: 'MQ',
  },
  {
    countryName: 'Mauritania',
    phoneExtension: '+222',
    countryCode: 'MR',
  },
  {
    countryName: 'Mauritius',
    phoneExtension: '+230',
    countryCode: 'MU',
  },
  {
    countryName: 'Mayotte',
    phoneExtension: '+262',
    countryCode: 'YT',
  },
  {
    countryName: 'Mexico',
    phoneExtension: '+52',
    countryCode: 'MX',
  },
  {
    countryName: 'Micronesia, Federated States of Micronesia',
    phoneExtension: '+691',
    countryCode: 'FM',
  },
  {
    countryName: 'Moldova',
    phoneExtension: '+373',
    countryCode: 'MD',
  },
  {
    countryName: 'Monaco',
    phoneExtension: '+377',
    countryCode: 'MC',
  },
  {
    countryName: 'Mongolia',
    phoneExtension: '+976',
    countryCode: 'MN',
  },
  {
    countryName: 'Montenegro',
    phoneExtension: '+382',
    countryCode: 'ME',
  },
  {
    countryName: 'Montserrat',
    phoneExtension: '+1664',
    countryCode: 'MS',
  },
  {
    countryName: 'Morocco',
    phoneExtension: '+212',
    countryCode: 'MA',
  },
  {
    countryName: 'Mozambique',
    phoneExtension: '+258',
    countryCode: 'MZ',
  },
  {
    countryName: 'Myanmar',
    phoneExtension: '+95',
    countryCode: 'MM',
  },
  {
    countryName: 'Namibia',
    phoneExtension: '+264',
    countryCode: 'NA',
  },
  {
    countryName: 'Nauru',
    phoneExtension: '+674',
    countryCode: 'NR',
  },
  {
    countryName: 'Nepal',
    phoneExtension: '+977',
    countryCode: 'NP',
  },
  {
    countryName: 'Netherlands',
    phoneExtension: '+31',
    countryCode: 'NL',
  },
  {
    countryName: 'Netherlands Antilles',
    phoneExtension: '+599',
    countryCode: 'AN',
  },
  {
    countryName: 'New Caledonia',
    phoneExtension: '+687',
    countryCode: 'NC',
  },
  {
    countryName: 'New Zealand',
    phoneExtension: '+64',
    countryCode: 'NZ',
  },
  {
    countryName: 'Nicaragua',
    phoneExtension: '+505',
    countryCode: 'NI',
  },
  {
    countryName: 'Niger',
    phoneExtension: '+227',
    countryCode: 'NE',
  },
  {
    countryName: 'Nigeria',
    phoneExtension: '+234',
    countryCode: 'NG',
  },
  {
    countryName: 'Niue',
    phoneExtension: '+683',
    countryCode: 'NU',
  },
  {
    countryName: 'Norfolk Island',
    phoneExtension: '+672',
    countryCode: 'NF',
  },
  {
    countryName: 'Northern Mariana Islands',
    phoneExtension: '+1670',
    countryCode: 'MP',
  },
  {
    countryName: 'Norway',
    phoneExtension: '+47',
    countryCode: 'NO',
  },
  {
    countryName: 'Oman',
    phoneExtension: '+968',
    countryCode: 'OM',
  },
  {
    countryName: 'Pakistan',
    phoneExtension: '+92',
    countryCode: 'PK',
  },
  {
    countryName: 'Palau',
    phoneExtension: '+680',
    countryCode: 'PW',
  },
  {
    countryName: 'Palestinian Territory, Occupied',
    phoneExtension: '+970',
    countryCode: 'PS',
  },
  {
    countryName: 'Panama',
    phoneExtension: '+507',
    countryCode: 'PA',
  },
  {
    countryName: 'Papua New Guinea',
    phoneExtension: '+675',
    countryCode: 'PG',
  },
  {
    countryName: 'Paraguay',
    phoneExtension: '+595',
    countryCode: 'PY',
  },
  {
    countryName: 'Peru',
    phoneExtension: '+51',
    countryCode: 'PE',
  },
  {
    countryName: 'Philippines',
    phoneExtension: '+63',
    countryCode: 'PH',
  },
  {
    countryName: 'Pitcairn',
    phoneExtension: '+872',
    countryCode: 'PN',
  },
  {
    countryName: 'Poland',
    phoneExtension: '+48',
    countryCode: 'PL',
  },
  {
    countryName: 'Portugal',
    phoneExtension: '+351',
    countryCode: 'PT',
  },
  {
    countryName: 'Puerto Rico',
    phoneExtension: '+1939',
    countryCode: 'PR',
  },
  {
    countryName: 'Qatar',
    phoneExtension: '+974',
    countryCode: 'QA',
  },
  {
    countryName: 'Romania',
    phoneExtension: '+40',
    countryCode: 'RO',
  },
  {
    countryName: 'Russia',
    phoneExtension: '+7',
    countryCode: 'RU',
  },
  {
    countryName: 'Rwanda',
    phoneExtension: '+250',
    countryCode: 'RW',
  },
  {
    countryName: 'Reunion',
    phoneExtension: '+262',
    countryCode: 'RE',
  },
  {
    countryName: 'Saint Barthelemy',
    phoneExtension: '+590',
    countryCode: 'BL',
  },
  {
    countryName: 'Saint Helena, Ascension and Tristan Da Cunha',
    phoneExtension: '+290',
    countryCode: 'SH',
  },
  {
    countryName: 'Saint Kitts and Nevis',
    phoneExtension: '+1869',
    countryCode: 'KN',
  },
  {
    countryName: 'Saint Lucia',
    phoneExtension: '+1758',
    countryCode: 'LC',
  },
  {
    countryName: 'Saint Martin',
    phoneExtension: '+590',
    countryCode: 'MF',
  },
  {
    countryName: 'Saint Pierre and Miquelon',
    phoneExtension: '+508',
    countryCode: 'PM',
  },
  {
    countryName: 'Saint Vincent and the Grenadines',
    phoneExtension: '+1784',
    countryCode: 'VC',
  },
  {
    countryName: 'Samoa',
    phoneExtension: '+685',
    countryCode: 'WS',
  },
  {
    countryName: 'San Marino',
    phoneExtension: '+378',
    countryCode: 'SM',
  },
  {
    countryName: 'Sao Tome and Principe',
    phoneExtension: '+239',
    countryCode: 'ST',
  },
  {
    countryName: 'Saudi Arabia',
    phoneExtension: '+966',
    countryCode: 'SA',
  },
  {
    countryName: 'Senegal',
    phoneExtension: '+221',
    countryCode: 'SN',
  },
  {
    countryName: 'Serbia',
    phoneExtension: '+381',
    countryCode: 'RS',
  },
  {
    countryName: 'Seychelles',
    phoneExtension: '+248',
    countryCode: 'SC',
  },
  {
    countryName: 'Sierra Leone',
    phoneExtension: '+232',
    countryCode: 'SL',
  },
  {
    countryName: 'Singapore',
    phoneExtension: '+65',
    countryCode: 'SG',
  },
  {
    countryName: 'Slovakia',
    phoneExtension: '+421',
    countryCode: 'SK',
  },
  {
    countryName: 'Slovenia',
    phoneExtension: '+386',
    countryCode: 'SI',
  },
  {
    countryName: 'Solomon Islands',
    phoneExtension: '+677',
    countryCode: 'SB',
  },
  {
    countryName: 'Somalia',
    phoneExtension: '+252',
    countryCode: 'SO',
  },
  {
    countryName: 'South Africa',
    phoneExtension: '+27',
    countryCode: 'ZA',
  },
  {
    countryName: 'South Sudan',
    phoneExtension: '+211',
    countryCode: 'SS',
  },
  {
    countryName: 'South Georgia and the South Sandwich Islands',
    phoneExtension: '+500',
    countryCode: 'GS',
  },
  {
    countryName: 'Spain',
    phoneExtension: '+34',
    countryCode: 'ES',
  },
  {
    countryName: 'Sri Lanka',
    phoneExtension: '+94',
    countryCode: 'LK',
  },
  {
    countryName: 'Sudan',
    phoneExtension: '+249',
    countryCode: 'SD',
  },
  {
    countryName: 'SuricountryName',
    phoneExtension: '+597',
    countryCode: 'SR',
  },
  {
    countryName: 'Svalbard and Jan Mayen',
    phoneExtension: '+47',
    countryCode: 'SJ',
  },
  {
    countryName: 'Swaziland',
    phoneExtension: '+268',
    countryCode: 'SZ',
  },
  {
    countryName: 'Sweden',
    phoneExtension: '+46',
    countryCode: 'SE',
  },
  {
    countryName: 'Switzerland',
    phoneExtension: '+41',
    countryCode: 'CH',
  },
  {
    countryName: 'Syrian Arab Republic',
    phoneExtension: '+963',
    countryCode: 'SY',
  },
  {
    countryName: 'Taiwan',
    phoneExtension: '+886',
    countryCode: 'TW',
  },
  {
    countryName: 'Tajikistan',
    phoneExtension: '+992',
    countryCode: 'TJ',
  },
  {
    countryName: 'Tanzania, United Republic of Tanzania',
    phoneExtension: '+255',
    countryCode: 'TZ',
  },
  {
    countryName: 'Thailand',
    phoneExtension: '+66',
    countryCode: 'TH',
  },
  {
    countryName: 'Timor-Leste',
    phoneExtension: '+670',
    countryCode: 'TL',
  },
  {
    countryName: 'Togo',
    phoneExtension: '+228',
    countryCode: 'TG',
  },
  {
    countryName: 'Tokelau',
    phoneExtension: '+690',
    countryCode: 'TK',
  },
  {
    countryName: 'Tonga',
    phoneExtension: '+676',
    countryCode: 'TO',
  },
  {
    countryName: 'Trinidad and Tobago',
    phoneExtension: '+1868',
    countryCode: 'TT',
  },
  {
    countryName: 'Tunisia',
    phoneExtension: '+216',
    countryCode: 'TN',
  },
  {
    countryName: 'Turkey',
    phoneExtension: '+90',
    countryCode: 'TR',
  },
  {
    countryName: 'Turkmenistan',
    phoneExtension: '+993',
    countryCode: 'TM',
  },
  {
    countryName: 'Turks and Caicos Islands',
    phoneExtension: '+1649',
    countryCode: 'TC',
  },
  {
    countryName: 'Tuvalu',
    phoneExtension: '+688',
    countryCode: 'TV',
  },
  {
    countryName: 'Uganda',
    phoneExtension: '+256',
    countryCode: 'UG',
  },
  {
    countryName: 'Ukraine',
    phoneExtension: '+380',
    countryCode: 'UA',
  },
  {
    countryName: 'United Arab Emirates',
    phoneExtension: '+971',
    countryCode: 'AE',
  },
  {
    countryName: 'United Kingdom',
    phoneExtension: '+44',
    countryCode: 'GB',
  },

  {
    countryName: 'Uruguay',
    phoneExtension: '+598',
    countryCode: 'UY',
  },
  {
    countryName: 'Uzbekistan',
    phoneExtension: '+998',
    countryCode: 'UZ',
  },
  {
    countryName: 'Vanuatu',
    phoneExtension: '+678',
    countryCode: 'VU',
  },
  {
    countryName: 'Venezuela, Bolivarian Republic of Venezuela',
    phoneExtension: '+58',
    countryCode: 'VE',
  },
  {
    countryName: 'Vietnam',
    phoneExtension: '+84',
    countryCode: 'VN',
  },
  {
    countryName: 'Virgin Islands, British',
    phoneExtension: '+1284',
    countryCode: 'VG',
  },
  {
    countryName: 'Virgin Islands, U.S.',
    phoneExtension: '+1340',
    countryCode: 'VI',
  },
  {
    countryName: 'Wallis and Futuna',
    phoneExtension: '+681',
    countryCode: 'WF',
  },
  {
    countryName: 'Yemen',
    phoneExtension: '+967',
    countryCode: 'YE',
  },
  {
    countryName: 'Zambia',
    phoneExtension: '+260',
    countryCode: 'ZM',
  },
  {
    countryName: 'Zimbabwe',
    phoneExtension: '+263',
    countryCode: 'ZW',
  },
];
