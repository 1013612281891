import { Alert, Box, DialogContent, Typography } from '@mui/material';
import { Modal, ModalFooter } from 'common';
import { useAppDispatch, useAppSelector } from 'stores/hooks';
import { clearMessageOption, selectModalOption } from 'stores/messageModal';

const MessageModal = () => {
  const dispatch = useAppDispatch();
  const options = useAppSelector(selectModalOption);
  const { open, title, message, alertMessage } = options;

  const handleClose = () => {
    dispatch(clearMessageOption());
  };

  return (
    <Modal
      handleClose={handleClose}
      open={open}
      title={title ?? 'Access Denied'}
    >
      <DialogContent>
        <Box mt={2}>
          <Box>
            <Typography variant="body1">
              {message ?? 'You do not have write access on this service'}
            </Typography>
          </Box>
          {alertMessage && (
            <Alert severity="info">
              <Typography gutterBottom={false} variant="body2">
                {alertMessage}
              </Typography>
            </Alert>
          )}
        </Box>
      </DialogContent>
      <ModalFooter
        actionButtonLabel="close"
        actionButtonType="button"
        isCancelRequired={false}
        isDisabled={false}
        isLoading={false}
        onActionButtonClick={handleClose}
      />
    </Modal>
  );
};

export default MessageModal;
