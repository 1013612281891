import MessageModal from './MessageModal';

interface Props {
  children: JSX.Element;
}

const MessageModalWrapper = ({ children }: Props) => (
  <>
    {children}
    <MessageModal />
  </>
);

export default MessageModalWrapper;
