export const getCountryFromCountryCode = (
  code: string,
  countryCodeArr: any
) => {
  const obj = countryCodeArr.find((item: any) => item.countryCode === code);

  return obj?.countryName;
};

export const getSelectedPhoneExtension = (
  code: string,
  countryCodeArr: any
) => {
  const obj = countryCodeArr.find((item: any) => item.countryCode === code);
  return obj?.phoneExtension;
};
