import apiRoutes from 'constants/apiRoutes';
import { IFilter } from 'interfaces/common';
import { IListResponse, IResponse } from 'interfaces/http';
import { INetworkTaxonomyTableRow } from 'interfaces/networks/network-taxonomy';
import { baseRequest } from 'utils/axios';

const { taxonomies } = apiRoutes.networks;

export const getAllNetworkTaxonomies = async (
  params: IFilter
): Promise<IResponse<IListResponse<INetworkTaxonomyTableRow>>> => {
  const { response, error } = await baseRequest({
    method: 'GET',
    url: taxonomies.search,
    params,
  });

  if (error) {
    throw new Error(error.message);
  }

  return response?.data;
};
