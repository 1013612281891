import { Link } from '@mui/material';

interface IProps {
  children: React.ReactNode;
  href: string;
}

const LinkButton = ({ children, href = '#' }: IProps) => (
  <Link href={href} rel="noopener noreferrer" target="_blank" underline="hover">
    {children}
  </Link>
);

export default LinkButton;
