// import { ReferenceSubType, ReferenceType } from 'enums/common';
import { selectAuthTenantData } from 'stores/auth';
import { useAppSelector } from 'stores/hooks';
import { checkUserIsClient } from 'utils/client-management';

/**
 * Checks if the logged in user is a client
 *
 */
export const useCheckUserIsClient = () => {
  const tenantData = useAppSelector(selectAuthTenantData);

  return checkUserIsClient(tenantData);
};
