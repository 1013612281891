import { Interval } from 'enums/common';
import { IListResponse, IResponse } from 'interfaces/http';

const intervals = [
  {
    id: 1,
    name: 'This Week',
    value: Interval.WEEKLY,
  },
  {
    id: 3,
    name: 'This Month',
    value: Interval.THIS_MONTH,
  },
  {
    id: 4,
    name: 'Last Month',
    value: Interval.LAST_MONTH,
  },
  {
    id: 5,
    name: 'This Quarter',
    value: Interval.THIS_QUARTER,
  },
  {
    id: 6,
    name: 'This Year',
    value: Interval.THIS_YEAR,
  },
  {
    id: 7,
    name: 'Last Year',
    value: Interval.LAST_YEAR,
  },
  {
    id: 8,
    name: 'Since Inception',
    value: Interval.SINCE_INCEPTION,
  },
  {
    id: 9,
    name: 'Custom Date',
    value: Interval.CUSTOM_DATE,
  },
];

const mockIntervalAPIData = {
  count: 5,
  rows: intervals,
};

export const getIntervals = async (): Promise<
  IResponse<IListResponse<any>>
> => {
  const mockData = {
    success: true,
    message: 'Interval fetched successfully',
    data: mockIntervalAPIData,
  };

  return mockData;
};
