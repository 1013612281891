/* eslint-disable no-param-reassign */
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import commonConstants from 'constants/common';
import {
  ClientGroupSortByType,
  IClientFilters,
  IGroupAdvancedSearchForm,
} from 'interfaces/client-management';
import { SortOrderType } from 'interfaces/common';
import { RootState } from 'stores';
import { checkIfValuesInObjectIsEmpty } from 'utils/common';

const { DEFAULT_TABLE_FILTER } = commonConstants;

const advancedSearchDefaultValues = {
  name: '',
  email: '',
  phone: '',
  agent: '',
  status: '',
  enrollmentStatus: '',
  isAdvancedSearch: false,
};

interface IGroupSort {
  sortBy: ClientGroupSortByType | null;
  sortOrder: SortOrderType | null;
}

interface GroupState {
  filters: IClientFilters;
  advancedSearch: IGroupAdvancedSearchForm;
  sort: IGroupSort;
  advanceFilterOfferings: any;
}

const initialState: GroupState = {
  filters: {
    ...DEFAULT_TABLE_FILTER,
    type: commonConstants.TYPE.GROUP,
    tenantId: '',
    agentId: '',
  },
  advancedSearch: { ...advancedSearchDefaultValues },
  sort: {
    sortBy: null,
    sortOrder: null,
  },
  advanceFilterOfferings: null,
};

export const slice = createSlice({
  name: 'group',
  initialState,
  reducers: {
    // Changing the state directly (with mutation) in reducer
    // Redux Toolkit uses Immer under the hood (takes care of immutablility)
    changeFilters: (state, action: PayloadAction<Partial<IClientFilters>>) => {
      state.filters = { ...state.filters, ...action.payload };
    },

    resetFilters: (state) => {
      state.filters = {
        ...state.filters,
        ...DEFAULT_TABLE_FILTER,
        type: commonConstants.TYPE.GROUP,
      };
    },

    changeAdvancedSearch: (
      state,
      action: PayloadAction<Partial<IGroupAdvancedSearchForm>>
    ) => {
      const advanceFilters = { ...state.advancedSearch, ...action.payload };
      const { isAdvancedSearch, ...rest } = advanceFilters;
      state.advancedSearch = {
        ...advanceFilters,
        isAdvancedSearch: !checkIfValuesInObjectIsEmpty(rest),
      };
      state.advancedSearch = { ...state.advancedSearch, ...action.payload };
    },

    resetAdvancedSearch: (state) => {
      state.advancedSearch = { ...advancedSearchDefaultValues };
      state.advanceFilterOfferings = null;
    },

    changeSortByAndOrder: (state, action: PayloadAction<IGroupSort>) => {
      state.sort = {
        sortBy: action.payload.sortBy,
        sortOrder: action.payload.sortOrder,
      };
    },

    resetAllFilters: (state) => {
      state.advancedSearch = { ...advancedSearchDefaultValues };
      state.sort = initialState.sort;
      state.filters = {
        ...state.filters,
        ...DEFAULT_TABLE_FILTER,
        type: commonConstants.TYPE.GROUP,
      };
      state.advanceFilterOfferings = [];
    },
    changeSelectedOfferings: (
      state,
      action: PayloadAction<Partial<any> | null>
    ) => {
      state.advanceFilterOfferings = action.payload;
    },
  },
});

// Actions
export const {
  changeFilters,
  changeAdvancedSearch,
  resetFilters,
  resetAdvancedSearch,
  changeSortByAndOrder,
  resetAllFilters,
  changeSelectedOfferings,
} = slice.actions;

// Selectors
export const selectGroupFilter = (state: RootState) =>
  state.clientGroup.filters;
export const selectGroupSort = (state: RootState) => state.clientGroup.sort;
export const selectGroupAdvancedSearch = (state: RootState) =>
  state.clientGroup.advancedSearch;
export const selectGroup = (state: RootState) => state.clientGroup;

export const selectAdvanceFilteredOfferings = (state: RootState) =>
  state.clientGroup.advanceFilterOfferings;

// Reducer
export default slice.reducer;
